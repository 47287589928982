<template>
  <!--
  等待优化,
  合并维也纳分类,尼斯分类,共用一个弹窗
  -->

  <div
    class="high-grade-query cn-new"
  >
    <div style="height: 74px"></div>
    <div class="toFast" @click="swichFastHigh(0)" >
      <i class="el-icon-arrow-right"></i>
      <span>{{$t('query_lang.fastQuery')}}</span>
    </div>
    <!--查询国家-->
    <div class="query-item" >
      <p class="title" style="padding-left: 15px;border: 1px solid #d8dce5;box-sizing: border-box">查询国家</p>
      <div class="container">
        <el-select
          v-model="countryData"
          placeholder="请选择国家"
          multiple collapse-tags
          class="el-select-long-officialt"
          filterable
          @change="whichLandFn"
        >
          <template >
            <el-option label="全选" value="all"></el-option>
            <el-option
              v-for="item in queryData.countryList"
              :key="item.countryKey"
              :label="item.countryName+'('+item.countryKey+')'"
              :value="item.countryKey"
              :disabled="item.disabled"
            ></el-option>
            <el-button style="width: 105%;
                background-color: #d01027;
                color: #ffffff;
                position: sticky;
                bottom: 0;
                left: 0;"
                       @click="goPurchase">购买更多
            </el-button>
          </template>
        </el-select>
      </div>
    </div>
    <!-- 高级查询改版之后 固定查询下拉部分start -->
    <div class="query-item" v-for="(item,index) in query_Item1" :key="index">
      <p class="title">
        <el-select v-model="item.tmParams" placeholder="请选择" @change="changeVal1">
          <el-option
            v-for="items in item.options"
            :key="items.value"
            :label="items.label"
            :value="items.value"
            :disabled="items.disabled"
          ></el-option>
        </el-select>
      </p>
      <div class="container">
        <el-input
          v-model="item.input"
          style="width: 520px;" :disabled="true"
          v-if="!(item.tmParams=='SBMC'||
            item.tmParams=='SBTP'||
            item.tmParams=='TXYSBM'||
            item.tmParams=='LB'||
            item.tmParams=='SQH'||
            item.tmParams=='ZCH'||
            item.tmParams=='SQRMC'||
            item.tmParams=='SQRDZ'||
            item.tmParams=='SQR'||
            item.tmParams=='CSGGR'||
            item.tmParams=='ZCR'||
            item.tmParams=='ZYQDQR'||
            item.tmParams=='DLJG')"
        ></el-input>
        <!--商标名称-->
        <el-input
          v-if="item.tmParams=='SBMC'"
          @keyup.enter.native="highGradeQuery"
          class="container el-input-long-officialt"
          v-model.trim="queryItem.tradeMarkText.value"
          :disabled="!!imgUplad"
        ></el-input>
        <!--有图片,或者有注册号的时候,禁止切换查询模式-->
        <el-radio-group
          v-model="queryMode.choosed"
          class="queryMode"
          @change="queryWayFn"
          v-if="item.tmParams=='SBMC'"
          :disabled="!!imgUplad||!!queryItem.applicationNumber.value||!!queryItem.registerNumber.value"
        >
          <el-radio label="similar">{{$t('query_lang.Similar_search')}}</el-radio>
          <el-radio label="match">{{$t('query_lang.Contained_search')}}</el-radio>
          <el-radio label="term">{{$t('query_lang.Identical_search')}}</el-radio>
        </el-radio-group>
        <!--有图片,或者有注册号的时候,禁止切换查询模式-->
        <!-- 商标图形 -->
        <el-upload
          drag
          action=""
          multiple
          class="upload-drag"
          :class="{uploadDisabled:!!queryItem.tradeMarkText.value||!!queryItem.applicationNumber.value||!!queryItem.registerNumber.value||!queryData.isCanUploadImg}"
          :auto-upload="false"
          :show-file-list="false"
          :on-change="uploadFn"
          :disabled="!!queryItem.tradeMarkText.value||!!queryItem.applicationNumber.value||!!queryItem.registerNumber.value||!queryData.isCanUploadImg"
          v-if="item.tmParams=='SBTP'"
        >
          <img :src="imgDefault" class="upload-btn" v-if="!imgUplad">
          <img :src="(imgUplad)" v-if="imgUplad" class="upload-img" referrerPolicy="no-referrer">
          <p v-if="imgUplad" class="delete" @click.stop="deleteImg">{{$t("update_lang.btn_delete")}}</p>
          <p v-if="!imgUplad">请上传商标图样，<5MB JPG/PNG/GIF/TIFF格式</p>
          <div class="el-upload-disabled-shade"></div>
        </el-upload>
        <!--申请号/注册号-->
        <el-input
          v-if="item.tmParams=='SQH'"
          @keyup.enter.native="highGradeQuery"
          class="container el-input-long-officialt"
          :placeholder="$t('follow_up_FE_lang.enter_fomat_no')"
          v-model.trim="queryItem.applicationNumber.value"
          @input="queryMode.choosed='term'"
          :disabled="!!imgUplad||!!queryItem.tradeMarkText.value||!!queryItem.patternElement.value"
        ></el-input>
        <!--申请号/注册号-->
        <!--申请人名称-->
        <template >
          <!--2018-12-29邱峰说可以  放开这个判断  对应禅道任务1138-->
          <el-input
            v-if="item.tmParams=='SQRMC'"
            @keyup.enter.native="highGradeQuery"
            class="container el-input-long-officialt"
            v-model.trim="queryItem.tmApplicant.value"
            :disabled="!!queryItem.applicationNumber.value"
          ></el-input>
          <!--申请人地址-->
          <el-input
            v-if="item.tmParams=='SQRDZ'"
            @keyup.enter.native="highGradeQuery"
            class="container el-input-long-officialt"
            :disabled="queryItem.tmApplicant.value==''||!!queryItem.applicationNumber.value"
            v-model.trim="queryItem.tmAddress.value"
          ></el-input>
        </template>
        <!--申请人地址（中文）-->
      </div>
      <p></p>
    </div>
    <!-- 高级查询改版之后 固定查询下拉部分end -->
    <!-- 高级查询改版之后 动态新增下拉数据部分 start-->
    <div class="query-item" v-for="(item,index) in query_Items" :key="index">
      <p class="title">
        <el-select
          v-model="item.tmParams"
          placeholder="请选择"
          @change="changeVal(item.tmParams,index)"
        >
          <el-option
            v-for="items in item.options"
            :key="items.value"
            :label="items.label"
            :value="items.value"
            :disabled="items.disabled"
          ></el-option>
        </el-select>
      </p>
      <div class="container">
        <el-input
          v-model="item.input"
          style="width: 520px;" :disabled="true"
          v-if="!(item.tmParams=='SBMC'||
           item.tmParams=='SBTP'||
           item.tmParams=='TXYSBM'||
           item.tmParams=='LB'||
           item.tmParams=='SQH'||
           item.tmParams=='ZCH'||
           item.tmParams=='SQRMC'||
           item.tmParams=='SQRDZ'||
           item.tmParams=='SQR'||
           item.tmParams=='CSGGR'||
           item.tmParams=='ZCR'||
           item.tmParams=='ZYQDQR'||
           item.tmParams=='DLJG'||
           item.tmParams=='ZCGGR'||
           item.tmParams=='YXQR'||
           item.tmParams=='SBZT'||
           item.tmParams=='SFYX'||
           item.tmParams=='SFGYSB')"
        ></el-input>

        <!--商标名称-->
        <el-input
          v-if="item.tmParams=='SBMC'"
          @keyup.enter.native="highGradeQuery"
          class="container el-input-long-officialt"
          v-model.trim="queryItem.tradeMarkText.value"
          :disabled="!!imgUplad"
        ></el-input>
        <!--有图片,或者有注册号的时候,禁止切换查询模式-->
        <el-radio-group
          v-model="queryMode.choosed"
          class="queryMode"
          @change="queryWayFn"
          v-if="item.tmParams=='SBMC'"
          :disabled="!!imgUplad||!!queryItem.applicationNumber.value||!!queryItem.registerNumber.value"
        >
          <el-radio label="similar">{{$t('query_lang.Similar_search')}}</el-radio>
          <el-radio label="match">{{$t('query_lang.Contained_search')}}</el-radio>
          <el-radio label="term">{{$t('query_lang.Identical_search')}}</el-radio>
        </el-radio-group>
        <!--有图片,或者有注册号的时候,禁止切换查询模式-->
        <!-- 商标图形 -->
        <progressp :psMsg.sync="flagdata" ref="child"></progressp>
        <el-upload
          drag
          action=""
          multiple
          class="upload-drag"
          :class="{uploadDisabled:!!queryItem.tradeMarkText.value||!!queryItem.applicationNumber.value||!!queryItem.registerNumber.value||!queryData.isCanUploadImg}"
          :auto-upload="false"
          :show-file-list="false"
          :on-change="uploadFn"
          :disabled="!!queryItem.tradeMarkText.value||!!queryItem.applicationNumber.value||!!queryItem.registerNumber.value||!queryData.isCanUploadImg"
          v-if="item.tmParams=='SBTP'"
        >
          <img :src="imgDefault" class="upload-btn" v-if="!imgUplad">
          <img :src="(imgUplad)" v-if="imgUplad" class="upload-img" referrerPolicy="no-referrer">
          <p v-if="imgUplad" class="delete" @click.stop="deleteImg">{{$t("update_lang.btn_delete")}}</p>
          <p v-if="!imgUplad">请上传商标图样，<5MB JPG/PNG/GIF/TIFF格式</p>
          <div class="el-upload-disabled-shade"></div>
        </el-upload>
        <!-- 商标图形 -->
        <!--图形要素编码-->
        <div class="choose" v-if="item.tmParams=='TXYSBM'">
          <el-input
            class="leftText el-input-long-officialt"
            :placeholder="$t('follow_up_FE_lang.enter_fomat_vienna')"
            v-model="queryItem.patternElement.value"
            @blur="viennaInput"
            :disabled="!!queryItem.tradeMarkText.value||!!queryItem.applicationNumber.value||!!queryItem.registerNumber.value"
          ></el-input>
          <span style="font-size: 0;width: 0;">{{viennaChoosedStr}}</span>
          <!--<span class="leftText">{{viennaChoosedStr}}</span>-->
          <!--<span
            class="rightText"
            @click="!imgUplad?ViennaVisible=true:ViennaVisible=false"
            :style="{cursor:!imgUplad?'pointer':'not-allowed'}"
          >{{$t('query_lang.please_choose')}}</span>-->
          <el-button
            class="el-long-white-button rightText"
            @click="ViennaVisible=true"
            :disabled="!!queryItem.tradeMarkText.value||!!queryItem.applicationNumber.value||!!queryItem.registerNumber.value"
          >请选择</el-button>
        </div>
        <!--类别-->
        <div class="choose" v-if="item.tmParams=='LB'">
          <el-input
            class="leftText el-input-long-officialt"
            :placeholder="$t('follow_up_FE_lang.enter_fomat_nice')"
            v-model="queryItem.classify.value"
            @blur="classifyInput"
            :disabled="!!queryItem.applicationNumber.value"
          ></el-input>
          <span style="font-size: 0;width: 0;">{{niceChoosedStr}}</span>
          <!-- <span class="leftText">{{niceChoosedStr}}</span> -->
          <el-button
            class="el-long-white-button rightText"
            @click="niceVisible=true"
            :disabled="!!queryItem.applicationNumber.value"
          >请选择</el-button>
        </div>
        <!--申请号/注册号-->
        <el-input
          v-if="item.tmParams=='SQH'"
          @keyup.enter.native="highGradeQuery"
          class="container el-input-long-officialt"
          :placeholder="$t('follow_up_FE_lang.enter_fomat_no')"
          v-model.trim="queryItem.applicationNumber.value"
          @input="queryMode.choosed='term'"
          :disabled="!!imgUplad||!!queryItem.tradeMarkText.value||!!queryItem.patternElement.value"
        ></el-input>
        <el-input
          v-if="item.tmParams=='ZCH'"
          @keyup.enter.native="highGradeQuery"
          class="container el-input-long-officialt"
          :placeholder="$t('follow_up_FE_lang.enter_fomat_reg_no')"
          v-model.trim="queryItem.registerNumber.value"
          @input="queryMode.choosed='term'"
          :disabled="!!imgUplad||!!queryItem.tradeMarkText.value||!!queryItem.patternElement.value"
        ></el-input>
        <!--申请人名称-->
        <template >
          <!--2018-12-29邱峰说可以  放开这个判断  对应禅道任务1138-->
          <el-input
            v-if="item.tmParams=='SQRMC'"
            @keyup.enter.native="highGradeQuery"
            class="container el-input-long-officialt"
            v-model.trim="queryItem.tmApplicant.value"
            :disabled="!!queryItem.applicationNumber.value"
          ></el-input>
          <!--申请人地址-->
          <el-input
            v-if="item.tmParams=='SQRDZ'"
            @keyup.enter.native="highGradeQuery"
            class="container el-input-long-officialt"
            :disabled="queryItem.tmApplicant.value==''||!!queryItem.applicationNumber.value"
            v-model.trim="queryItem.tmAddress.value"
          ></el-input>
        </template>

        <!--申请日-->
        <div class="container" >
          <div class="date" v-if="item.tmParams=='SQR'">
            <el-date-picker
              :disabled="!!queryItem.applicationNumber.value"
              v-model="queryItem.appDate.beginValue"
              type="date"
              class="el-date-editor-long-officialt"
              :placeholder="$t('query_lang.chooseDate')"
            ></el-date-picker>
            <span class="to">{{$t('query_lang.to')}}</span>
            <el-date-picker
              :disabled="!!queryItem.applicationNumber.value"
              v-model="queryItem.appDate.endValue"
              type="date"
              class="el-date-editor-long-officialt"
              :placeholder="$t('query_lang.chooseDate')"
            ></el-date-picker>
          </div>
        </div>
        <!--申请日-->
        <!-- 商标状态 -->
        <div class="container" >
          <div class="date" v-if="item.tmParams=='SBZT'">
            <!-- 不同国家显示不同商标状态 -->
            <el-select v-model="queryItem.status.value" v-if="queryData.countryStatusVuex==0" multiple>
              <el-option
                v-for="item in queryData.statusList.TM5_list"
                :key="item.index"
                :label="item.zhName"
                :value="item.index"
              ></el-option>
            </el-select>
            <el-select v-model="queryItem.status.value" v-if="queryData.countryStatusVuex==1" multiple>
              <el-option
                v-for="item in queryData.statusList.OTHER_list"
                :key="item.index"
                :label="item.zhName"
                :value="item.index"
              ></el-option>
            </el-select>
            <el-select v-model="queryItem.status.value" v-if="queryData.countryStatusVuex==2" multiple>
              <el-option
                v-for="item in queryData.statusList.COMBINE_list"
                :key="item.index"
                :label="item.zhName"
                :value="item.index"
              ></el-option>
            </el-select>
          </div>
        </div>
        <!-- 商标状态 -->
        <!-- 是否共有商标 -->
        <el-radio-group
          v-model="queryItem.istotal.value"
          class="queryMode"
          v-if="item.tmParams=='SFGYSB'"
        >
          <el-radio label="yes">是</el-radio>
          <el-radio label="no">否</el-radio>
        </el-radio-group>
        <!-- 是否共有商标 -->
        <!-- 是否有效 -->
        <el-radio-group
          v-model="queryItem.isvalid.value"
          class="queryMode"
          v-if="item.tmParams=='SFYX'"
        >
          <el-radio label="1">有效</el-radio>
          <el-radio label="0">无效</el-radio>
        </el-radio-group>
        <!-- 是否有效 -->
        <!-- 初审公告日 -->
        <div class="container" v-if="item.tmParams=='CSGGR'">
          <div class="date">
            <el-date-picker
              :disabled="!!queryItem.applicationNumber.value"
              v-model="queryItem.trialDate.beginValue"
              type="date"
              class="el-date-editor-long-officialt"
              :placeholder="$t('query_lang.chooseDate')"
            ></el-date-picker>
            <span class="to">{{$t('query_lang.to')}}</span>
            <el-date-picker
              :disabled="!!queryItem.applicationNumber.value"
              v-model="queryItem.trialDate.endValue"
              type="date"
              class="el-date-editor-long-officialt"
              :placeholder="$t('query_lang.chooseDate')"
            ></el-date-picker>
          </div>
        </div>
        <!-- 初审公告日 -->
        <!--注册日-->
        <div class="date" v-if="item.tmParams=='ZCR'">
          <el-date-picker
            :disabled="!!queryItem.applicationNumber.value"
            v-model="queryItem.regDate.beginValue"
            type="date"
            class="el-date-editor-long-officialt"
            :placeholder="$t('query_lang.chooseDate')"
          ></el-date-picker>
          <span class="to">{{$t('query_lang.to')}}</span>
          <el-date-picker
            :disabled="!!queryItem.applicationNumber.value"
            v-model="queryItem.regDate.endValue"
            type="date"
            class="el-date-editor-long-officialt"
            :placeholder="$t('query_lang.chooseDate')"
          ></el-date-picker>
        </div>
        <!--注册日-->
        <!-- 注册公告日 -->
        <div class="date" v-if="item.tmParams=='ZCGGR'">
          <el-date-picker
            :disabled="!!queryItem.applicationNumber.value"
            v-model="queryItem.ggDate.beginValue"
            type="date"
            class="el-date-editor-long-officialt"
            :placeholder="$t('query_lang.chooseDate')"
          ></el-date-picker>
          <span class="to">{{$t('query_lang.to')}}</span>
          <el-date-picker
            :disabled="!!queryItem.applicationNumber.value"
            v-model="queryItem.ggDate.endValue"
            type="date"
            class="el-date-editor-long-officialt"
            :placeholder="$t('query_lang.chooseDate')"
          ></el-date-picker>
        </div>
        <!-- 注册公告日 -->
        <!-- 优先权日 -->
        <div class="date" v-if="item.tmParams=='YXQR'">
          <el-date-picker
            :disabled="!!queryItem.applicationNumber.value"
            v-model="queryItem.priorityDate.beginValue"
            type="date"
            class="el-date-editor-long-officialt"
            :placeholder="$t('query_lang.chooseDate')"
          ></el-date-picker>
          <span class="to">{{$t('query_lang.to')}}</span>
          <el-date-picker
            :disabled="!!queryItem.applicationNumber.value"
            v-model="queryItem.priorityDate.endValue"
            type="date"
            class="el-date-editor-long-officialt"
            :placeholder="$t('query_lang.chooseDate')"
          ></el-date-picker>
        </div>
        <!-- 优先权日 -->
        <!--专用权到期日-->
        <div class="container" v-if="item.tmParams=='ZYQDQR'">
          <div class="date">
            <el-date-picker
              :disabled="!!queryItem.applicationNumber.value"
              v-model="queryItem.privateDate.beginValue"
              type="date"
              class="el-date-editor-long-officialt"
              :placeholder="$t('query_lang.chooseDate')"
            ></el-date-picker>
            <span class="to">{{$t('query_lang.to')}}</span>
            <el-date-picker
              :disabled="!!queryItem.applicationNumber.value"
              v-model="queryItem.privateDate.endValue"
              type="date"
              class="el-date-editor-long-officialt"
              :placeholder="$t('query_lang.chooseDate')"
            ></el-date-picker>
          </div>
        </div>
        <!--专用权到期日-->
        <!--代理机构-->
        <div class="container" v-if="item.tmParams=='DLJG'">
          <el-input
            @keyup.enter.native="highGradeQuery"
            class="container el-input-long-officialt"
            :disabled="!!queryItem.applicationNumber.value"
            v-model.trim="queryItem.tmAgent.value"
          ></el-input>
        </div>
        <!--代理机构-->
      </div>
      <i
        class="el-icon-delete"
        @click="delInput(item.tmParams,index)"
        style="color:black;cursor:pointer;position:absolute;right:-20px;top:15px;transform:scale(1.2);"
      ></i>
    </div>
    <el-button @click="addInput" :disabled="isAddInput" class="return-btn el-long-white-button">+ 新增</el-button>
    <!-- 高级查询改版之后 动态新增下拉数据部分 end-->

    <div class="buttons">
      <el-button
        class="query-btn el-long-defalut-button"
        @click="highGradeQuery"
        :disabled="onlyVipCanQuery&&!noReset.isPaied"
      >{{$t('query_lang.search')}}</el-button>
      <el-button
        class="return-btn el-long-white-button"
        @click="reset_Fn"
      >{{$t('follow_up_FE_lang.reset')}}</el-button>
    </div>

    <section class="powerByLandengyu">
      <div class="data-desription">
        <p>商标申请范围、最新更新时间，详见<span @click="toSheet()">《支持查询的国家/地区》</span>信息表</p>
      </div>
      <div class="disclaimer">
        {{$t('query_lang.Disclaimer')}}
      </div>
      <div class="container">
        <p>由<span @click="goFish">蓝灯鱼</span>提供技术支持</p>
<!--        <img src="../../../static/img/query/landengyu.png" @click="goFish"/>-->
      </div>
    </section>

    <!--维也纳分类-->
    <el-dialog :visible.sync="ViennaVisible" class="long-dialog-old" @open="openVienna">
      <div class="long-inner long-inner-viena">
        <viennaCodezu @closeViennaFn="ViennaVisible=false" ref="viennaCodezuRef"></viennaCodezu>
      </div>
    </el-dialog>
    <!--尼斯分类-->
    <el-dialog :visible.sync="niceVisible" class="long-dialog-old">
      <!--0124注释掉,暂时不用此模式,改用同快速查询一样的模式-->
      <div class="long-inner" v-if="false">
        <!--{{vienna.mixture}}-->
        <div class="top">
          <i class="el-icon-warning"></i>
          <span>温馨提示:&nbsp;&nbsp;可选多个</span>
        </div>
        <div class="middle nice-style">
          <el-checkbox-group v-model="niceChoosedArr">
            <ul class="first-ul" v-if="true">
              <li class="first-li" v-for="(item,index) in niceAll">
                <p class="first-p" @click="isOpenFn(item)">{{item.categoryName.slice(0,5)}}</p>
                <ul class="second-ul" v-if="item.isOpen">
                  <li class="second-li" v-for="(item2,index2) in item.children">
                    <p class="second-p" @click="isOpenFn(item2)">
                      <el-checkbox
                        @change="viennaChange"
                        :max="5"
                        :label="item2.goodsChName"
                      >{{item2.goodsChName}}</el-checkbox>
                    </p>
                  </li>
                </ul>
              </li>
            </ul>
          </el-checkbox-group>
        </div>
        <div class="bottom">
          <el-button class="viennaConfirm el-long-defalut-button" @click="niceConfirm">确认</el-button>
          <el-button class="viennaClose el-long-white-button" @click="niceClose">关闭</el-button>
        </div>
      </div>
      <div class="long-inner">
        <classify @closeFn="niceVisible=false" class="high-classify"></classify>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import Vue from "vue";
  //获取所有的分类
  import getNiceCode from './getNiceCode'
  //获取所有的国家
  import allCountry from './allCountry'
  import moment from "moment";
  import { Message } from 'element-ui';
  import { mapGetters, mapMutations, mapState } from "vuex";
  import {
    createFn,
    queryTmInfoTextByMulCountryFromDB,
    queryTmInfoGraphByMulCountryFromDB,
    progressBarFn
  } from "./querys";
  import {classifyData} from './data';//尼斯分类
  import classify from "./public/classInTheNiceClassification";
  import viennaCodezu from "./public/viennaCodezu";
  import imgDefault from "../../assets/images/query/upload.png";
  import progressBar from "./public/progressBar";
  import progressp from "./public/progress";

  const queryParamsMap = {
    'country': 'countryData'
  }

  export default {
    name: "high",
    components: {
      classify,
      viennaCodezu,
      progressp
    },
    data() {
      return {
        queryItemDefault: {
          tradeMarkText: {
            defaultText: "商标名称",
            keys: "SBMC",
            cnText: "",
            enText: "ewrwer",
            value: "",
            isMust: 1
          }, //
          tradeMarkPattern: {
            defaultText: "商标图形",
            keys: "SBTP",
            cnText: "",
            enText: "ewrwer",
            value: "",
            isMust: 1
          }, //value存储file
          patternElement: {
            defaultText: "图形要素编码",
            keys: "TXYSBM",
            cnText: "",
            enText: "ewrwer",
            value: "",
            isMust: 1
          },
          classify: {
            defaultText: "分类",
            keys: "LB",
            cnText: "",
            enText: "ewrwer",
            value: "",
            isMust: 2
          },
          applicationNumber: {
            defaultText: "申请号",
            keys: "SQH",
            cnText: "",
            enText: "ewrwer",
            value: "",
            isMust: 1
          },
          registerNumber: {
            defaultText: "注册号",
            keys: "ZCH",
            cnText: "",
            enText: "ewrwer",
            value: "",
            isMust: 1
          },
          tmApplicant: {
            defaultText: "申请人名称",
            keys: "SQRMC",
            cnText: "",
            enText: "ewrwer",
            value: "",
            isMust: 1
          },
          tmAddress: {
            defaultText: "申请人地址",
            keys: "SQRDZ",
            cnText: "",
            enText: "ewrwer",
            value: "",
            isMust: 1
          },
          status: {
            defaultText: "商标状态",
            keys: "SBZT",
            cnText: "",
            enText: "ewrwer",
            value: [],
            isMust: 2
          },
          istotal: {
            defaultText: "是否共有商标",
            keys: "SFGYSB",
            cnText: "",
            enText: "ewrwer",
            value: "",
            isMust: 2
          },
          isvalid: {
            defaultText: "是否有效",
            keys: "SFYX",
            cnText: "",
            enText: "ewrwer",
            value: "",
            isMust: 2
          },
          trialDate: {
            defaultText: "初审公告日",
            keys: "CSGGR",
            cnText: "",
            enText: "ewrwer",
            beginValue: "",
            endValue: "",
            isMust: 2,
            valueStr: ""
          },
          appDate: {
            defaultText: "申请日",
            keys: "SQR",
            cnText: "",
            enText: "ewrwer",
            beginValue: "",
            endValue: "",
            isMust: 2,
            valueStr: ""
          },
          regDate: {
            defaultText: "注册日",
            keys: "ZCR",
            cnText: "",
            enText: "ewrwer",
            beginValue: "",
            endValue: "",
            isMust: 2,
            valueStr: ""
          },
          ggDate: {
            defaultText: "注册公告日",
            keys: "ZCGGR",
            cnText: "",
            enText: "ewrwer",
            beginValue: "",
            endValue: "",
            isMust: 2,
            valueStr: ""
          },
          priorityDate: {
            defaultText: "优先权日",
            keys: "YXQR",
            cnText: "",
            enText: "ewrwer",
            beginValue: "",
            endValue: "",
            isMust: 2,
            valueStr: ""
          },
          privateDate: {
            defaultText: "专用权到期日",
            keys: "ZYQDQR",
            cnText: "",
            enText: "ewrwer",
            value: "",
            beginValue: "",
            endValue: "",
            isMust: 2,
            valueStr: ""
          },
          tmAgent: {
            defaultText: "代理机构",
            keys: "DLJG",
            cnText: "",
            enText: "ewrwer",
            value: "",
            isMust: 2
          }
        },
        queryItem: {},
        queryMode: {
          //查询模式,status,0精确查询,1近似查询,2包含查询
          // list: [
          //   {defaultText: "精确查询", cnText: "精确查询", enText: "exactQuery", val: "term"},
          //   {defaultText: "近似查询", cnText: "近似查询", enText: "approximateQuery", val: "ai"},
          //   {defaultText: "包含查询", cnText: "包含查询", enText: "includeQuery", val: "match"},
          // ],
          choosed: this.$store.state.query.queryData.queryWay
        },
        defaultText: {
          uploadText: "拖拽图片或点击上传",
          patternText: "与商标字段互斥，有商标内容则不显示图形要素",
          classifyText:
            "请输入1-45之间的阿拉伯数字,类别之间用;分隔，例如查询第九类输入：9;",
          plaseChoose: "请选择"
        },
        cnText: {
          uploadText: "拖拽图片或点击上传",
          patternText: "与商标字段互斥，有商标内容则不显示图形要素",
          classifyText: "请输入1-45之间的阿拉伯数字，例如查询第九类输入：9",
          plaseChoose: "请选择"
        },
        enText: {
          uploadText: "tuotuotuto",
          patternText: "tuotuotuto",
          classifyText: "aaaa",
          plaseChoose: "please choose"
        },
        /*维也纳分类,即图形像素*/
        ViennaVisible: false, //维也纳分类Vienna
        /*尼斯分类*/
        niceVisible: false,
        niceChoosedArr: [], //选中的尼斯分类数组
        niceAll: [], //接口获取到整个一二级的尼斯分类,
        //niceChoosedStr:"", // 选中的图形像素,见computed
        /*0124分类内容*/
        // classifyData: JSON.parse(JSON.stringify(classifyData)),//分类内容
        imgUplad: "", //上传的图片
        imgDefault: imgDefault, //默认的图片,即上传图片
        // 高级查询改版之后---初始化数据start
        val: "",
        isAddInput: false,
        query_Item1: [
          //固定查询下拉列表
          {
            tmParams: "",
            input: "",
            options: [{
              value: "SBMC",
              label: "商标名称",
              disabled: false
            },
              {
                value: "SBTP",
                label: "商标图片",
                disabled: false
              },
              {
                value: "SQRMC",
                label: "申请人名称",
                disabled: false
              },
              {
                value: "SQH",
                label: "申请号",
                disabled: false
              }

            ]
          }
        ],
        query_Items: [
          //动态新增 查询下拉列表
          {
            tmParams: "",
            input: "",
            options: [{
              value: "SBMC",
              label: "商标名称",
              disabled: false
            },
              {
                value: "SBTP",
                label: "商标图片",
                disabled: false
              },
              {
                value: "SQRMC",
                label: "申请人名称",
                disabled: false
              },
              {
                value: "SQRDZ",
                label: "申请人地址",
                disabled: false
              },
              {
                value: "SQH",
                label: "申请号",
                disabled: false
              },
              {
                value: "LB",
                label: "类别",
                disabled: false
              },
              // {
              //     value: "TXYSBM",
              //     label: "图形要素编码",
              //     disabled: false
              // },
              {
                value: "SQR",
                label: "申请日",
                disabled: false
              },
              {
                value: "ZCR",
                label: "注册日",
                disabled: false
              },
              {
                value: "CSGGR",
                label: "初审公告日",
                disabled: false
              },
              //{
              //   value: "ZCGGR",
              //  label: "注册公告日",
              //  disabled: false
              //},
              {
                value: "ZYQDQR",
                label: "专用权到期日",
                disabled: false
              },
              {
                value: "YXQR",
                label: "优先权日",
                disabled: false
              },
              // {
              //  value: "DLJG",
              //  label: "代理机构",
              //   disabled: false
              // },
              {
                value: 'ZCH',
                label: '注册号',
                disabled: false
              },
              {
                value: "SBZT",
                label: "商标状态",
                disabled: false
              },
              {
                value: "SFGYSB",
                label: "是否共有商标",
                disabled: false
              },
              {
                value: "SFYX",
                label: "是否有效",
                disabled: false
              }
            ]
          }
        ],
        // 高级查询改版之后---初始化数据end
        flagdata: "",
        selectList: [],
        onlyVipCanQuery: false,
        isSelectAll: false,
      };
    },
    created() {
      let _highQueryConditions = this.$store.state.query.queryData.highQueryConditions;
      this.queryItem = JSON.parse(JSON.stringify(this.queryItemDefault));
      if (_highQueryConditions) {
        for (let k in this.queryItem) {
          if(this.queryItem[k]['keys'] === _highQueryConditions[0].key) {
            this.query_Item1[0].tmParams = _highQueryConditions[0].key
            this.queryItem[k].value = _highQueryConditions[0].value
            this.imgUplad = _highQueryConditions[0].imgUplad
          }
        }

        if (_highQueryConditions[1].length < 1) {
          return false
        } else if (_highQueryConditions[1].length == 1) {
          for (let s in this.queryItem) {
            if(this.queryItem[s]['keys'] === _highQueryConditions[1][0].key) {
              this.query_Items[0].tmParams = _highQueryConditions[1][0].key
              this.queryItem[s].value = _highQueryConditions[1][0].value
              this.queryItem[s].beginValue = _highQueryConditions[1][0].beginValue
              this.queryItem[s].endValue = _highQueryConditions[1][0].endValue
              this.imgUplad = _highQueryConditions[1][0].imgUplad
            }
          }
        } else if (_highQueryConditions[1].length > 1) {
          for (var i = 0; i < _highQueryConditions[1].length; i ++) {
            this.addInput()
            for (let n in this.queryItem) {
              if(this.queryItem[n]['keys'] === _highQueryConditions[1][i].key) {
                this.query_Items[i].tmParams = _highQueryConditions[1][i].key
                this.queryItem[n].value = _highQueryConditions[1][i].value
                this.queryItem[n].beginValue = _highQueryConditions[1][i].beginValue
                this.queryItem[n].endValue = _highQueryConditions[1][i].endValue
                this.imgUplad = _highQueryConditions[1][i].imgUplad
              }
            }
          }
          let val = '', index = _highQueryConditions[1].length;
          this.query_Items.splice(index, 1);
          var query_Items = this.query_Items;
          var query_Item1 = this.query_Item1;
          query_Items.forEach(function(items, index) {
            let options = items.options;
            options.forEach(function(item, i) {
              if (item.value == val && val != "") {
                item["disabled"] = false;
              }
            });
          });
          query_Item1.forEach(function(items, index) {
            let options = items.options;
            options.forEach(function(item, i) {
              if (item.value == val && val != "") {
                item["disabled"] = false;
              }
            });
          });
          //删除输入框，要清空存值
          for (var key in this.queryItem){
            if (this.queryItem[key].keys == val && val != "") {
              this.queryItem[key].value="";
              this.queryItem[key].beginValue="";
              this.queryItem[key].endValue="";
            }
          }
          //商标图片删除，
          if(val=='SBTP'){
            this.imgUplad='';
          }
          //是否共有商标清空
          if(val=='SFGYSB'){
            this.queryItem.istotal.value="";
          }
          //是否有效清空
          if(val=='SFYX'){
            this.queryItem.isvalid.value="";
          }
          if (query_Items.length <= 14) {
            this.isAddInput = false;
          }
        }
      }
      this.origin = window.location.origin;
      this.origin = "dandan"; //正式环境,开发文字查询相关功能,则用此,否则请注释掉
      this.queryItemsShowFn({
        fastOrHigh: 1
      });
      this.onlyVipCanQuery = true;
      // this.$parent.getBoughtCountry(); // 查询结果页返回不需要再调查询国家接口，否则会清空国家筛选条件

    },
    mounted() {

        this.queryDataFn({
          countryList: allCountry.countryMaxDates,
          country: ['CN']
        })

      this.getNiceCodeFromDB();
      this.asideFormFn({
        country: [],
        applicant: [],
        applicantEn: [],
        niceList: [],
        imageFactory: [],
        appYear: [],
        regYear: [],
        trailYear: [],
        isvalid: [],
        ggBeginYear: [],
        priorityYear: [],
        rightPeriod: [],
        tmstatuscode: [],
        commonBrand: []
      })
    },
    methods: {
      ...mapMutations("query", [
        "setClassifyShowFn",
        "queryItemsShowFn",
        "queryDataFn",
        "ajaxResponseFn",
        "othersItemFn",
        "pageNum_Fn",
        "pageSize_Fn",
        "noResetFn",
        "queryBtnClickNumFn",
        "responseDataFn",
        "sendQueryDataFn",
        "isFirstQueryFn",
        "asideFormFn"
      ]),
      goPurchase() {
        this.$router.push({
          path: '/trademarkPayment'
        })
      },
      toSheet(){
        this.$router.push({
          path: '/query/searchableCountrySheet',
        })
      },
      goFish(){
        window.open("http://www.lanternfish.cn");
      },
      //动态输入框切换时触发
      swichFastHigh(index) { // 组合查询按钮
        this.queryDataFn({
          imgLocalUrl: this.queryData.imgDefault,
          imgLineUrl: "",
          classify: JSON.parse(JSON.stringify(this.queryData.classifyDefault))
        })
        this.queryItemsShowFn({
          reset: true,
        });
        this.queryItemsShowFn({
          fastOrHigh: index,
        });
        this.queryDataFn({
          reset: true,
        });
        this.othersItemFn({
          reset: true,
        });
        this.routerFn();
        this.queryDataFn({
          highQueryConditions: null
        })
        // this.$parent.getBoughtCountry()
      },
      routerFn() { // 切换快速查询和高级查询
        //0123上午有空放在utils中,目前main中,fastQuery中都有//0123下午需求变更,暂时不需要此函数
        if (this.queryItemsShow.fastOrHigh) {
          this.$router.push({
            path: "/query/high"
            // query: this.$route.query
          });
        } else {
          this.$router.push({
            path: "/query/fast"
            // query: this.$route.query
          });
        }
        this.queryItemsShowFn({
          result: false,
          condition: false,
          more: false
        });
        //清空查询条件
      },
      changeVal(val, index) {
        this.val = val;
        this.$set(
          this.query_Items,
          this.query_Items[this.query_Items.length - 1]["tmParams"],
          val
        );
        var query_Items = this.query_Items;
        var query_Item1 = this.query_Item1;
        var list = [];
        query_Items.forEach(function(item, index) {
          let options = item.options;
          if (item.tmParams != "") {
            list.push(item.tmParams);
          }
          options.forEach(function(item, i) {
            item["disabled"] = false;
          });
        });
        query_Item1.forEach(function(item, index) {
          let options = item.options;
          if (item.tmParams != "") {
            list.push(item.tmParams);
          }
          options.forEach(function(item, i) {
            item["disabled"] = false;
          });
        });
        query_Items.forEach(function(items, index) {
          let options = items.options;
          options.forEach(function(item, i) {
            list.forEach(function(ite) {
              if (ite == item.value) {
                item["disabled"] = true;
              }
            });
          });
        });
        query_Item1.forEach(function(items, index) {
          let options = items.options;
          options.forEach(function(item, i) {
            list.forEach(function(ite) {
              if (ite == item.value) {
                item["disabled"] = true;
              }
            });
          });
        });

        //切换输入框，要清空被切换的存值
        for (var key in this.queryItem){
          if(list.indexOf(this.queryItem[key].keys)==-1){
            if ("beginValue" in this.queryItem[key]) {
              this.queryItem[key].beginValue ='';
              this.queryItem[key].endValue ='';
            }else{
              this.queryItem[key].value="";
            }
          }
        }
        // if(val == 'SBZT') {

        // }
        //是否商标状态要默认否
        if(val=='SFGYSB'){
          this.queryItem.istotal.value="no";
        }
        //是否商有效要默认有效
        if(val=='SFYX'){
          this.queryItem.isvalid.value="1";
        }
        //图片清空
        if(list.indexOf('SBTP')==-1){
          this.imgUplad="";
        }
      },
      //固定输入框切换时触发
      changeVal1(val) {
        this.val = val;
        if(this.val != 'SBMC') {
          this.queryMode.choosed = "term";
        }
        this.$set(
          this.query_Item1,
          this.query_Item1[this.query_Item1.length - 1]["tmParams"],
          val
        );
        var query_Items = this.query_Items;
        var query_Item1 = this.query_Item1;
        var list = [];
        query_Items.forEach(function(item, index) {
          let options = item.options;
          if (item.tmParams != "") {
            list.push(item.tmParams);
          }
          options.forEach(function(item, i) {
            item["disabled"] = false;
          });
        });
        query_Item1.forEach(function(item, index) {
          let options = item.options;
          if (item.tmParams != "") {
            list.push(item.tmParams);
          }
          options.forEach(function(item, i) {
            item["disabled"] = false;
          });
        });
        query_Items.forEach(function(items, index) {
          let options = items.options;
          options.forEach(function(item, i) {
            list.forEach(function(ite) {
              if (ite == item.value) {
                item["disabled"] = true;
              }
            });
          });
        });
        query_Item1.forEach(function(items, index) {
          let options = items.options;
          options.forEach(function(item, i) {
            list.forEach(function(ite) {
              if (ite == item.value) {
                item["disabled"] = true;
              }
            });
          });
        });

        //切换输入框，要清空被切换的存值
        for (var key in this.queryItem){
          if(list.indexOf(this.queryItem[key].keys)==-1){
            this.queryItem[key].value="";
          }
        }
        //图片清空
        if(list.indexOf('SBTP')==-1){
          this.imgUplad="";
          this.queryDataFn({
            imgLineUrl: ""
          })
        }
      },
      addInput() {
        this.query_Items.push({
          tmParams: "",
          input: "",
          options: [
            {
              value: "SBMC",
              label: "商标名称",
              disabled: false,
            },
            {
              value: "SBTP",
              label: "商标图片",
              disabled: false
            },
            {
              value: "SQRMC",
              label: "申请人名称",
              disabled: false
            },
            {
              value: "SQRDZ",
              label: "申请人地址",
              disabled: false
            },
            {
              value: "SQH",
              label: "申请号",
              disabled: false
            },
            {
              value: "LB",
              label: "类别",
              disabled: false,
            },
            {
              value: "SQR",
              label: "申请日",
              disabled: false
            },
            {
              value: "TXYSBM",
              label: "图形要素编码",
              disabled: false
            },
            {
              value: "CSGGR",
              label: "初审公告日",
              disabled: false
            },
            {
              value: "ZCR",
              label: "注册日",
              disabled: false
            },
            {
              value: "ZYQDQR",
              label: "专用权到期日",
              disabled: false
            },
            {
              value: "DLJG",
              label: "代理机构",
              disabled: false
            },
            {
              value: 'ZCH',
              label: '注册号',
              disabled: false
            },
            {
              value: "SBZT",
              label: "商标状态",
              disabled: false
            },
            {
              value: "SFGYSB",
              label: "是否共有商标",
              disabled: false
            },
            {
              value: "SFYX",
              label: "是否有效",
              disabled: false
            },
            //{
            // value: "ZCGGR",
            // label: "注册公告日",
            //  disabled: false
            //},
            {
              value: "YXQR",
              label: "优先权日",
              disabled: false
            }
          ]
        });
        var query_Items = this.query_Items;
        var query_Item1 = this.query_Item1;
        var list = [];
        query_Items.forEach(function(item, index) {
          if (item.tmParams != "") {
            list.push(item.tmParams);
          }
        });
        query_Item1.forEach(function(item, index) {
          if (item.tmParams != "") {
            list.push(item.tmParams);
          }
        });

        var query_Items2 = query_Items.slice(-1);
        query_Items2.forEach(function(items, index) {
          let options = items.options;
          options.forEach(function(item, i) {
            list.forEach(function(ite) {
              if (ite == item.value) {
                item["disabled"] = true;
              }
            });
          });
        });
        // this.isDisabledSBZT();
        if (query_Items.length > 14) {
          this.isAddInput = true;
        }
      },
      delInput(val, index) {
        this.query_Items.splice(index, 1);
        var query_Items = this.query_Items;
        var query_Item1 = this.query_Item1;
        query_Items.forEach(function(items, index) {
          let options = items.options;
          options.forEach(function(item, i) {
            if (item.value == val && val != "") {
              item["disabled"] = false;
            }
          });
        });
        query_Item1.forEach(function(items, index) {
          let options = items.options;
          options.forEach(function(item, i) {
            if (item.value == val && val != "") {
              item["disabled"] = false;
            }
          });
        });
        //删除输入框，要清空存值
        for (var key in this.queryItem){
          if (this.queryItem[key].keys == val && val != "") {
            this.queryItem[key].value="";
            this.queryItem[key].beginValue="";
            this.queryItem[key].endValue="";
          }
        }
        //商标图片删除，
        if(val=='SBTP'){
          this.imgUplad='';
        }
        //是否共有商标清空
        if(val=='SFGYSB'){
          this.queryItem.istotal.value="";
        }
        //是否有效清空
        if(val=='SFYX'){
          this.queryItem.isvalid.value="";
        }
        if (query_Items.length <= 14) {
          this.isAddInput = false;
        }
        this.queryDataFn({
          classify: JSON.parse(JSON.stringify(classifyData))
        })
      },
      createFn(word) {
        createFn.bind(this)(word);
      },
      openVienna() {
        this.$nextTick(() => {
          this.$refs.viennaCodezuRef.updateVienna();
        });
      },
      uploadFn(file) {
        this.resetProgress()
          .then(data => {
            if (data.code == 200) {
              this.flagdata = "";
              let bol = this.formatCheck({
                file: file,
                suffix: ["JPG", "PNG", "GIF", "TIFF"],
                // size: 2048000,
                size: 5048000 //5mb
              });
              if (!bol) {
                return;
              }
              this.uploadAnalysis({
                file: file.raw
              })
                .then(data => {
                  this.imgUplad = data.data;
                  this.flagdata = this.imgUplad;
                  this.queryMode.choosed = "similar";
                  this.queryDataFn({
                    queryWay: this.queryMode.choosed
                  });
                  this.queryDataFn({
                    imgFile: file,
                    imgLocalUrl: data.data,
                    imgLineUrl: data.data,
                    fastSearch: "",
                    tradeMarkText: "",
                    viennaFromAI: [],
                    viennaChoosedArr: []
                  });
                  // this.clearNotAiRequire();
                })
                .catch(error => {
                  console.log(error);
                });
              if (this.flagdata == "") {
                setTimeout(() => {
                  this.$refs.child[0].etUploadProgressFn();
                }, 300);
              }
              this.defaultText.uploadText = "已上传";
              this.cnText.uploadText = "已上传";
              this.enText.uploadText = "yishangchuan";
            }
          })
          .catch(error => {});
      },
      deleteImg() {
        this.imgUplad = "";
        this.queryDataFn({
          imgFile: null,
          imgLocalUrl: imgDefault,
          imgLineUrl: ""
        });
      },
      viennaInput() {
        /*末尾去;号*/
        let str = this.queryItem.patternElement.value;
        if (!str.length) {
          resetVienna.bind(this)();
          return;
        }
        str = str.substr(-1) == ";" ? str.substr(0, str.length - 1) : str;
        let arr = str.split(";");
        /*判断是否合法*/
        let bol = true;
        arr.map(item => {
          bol = this.noReset.viennaSecondText.includes(item);
        });
        if (!bol) {
          this.$message(this.$t("follow_up_FE_lang.enter_error")); //输入错误
          resetVienna.bind(this)();
          return;
        }
        /*set*/
        this.queryDataFn({
          viennaChoosedArr: arr
        });

        function resetVienna() {
          this.noReset.vienna.map(item => {
            item.isOpen = false;
            item.children.map(item2 => {
              item2.isOpen = false;
              item2.children &&
              item2.children.map(item3 => {
                item3.isOpen = false;
                // this.queryData.viennaChoosedArr = [];
                this.queryDataFn({
                  viennaChoosedArr: []
                });
              });
            });
          });
        }
      },
      classifyInput() {
        /*末尾补;号*/
        let str = this.queryItem.classify.value;
        if (!str.length) {
          resetClassify.bind(this)();
          return;
        }
        str = str.substr(-1) == ";" ? str : str + ";";
        let arr = str.match(/\d{1,2}/g);
        if (arr == null) {
          this.$message(this.$t("follow_up_FE_lang.enter_error")); //输入错误
          resetClassify.bind(this)();
          return;
        }
        let strNew = arr.join(";") + ";";
        /*判断是否合法*/
        let bol = false;
        if (strNew == str) bol = true;
        let middleArr = arr.filter(item => item == 0 || item > 45 || item < 1);
        if (middleArr.length) bol = false;
        if (!bol) {
          this.$message(this.$t("follow_up_FE_lang.enter_error")); //输入错误
          resetClassify.bind(this)();
          return;
        }
        /*set*/
        arr.map((item, index) => {
          if (item < 10) arr[index] = "0" + item * 1;
        });
        arr = [...new Set(arr)];
        this.queryData.classify.map(item => {
          let ddd = arr.includes(item.no);
          if (ddd) {
            item.isCheck = true;
            item.isQuery = true;
          } else {
            item.isCheck = false;
            item.isQuery = false;
          }
        });
        this.queryDataFn();

        function resetClassify() {
          this.queryData.classify.map(item => {
            item.isCheck = false;
            item.isQuery = false;
          });
          this.queryDataFn();
        }
      },
      queryWayFn() {
        this.queryDataFn({
          queryWay: this.queryMode.choosed
        });
      },
      clearNotAiRequire() {
        this.queryItem.patternElement.value = "";
        // this.queryItem.tmApplicant.value = "";
        this.queryItem.tmAddress.value = "";
        this.queryItem.trialDate.beginValue = "";
        this.queryItem.trialDate.endValue = "";
        this.queryItem.appDate.beginValue = "";
        this.queryItem.appDate.endValue = "";
        this.queryItem.regDate.beginValue = "";
        this.queryItem.regDate.endValue = "";
        this.queryItem.privateDate.beginValue = "";
        this.queryItem.privateDate.endValue = "";
        this.queryItem.tmAgent.value = "";
        //this.queryItem.ggDate.beginValue = "";
        this.queryItem.priorityDate.endValue = "";
        this.queryItem.status.value = [];
      },
      isOpenFn(item) {
        item.isOpen = !item.isOpen;
      },
      viennaChange() {},
      // returnFn() {
      //   this.queryItemsShowFn({
      //     fastOrHigh: 0
      //   });
      //   this.$router.push({
      //     path: "/query/fast",
      //     query: this.$route.query
      //   });
      // },
      reset_Fn() {
        this.setClassifyShowFn();
        this.queryItem = JSON.parse(JSON.stringify(this.queryItemDefault));
        this.queryMode.choosed = "similar";
        // this.queryData.viennaChoosedArr=[];
        this.queryDataFn({
          viennaChoosedArr: []
        });
        this.queryData.classify.map(item => {
          item.isCheck = false;
          item.isQuery = false;
        });
        this.queryDataFn();
        this.deleteImg();
        this.queryData.country=['CN'];
        this.queryData.countryName='中国大陆';
      },
      //商标状态是否可选
      isDisabledSBZT(){
        //判断是否已选
        let isSelected=false;
        this.query_Items.forEach(function(items, index) {
          if (items.tmParams=='SBZT') {
            isSelected = true;
          }
        });
        //中欧美以外其他国家商标状态未定，所以不可选
        if(this.queryData.country=="CN"||this.queryData.country=="EU"||this.queryData.country=="US"){
          this.query_Items.forEach(function(item, index) {
            let options = item.options;
            options.forEach(function(item2, i) {
              if(item2["value"]=='SBZT'){
                item2["disabled"] = isSelected;
              }
            });
          });
        }else{
          this.query_Items.forEach((item, index)=> {
            let options = item.options;
            //已选则删除
            if (item.tmParams=='SBZT') {
              this.delInput('SBZT',index);
            }
          });
          //上下两个循环合一起时 第二个修改不生效，原因未知
          this.query_Items.forEach((item, index)=> {
            let options = item.options;
            options.forEach((item2, i)=> {
              //未选不可选
              if(item2["value"]=='SBZT'){
                item2["disabled"] = true;
              }
            });
          });
        }
      },
      whichLandFn(v) {
        if (this.isSelectAll) {
          this.isSelectAll = false;
          this.queryDataFn({
            tabCountryName: '',
            country: this.queryData.countryList.filter(i => !i.disabled).map(i => i.countryKey).includes('CN') ? ['CN'] : []
          })
          this.asideFormFn({
            ...this.$store.state.query.asideForm,
            country: this.queryData.country,
          })
        } else if (v.includes('all') && !this.isSelectAll) {
          this.isSelectAll = true;
          this.queryDataFn({
            tabCountryName: '',
            country: [...this.queryData.countryList.filter(i => !i.disabled).map(i => i.countryKey)]
          })
          this.asideFormFn({
            ...this.$store.state.query.asideForm,
            country: [this.queryData.countryList[0].countryKey],
          })
        } else {
          this.queryDataFn({
            tabCountryName: ''
          })
        }
        //回复默认，不可图片查询
        this.queryData.isCanUploadImg=true;
        let tmpThis=this;
        this.queryData.countryList.map((countryItem) => {
          if(tmpThis.queryData.country.join(";").includes(countryItem.countryKey)){
            tmpThis.queryData.countryName=countryItem.chName;
            //部分国家暂不支持图片查询
            if(!countryItem.isGraphQuerySelect){
              tmpThis.queryData.isCanUploadImg=false;
            }
          }
        });
        //商标状态是否可选
        // this.isDisabledSBZT();
        let TM5CountyList = ['CN', 'EU', 'US', 'JP', 'KR'],
          OtherCountryList = ["AR", "AU", "AT", "BX", "BR", "CA", "CL", "DK", "FI", "FR", "DE", "GB", "GR", "HK", "HU", "IS", "IN", "ID", "IE", "IT", "MO", "MY", "MX", "NZ", "NO", "PH", "PT", "RU", "SG", "ZA", "ES", "SE", "CH", "TW", "TH", "TR", "AE", "WO"]
        let tm5Flag = this.queryData.country.every(i => TM5CountyList.includes(i)),
          otherFlag = this.queryData.country.every(i => OtherCountryList.includes(i));
        if(tm5Flag&&!otherFlag) {
          this.queryDataFn({
            countryStatusVuex: 0
          })
        } else if(!tm5Flag&&otherFlag) {
          this.queryDataFn({
            countryStatusVuex: 1
          })
        } else {
          this.queryDataFn({
            countryStatusVuex: 2
          })
        }
        this.queryItem.status.value = [];
      },
      highGradeQuery() {
        if(this.queryData.country.length == 0) {
          Message('请至少选择一个国家！')
          return
        }
        if (this.onlyVipCanQuery && !this.noReset.isPaied) return;
        //欧盟没有优先权字段
        if(this.queryData.country=='EU'&&(this.queryItem.priorityDate.beginValue||this.queryItem.priorityDate.endValue)){
          this.$message("欧盟没有优先权日期信息，请重新选择!");
          return;
        }
        let queryRecently = "";
        //包含名称或者图片的查询queryMode=0
        if (this.imgUplad){
          queryRecently = "highGradeGraphQuery";
          this.queryData.queryMode==0;
        }else{
          queryRecently="highGradeTextQuery";
          if(this.queryItem.tradeMarkText.value){
            this.queryData.queryMode==0;
          }
        }
        //选择的法律状态
        // if(this.queryData.statusList[this.queryData.country + '_list']){
        //   this.queryData.statusList[this.queryData.country+'_list'].map((item) =>{
        //     if(item.index==this.queryItem.status.value){
        //       item.isQuery=true;
        //     }else{
        //       item.isQuery=false;
        //     }
        //     item.isCheck=false;
        //   });
        // }
        this.othersItemFn({
          queryRecently,
          result: true,
          condition: false
        });
        this.pageNum_Fn(1);
        this.othersItemFn({
          whereFast: true
        });
        this.ajaxResponseFn({
          queryTmInfo: []
        });
        this.isFirstQueryFn(true);
        /*isOk验证至少有一个必填项*/
        /*isOdd验证成对出现*/
        {
          let isOk = 0;
          for (let key in this.queryItem) {
            if ("beginValue" in this.queryItem[key]) {
              /*时间成对选择*/ //暂时不用成对
              if (
                this.queryItem[key].beginValue &&
                this.queryItem[key].endValue &&
                this.queryItem[key].beginValue > this.queryItem[key].endValue
              ) {
                this.$message(this.$t("reg_query_lang.right_range_of_time")); //请选择正确的时间段
                return;
              }
              let valueStr = "";
              if (this.queryItem[key].beginValue)
                valueStr +=
                  "从" +
                  moment(this.queryItem[key].beginValue).format("YYYY-MM-DD") +
                  "起 ";
              if (this.queryItem[key].endValue)
                valueStr +=
                  "到" +
                  moment(this.queryItem[key].endValue).format("YYYY-MM-DD") +
                  "止";
              this.queryItem[key].valueStr = valueStr;
            }
            if (this.queryItem[key].isMust == 1 && this.queryItem[key].value)
              isOk++;
          }

          if (this.imgUplad) isOk++;
          /*如果至少有一个必选条件*/
          if (isOk == 0) {
            // this.$message(this.$t('reg_query_lang.tips_which2'));//商标文字/商标图形/图样要素编码/申请号/注册号/申请人名称/申请人地址/代理机构,至少选一个
            this.$message("尊敬的用户，录入的条件有些少，请再填写一个吧！"); //需求1145
            return;
          }
        }
        this.asideFormFn({
          ...this.$store.state.query.asideForm,
          country: [this.queryData.tabCountryName || this.queryData.country[0]],
          // country: [this.queryData.country[0]],
        })
        this.setClassifyShowFn();
        let sendData = {
          tmstatuscode: this.queryItem.status.value != [] ? this.queryItem.status.value.join(';') : '', // 商标状态
          isvalid:this.queryItem.isvalid.value,
          tradeMarkText: this.queryItem.tradeMarkText.value,
          istotal: this.queryItem.istotal.value, //是否共有商标
          viennaChoosedStr: this.viennaChoosedStr, //选择的维也纳分类拼成的串
          niceChoosedStr: this.niceChoosedStr, //选择的尼斯分类
          applicationNumber: this.queryItem.applicationNumber.value, //申请号
          registerNumber: this.queryItem.registerNumber.value, //注册号
          tmApplicant: this.queryItem.tmApplicant.value, //申请人名称
          tmAddress: this.queryItem.tmAddress.value, //申请人地址
          trialDate: this.queryItem.trialDate.valueStr, //初审公告日
          trialDateBegin: moment(this.queryItem.trialDate.beginValue).format("YYYY-MM-DD"),
          trialDateEnd: moment(this.queryItem.trialDate.endValue).format("YYYY-MM-DD"),
          //ggDate: this.queryItem.ggDate.valueStr, //注册公告日
          //ggDateBegin: moment(this.queryItem.ggDate.beginValue).format(
          //  "YYYY-MM-DD"
          // ),
          //ggDateEnd: moment(this.queryItem.ggDate.endValue).format(
          //  "YYYY-MM-DD"
          //),
          priorityDate: this.queryItem.priorityDate.valueStr, //优先权日
          priorityDateBegin: moment(this.queryItem.priorityDate.beginValue).format("YYYY-MM-DD"),
          priorityDateEnd: moment(this.queryItem.priorityDate.endValue).format("YYYY-MM-DD"),
          appDate: this.queryItem.appDate.valueStr, //申请日
          appDateBegin: moment(this.queryItem.appDate.beginValue).format("YYYY-MM-DD"),
          appDateEnd: moment(this.queryItem.appDate.endValue).format("YYYY-MM-DD"),
          regDate: this.queryItem.regDate.valueStr, //注册日
          regDateBegin: moment(this.queryItem.regDate.beginValue).format("YYYY-MM-DD"),
          regDateEnd: moment(this.queryItem.regDate.endValue).format("YYYY-MM-DD"),
          privateDate: this.queryItem.privateDate.valueStr, //专用权到期日
          privateDateBegin: moment(this.queryItem.privateDate.beginValue).format("YYYY-MM-DD"),
          privateDateEnd: moment(this.queryItem.privateDate.endValue).format("YYYY-MM-DD"),
          tmAgent: this.queryItem.tmAgent.value, //代理机构
          position: { x: 0, y: 0, width: 0, height: 0 }
        };
        let optionalCondition = []
        this.query_Items.forEach(item => {
          let obj = {}
          for (let k in this.queryItem) {
            if (this.queryItem[k]["keys"] === item.tmParams) {
              obj.key = item.tmParams
              obj.beginValue = this.queryItem[k]['beginValue']
              obj.endValue = this.queryItem[k]['endValue']
              obj.value = this.queryItem[k]['value']
              obj.imgUplad = this.queryData.imgLineUrl
              optionalCondition.push(obj)
            }
          }
        })

        let solidCondition = {}
        for (let k in this.queryItem) {
          if (this.queryItem[k]["keys"] === this.query_Item1[0].tmParams) {
            solidCondition.key = this.query_Item1[0].tmParams
            solidCondition.value = this.queryItem[k]['value']
            solidCondition.imgUplad = this.queryData.imgLineUrl
          }
        }
        this.queryDataFn({
          highQueryConditions: [solidCondition, optionalCondition]
        })
        this.queryDataFn(sendData);
        let queryBtnClickNum = this.queryBtnClickNum;
        this.queryBtnClickNumFn(++queryBtnClickNum);
        this.queryItemsShowFn({
          noDataBol: false
        });
        if (this.imgUplad) {
          if (!this.classifyChoosed.length) {
            // this.$message("图片查询需要先选择分类");//不需要翻译
            this.queryData.classify.map(item => {
              item.isCheck = true;
              item.isQuery = true;
            });
          }
          queryTmInfoGraphByMulCountryFromDB.bind(this)();
        } else {
          if (this.queryItem.tradeMarkText.value && !this.classifyChoosed.length ) {
            this.queryData.classify.map(item => {
              item.isCheck = true;
              item.isQuery = true;
            });
          }
          this.queryDataFn({
            queryWay: this.queryMode.choosed,
          });
          this.createFn(this.queryData.tradeMarkText);
          queryTmInfoTextByMulCountryFromDB.bind(this)();
        }

        this.$router.push({
          path: '/query/result',
          query: {
            isFreshFlag: false,
            backpath: 'high'
          }
        })
        // this.reset_Fn()

      },
      /*尼斯分类(大类和类似群组),逻辑直接从维也纳分类复制,并简单修改*/
      getNiceCodeFromDB() {
        getNiceCode()
          .then(data => {
            data.data.map(item => {
              item.isOpen = false; //是否展开
              item.isCheck = false; //是否选中
              item.isQuery = false; //是否查询
              item.no = item.category > 10 ? item.category : "0" + item.category; //与data.js保持一致
              item.name = item.categoryName.slice(0, 4); //与data.js保持一致
              // item.categoryNameSplice0_4=item.categoryName.slice(0,4);
              item.children.map(item2 => {
                item2.isOpen = false;
                item2.isCheck = false;
                item.isQuery = false; //是否查询
                /*                item2.children && item2.children.map((item3) => {
                                                    item3.isOpen = false;
                                                    item3.isCheck = false;
                                                  })*/
              });
            });
            this.niceAll = data.data;
          })
          .catch(error => {
            console.log(error);
          });
      },
      /*尼斯分类,用于更新data.js*/
      getNiceCodeFromDB2() {
        getNiceCode()
          .then(data => {
            let arr = [];
            data.data.sort((item1, item2) => item1.category - item2.category);
            data.data.map(item => {
              arr.push({
                no: item.category < 10 ? "0" + item.category : item.category,
                name: item.categoryName,
                nameEn: item.categoryEnName,
                isCheck: false,
                isQuery: false
              });
            });
          })
          .catch(error => {
            console.log(error);
          });
      },
      niceConfirm() {
        this.niceVisible = false;
      },
      niceClose() {
        this.niceVisible = false;
      },
      /*0124尼斯分类*/
      classifyChange(item) {
        item.isCheck = !item.isCheck;
        item.isQuery = item.isCheck;
        if (
          this.queryData.classify.filter(item2 => item2.isCheck == false).length
        )
          this.othersItemFn({
            checkAll: false
          });
        else
          this.othersItemFn({
            checkAll: true
          });
        this.queryDataFn(); //刷新vuex工具,以便可视及时更新;
      },
      checkedAllFn() {
        this.queryData.classify.map(item => {
          item.isCheck = this.othersItem.checkAll;
          item.isQuery = this.othersItem.checkAll;
        });
        this.queryDataFn();
      },
      classifyCancel() {
        this.queryDataFn({
          classify: JSON.parse(JSON.stringify(this.queryData.classifyDefault))
        });
      },
      classifyConfirm() {
        //高级查询中确定不能让condition为true
        this.queryItemsShowFn({
          condition: false
        });
      },
    },
    computed: {
      ...mapState("query", [
        "queryItemsShow",
        "queryData",
        "ajaxResponse",
        "othersItem",
        "pageNum_",
        "pageSize_",
        "noReset",
        "queryBtnClickNum",
        "statusList",
        "countryStatusVuex"
      ]),
      ...mapGetters("query", ["classifyChoosed", "classifyStatus"]),
      viennaChoosedStr() {
        let imageType = "";
        if(this.queryData.viennaChoosedArr){
          this.queryData.viennaChoosedArr.map((item, index) => {
            imageType +=
              item +
              (index == this.queryData.viennaChoosedArr.length - 1 ? "" : ";");
          });
          this.queryItem.patternElement.value = imageType;
        }
        return imageType;
      },
      niceChoosedStr() {
        /*0124临时代码*/
        let imageType = "";
        this.queryData.classify.map((item, index) => {
          if (item.isCheck)
            imageType += item.no + (index == this.niceChoosedArr.length - 1 ? "" : ";");
        });
        this.queryItem.classify.value = imageType;
        return imageType;
      },
      ...['country'].reduce((obj, cur) => {
        obj[queryParamsMap[cur]] = {
          get() {
            return this.$store.state.query.queryData[cur]
          },
          set(v) {
            // 使用vuex中的mutations中定义好的方法来改变
            this.queryDataFn({...this.$store.state.query.queryData,[cur]:v})
          }
        }
        return obj;
      }, {})
    }
  };
</script>

<style lang="less">
  .high-grade-query {
    &.high-grade-query-en {
      .query-item {
        /*margin-bottom: 0;*/
        & > * {
          display: block;
        }

        .title {
          width: 100%;
          height: 32px;
          line-height: 32px;
        }

        .container {
          width: 100%;

          .choose {
            width: 100%;

            .leftText {
              width: 500px;
              height: 100%;
              font-size: 14px;
            }

            .rightText {
              width: 130px;
              text-align: center;
            }
          }

          .el-date-editor-long-officialt.el-date-editor {
            width: 300px;

            &.is-disabled {
              background: #eeeeee;
            }
          }
        }
      }
    }

    width: 660px;
    margin: 0 auto;
    padding-bottom: 184px;
    margin-top: 50px;
    .toHigh, .toFast {
      margin: 0 auto;
      /*width: 134px;*/
      cursor: pointer;
      & > * {
        display: inline-block;
      }
      &.mt320 {
        margin-top: 327px;
      }
      .el-icon-arrow-right, .el-icon-arrow-left {
        width: 24px;
        height: 24px;
        background: #cfcfcf;
        color: #ffffff;
        line-height: 24px;
        text-align: center;
        font-size: 20px;
        border-radius: 50%;
        vertical-align: middle;
      }
      i:hover{
        background-color: #3a6ecf;
      }

      span {
        font-size: 16px;
        letter-spacing: 1px;
        color: #333333;
        vertical-align: middle;
      }
    }
    .toFast {
      position: absolute;
      top: 10px;
      right: 20px;
      z-index: 2;
    }
    .toFast:hover{
      i{
        background-color: #3a6ecf;
      }
      span{
        color:#3a6ecf
      }
    }

    .query-item {
      font-size: 0;
      margin-bottom: 15px;
      position: relative;
      .el-icon-delete{
        &:hover{
          color: red !important;
        }
      }
      & > * {
        display: inline-block;
        vertical-align: top;
        font-size: 14px;
      }

      .title {
        width: 136px;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        letter-spacing: 1px;
        color: #333333;
        .el-input--suffix {
          .el-input__inner {
            border-radius: 4px 0 0 4px;
          }
        }
      }

      .container {
        width: 520px;
        .el-input__inner {
          border-radius: 0 4px 4px 0;
          height: 40px;
        }
        .el-radio-group{
          margin-left: 15px;
        }
        .el-select{
          .el-input--suffix{
            width: 520px;
          }
        }
        .queryMode {
          margin-top: 12px;
        }

        .upload-drag {
          border: 1px solid #d8dce5;
          height: 100px;

          &.uploadDisabled {
            position: relative;
            border: 0;

            .el-upload {
              background: #eeeeee;

              .el-upload-dragger {
                p {
                  color: #c0c4cc;
                }

                .el-upload-disabled-shade {
                  display: block;
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  /*background: rgba(255, 238, 245, 0.6);*/
                  top: 0;
                  right: 0;
                  cursor: not-allowed;
                }
              }
            }
          }

          .el-upload {
            width: 100%;
            height: 100%;
            background: transparent;

            .el-upload-dragger {
              border: none !important;
              width: 100%;
              height: 100%;
              background: transparent;
              position: relative;
              /*padding: 10px;*/
              .upload-btn {
                width: 38px;
                height: 36px;
                margin-top: 10px;
              }

              .upload-img {
                height: 96%;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
              }

              p.delete {
                position: absolute;
                z-index: 5;
                right: 10px;
              }

              p {
                margin-top: 5px;
                font-size: 12px;
                letter-spacing: 1px;
                color: #999999;

                &.delete:hover {
                  color: #0b6ce8;
                }
              }

              .el-upload-disabled-shade {
                display: none;
              }
            }
          }
        }

        /*图形要素呢内*/
        .choose {
          /*background: #eeeeee;*/
          /*border: 1px solid #d8dce5;*/
          width: 500px;
          height: 36px;
          border-radius: 5px;
          font-size: 0;
          /*padding: 5px 0px;*/
          line-height: 36px;
          font-size: 0;

          & > * {
            display: inline-block;
            vertical-align: top;
            font-size: 12px;
            letter-spacing: 1px;
          }

          span.leftText {
            width: 436px;
            background: transparent;
            font-size: 12px;
            height: 36px;
            display: inline-block;
            padding: 0 15px;
            line-height: 36px;
            color: #999999;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .rightText {
            font-size: 14px;
            color: #666666;
            /* height: 100%; */
            width: 65px;
            margin-left: 5px;
            line-height: 34px;
            border-radius: 3px;
            margin-top: 2px;
          }
        }

        .date {
          font-size: 0;

          & > * {
            font-size: 12px;
            vertical-align: middle;
          }

          .to {
            width: 40px;
            display: inline-block;
            text-align: center;
            font-size: 14px;
            letter-spacing: 1px;
            color: #333333;
          }
        }
      }
    }

    .buttons {
      overflow: hidden;
      text-align: left;

      .el-checkbox {
        margin-left: 40px;
      }

      .el-button {
        float: right;

        &.return-btn {
          margin-right: 20px;
        }
      }
    }

    .long-dialog-old.el-dialog__wrapper {
      text-align: center;

      .el-dialog {
        width: auto;
        display: inline-block;
      }

      .el-dialog__header {
        padding: 0;
        height: 0px;
        display: inline-block;

        .el-icon-close {
          top: 0;
          right: 0;
          font-size: 20px;
        }
      }

      .el-dialog__body {
        padding: 0;
        display: inline-block;
      }

      .long-inner {
        width: 740px;
        border-radius: 30px;
        text-align: left;

        &.long-inner-viena {
          width: 950px;
        }

        .top {
          height: 53px;
          line-height: 53px;
          font-size: 14px;
          letter-spacing: 1px;
          color: #666666;
          padding: 0px 50px;
          border-bottom: 1px solid #cecece;
          background-color: #efefef;
          border-radius: 3px;

          i {
            color: #e96364;
          }

          span {
            margin-left: 12px;
          }
        }

        /*尼斯分类*/
        .middle.nice-style {
          height: 530px;
          overflow: auto;

          .first-ul {
            font-size: 13px;

            .first-li {
              padding: 0;
              text-indent: 50px;

              .first-p {
                cursor: pointer;
                height: 40px;
                line-height: 40px;
                font-size: 14px;
                letter-spacing: 0px;
                color: #333333;

                &:hover {
                  background: #f7f7f7;
                }

                .el-checkbox {
                  text-indent: 0;
                  //复选框样式见long.scss
                }
              }

              .second-ul {
                .second-li {
                  padding: 0;
                  text-indent: 50+18px;

                  &:nth-last-of-type(1) {
                    padding-bottom: 0;
                  }

                  .second-p {
                    cursor: pointer;
                    height: 34px;
                    line-height: 34px;

                    &:hover {
                      background: #f7f7f7;
                    }

                    .el-checkbox {
                      text-indent: 0;
                      //复选框样式见long.scss
                    }
                  }
                }
              }
            }
          }
        }

        .bottom {
          height: 94px;
          padding: 30px 54px;
          text-align: right;

          .el-button {
            height: 30px;
            width: 100px;
          }

          .viennaConfirm {
          }

          .viennaClose {
            margin-left: 22px;
          }
        }
      }
    }

    .el-input-long-officialt {
      width: 500px;
      height: 40px;
      border-radius: 0 4px 4px 0;

      &.is-disabled {
        input.el-input__inner {
          background-color: #eeeeee;
          border-color: #eeeeee;
          color: #c0c4cc;
          cursor: not-allowed;
        }
      }
    }

    .el-date-editor-long-officialt.el-date-editor {
      width: 230px;

      &.is-disabled {
        input.el-input__inner {
          background-color: #eeeeee;
          color: #c0c4cc;
          border: 0;
        }
      }
    }

    .el-select-long-officialt {
      width: 500px;
    }

    .el-long-white-button {
      &:hover {
        span {
          color: #ffffff;
        }
      }

      &.is-disabled {
        span {
          color: #c0c4cc;
        }
      }
    }

    .el-input {
      &.leftText {
        width: 430px;

        input {
          width: inherit;
          vertical-align: top;
          height: 40px;
          line-height: 40px;
          border: 0;
          background: transparent;
          border: 1px solid #d8dce5;
        }
      }

      /*.leftText .el-input__inner:focus{
                  border:1px solid #409eff!important;
              }*/
    }

    /*0124尼斯分类*/
    /*    section.classify {
            width: 100%;
            & > div {
              margin-left: 5px;
              width: 740px;
              height: 440px;
              padding: 35px 0 57px 0px;
              background-color: #ffffff;
              box-shadow: 0px 2px 18px 0px rgba(47, 47, 47, 0.18);
              border-radius: 5px;
              ul {
                text-align: left;
                li {
                  display: inline-block;
                  height: 35px;
                  width: 98px;
                  margin-left: 42px;
                  line-height: 35px;
                  font-size: 13px;
                  cursor: pointer;
                  color: #333333;
                  &:hover {
                    color: #eb4142;
                  }
                  &.checked {
                    color: #e25665;
                  }
                }
              }
              .buttons {
                margin-top: 25px;
                padding-right: 68px;
                overflow: hidden;
                .el-button {
                  float: right;
                  width: 90px;
                  height: 34px;
                  padding: 0;
                  font-size: 16px;
                  border-radius: 5px;
                  !*确定按钮*!
                  &:nth-of-type(1) {
                    border: solid 1px transparent;
                    background-color: #d01027;
                    color: #ffffff;
                    &:hover {
                      background-color: #c6293a;
                    }
                  }
                  !*取消按钮*!
                  &:nth-of-type(2) {
                    border: solid 1px #aaaaaa;
                    margin-right: 20px;
                    &:hover {
                      color: #ffffff;
                      background-color: #434554;
                    }
                  }
                }

              }
            }
          }*/
    .class-in-the-Nice-classification.high-classify {
      /*有序号有内容的时候*/
      /*      &.en {
                & > div {
                  padding: 35px 0 20px 0px;
                  ul {
                    height: 450px;
                  }
                }
              }*/
    }
  }

  .el-input-long-officialt input.el-input__inner:focus {
    border: 1px solid #409eff;
  }
  .powerByLandengyu {
    position: absolute;
    bottom: 40px;
    left: 45%;
    transform: translateX(-40%);
    color: #999;
    .data-desription {
      text-align: center;
      font-size: 12px;
      margin-bottom: 8px;
      span {
        cursor: pointer;
        color: #4c92d8;
      }
      .left {
        margin-right: 12px;
      }
    }
    .disclaimer{
      width: 840px;
      margin: 0 auto;
      font-size: 12px;
      line-height: 22px;
      margin-bottom: 8px;
      text-align: center;
    }
    .container {
      width: 840px;
      text-align: center;
      font-size: 0;
      & > * {
        font-size: 14px;
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
      }
      span{
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0px;
        color: #4c92d8;
        margin: 0 2px;
      }
      img{
        height: 26px;
      }
    }
    .container span:hover {
      color: rgb(64, 187, 224);
      border-bottom: 1px solid rgb(64, 187, 224);
    }

  }
</style>
