<template>
  <section class="classify class-in-the-Nice-classification" :class="{en:$i18n.locale=='en-US','cn-new':$i18n.locale=='zh-CN'}">
    <div>
      <ul>
        <li v-for="(item,index) in queryData.classify" @click="classifyChange(item)" :class="{checked:item.isCheck}">
          <!--<span class="left_">{{item.no}}</span>-->
          <!--<span class="right_">{{$i18n.locale=='en-US'?item.nameEn:item.name}}</span>-->
          <el-tooltip class="no" effect="dark" :content="$i18n.locale=='en-US'?item.nameEn:item.name" placement="top">
            <span class="nice-no">{{item.no}}</span>
          </el-tooltip>
        </li>
      </ul>
      <p class="under-ul-line"></p>
      <div class="buttons">
        <el-checkbox v-model="othersItem.checkAll" @change="checkedAllFn();">
          {{$t('query_lang.checkAll')}}
        </el-checkbox>
        <el-button @click="classifyConfirm();" class="niceConfirm el-long-defalut-button">{{$t('query_lang.confirm')}}</el-button>
        <!--<el-button plain @click="classifyCancel();" v-if="false" class="niceClose el-long-white-button">{{$t('query_lang.cancel')}}</el-button>-->
        <el-button plain @click="classifyReset();" class="niceClose el-long-white-button resetButtons" style="margin-right: 20px;">{{$t('follow_up_FE_lang.reset')}}</el-button>
      </div>
    </div>
  </section>
</template>

<script>
  /**
   * 请在引用处控制显隐,如<classify v-show="queryItemsShow.isShow.classify" class="classify"></classify>
   * 请在使用处,根据需要设置定位section.classify{position: absolute;};
   * **/
  import {mapState, mapMutations, mapGetters} from 'vuex';
  export default {
    name: "class-in-the-Nice-classification",
    data() {
      return {}
    },
    created() {

    },
    mounted() {
      this.classifyChange();
    },
    methods: {
      ...mapMutations('query', ['setClassifyShowFn','queryItemsShowFn', 'queryDataFn', 'ajaxResponseFn', 'othersItemFn', 'pageNum_Fn', 'pageSize_Fn']),
      classifyChange(item) {
        if(item){
          item.isCheck = !item.isCheck;
          item.isQuery = item.isCheck;
        }
        if (this.queryData.classify.filter((item2) => item2.isCheck == false).length) this.othersItemFn({checkAll: false});
        else this.othersItemFn({checkAll: true});
        this.queryDataFn();//刷新vuex工具,以便可视及时更新;
      },
      checkedAllFn() {
        this.queryData.classify.map((item) => {
          item.isCheck = this.othersItem.checkAll;
          item.isQuery = this.othersItem.checkAll;
        });
        this.queryDataFn();
      },
      classifyCancel() {
        // this.queryDataFn({
        //   classify: JSON.parse(JSON.stringify(this.queryData.classifyDefault))
        // })
        /*关闭方式2*/
        this.queryItemsShowFn({
          isShow:{
            classify: false,
            upload: false,
            progressBar: false,
          }
        })
        /*关闭方式1*/
        this.$emit("closeFn");
      },
      classifyReset(){
        this.othersItem.checkAll=false;
        this.checkedAllFn();
        this.setClassifyShowFn();
      },
      classifyConfirm() {
        /*快速查询,高级查询特有*/
        this.queryItemsShowFn({
          condition: true,
        });
        this.othersItemFn({
          whereFast: true,
        });
        /*关闭方式2*/
        this.queryItemsShowFn({
          isShow:{
            classify: false,
            upload: false,
            progressBar: false,
          }
        });
        /*关闭方式1*/
        this.setClassifyShowFn();
        this.$emit("closeFn");
        this.$emit("classifyConfirm");
      },
    },
    computed: {
    ...mapState('query', ['queryItemsShow', 'queryData', 'ajaxResponse', 'othersItem', 'pageNum_', 'pageSize_']),
    ...mapGetters("query", ['classifyChoosed','classifyStatus']),
    }
  }
</script>

<style scoped lang="less">
  .class-in-the-Nice-classification {
    /*有序号有内容的时候*/
    /*&.en{
      & > div{
        padding: 35px 0 20px 0px;
        ul{
          height: 250px;
        }
      }
    }
    &.cn-new{
      & > div{
        padding: 35px 0 22px 0px;
        ul{
          li{
            width: 198px;
            .right_{

              width: 168px;
            }
          }
        }
      }
    }*/
    z-index: 2;
    width: 100%;
    text-align: left;
    & > div {
      margin-left: 5px;
      width: 740px;
      padding: 35px 0 57px 0px;
      background-color: #ffffff;
      box-shadow: 0px 2px 18px 0px rgba(47, 47, 47, 0.18);
      border-radius: 5px;
      /*有序号有内容的时候*/
      /*ul {
        height: 440px;
        overflow: auto;
        li {
          display: inline-block;
          !*min-height: 35px;*!
          width:317px;
          margin-left: 42px;
          cursor: pointer;
          color: #333333;
          margin-bottom: 2px;
          font-size: 0;
          !*border-bottom: 1px solid red;*!
          line-height: 26px;
          &>*{
            display: inline-block;
            font-size: 13px;
            vertical-align: top;
            !*line-height: 16px;*!
          }
          &:hover {
            color: #eb4142;
          }
          &.checked {
            color: #e25665;
          }
          .left_{
            !*background: green;*!
            width: 30px;
          }
          .right_{
            !*background: yellow;*!
            width: 317-30px;
          }

        }
        li:nth-of-type(3n+1){
          &:after{
            display: block;
            width:10px;
            background: red;
            content:"";
          }
        }
      }*/
      /*只有序号的时候*/
      ul {
        height: 200px;
        overflow: auto;
        li {
          display: inline-block;
          /*min-height: 35px;*/
          width: 32px;
          height: 32px;
          line-height: 32px;
          border-radius: 50%;
          background: #ffffff;
          cursor: pointer;
          color: #333333;
          margin-bottom: 8px;
          letter-spacing: 1px;
          text-align: center;
          margin-left: 44px;
          &:hover {
            color: #d01027;
          }
          &.checked {
            background: #d01027;
            color: #ffffff;
          }
        }
        li:nth-of-type(5n+1){

        }
        li:nth-of-type(3n+1){
          &:after{
            display: block;
            width:10px;
            background: red;
            content:"";
          }
        }
      }
      .under-ul-line {
        height: 1px;
        width: 660px;
        margin: 0 auto;
        background: #eaeaea;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .buttons {
        margin-top: 15px;
        padding-right: 68px;
        overflow: hidden;
        .el-checkbox {
          margin-left: 40px;
        }
        .el-button {
          float: right;
          width: 90px;
          height: 34px;
          /*确定按钮*/
          &:nth-of-type(1) {

          }
          /*取消按钮*/
          &:nth-of-type(2) {

          }
        }

      }
      .resetButtons:hover{
        background-color:#434554;
        border-color: #434554;
        color:#fff;
      }
    }
  }
</style>
