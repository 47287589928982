export default {
  "code": 200,
  "data":[
  {
    "category": "1",
    "categoryEnName": "Industrial chemicals",
    "categoryName": "化学品及肥料",
    "children": [
      {
        "category": "1",
        "categoryEnName": "Industrial chemicals",
        "categoryName": "化学品及肥料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "化学用硫华",
        "goodsEnName": "Flowers of sulphur for chemical purposes",
        "goodsJpName": "化学用硫黄華",
        "goodsNo": "010299",
        "id": 57383,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Industrial gases, elementary substance",
        "similarGroup": "0101",
        "similarItem": "0101（二）",
        "similarName": "工业气体,单质",
        "type": 0,
        "updateTime": "2021-05-25 11:22:08"
      },
      {
        "category": "1",
        "categoryEnName": "Industrial chemicals",
        "categoryName": "化学品及肥料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "甲基苯",
        "goodsEnName": "Methyl benzene",
        "goodsJpName": "メチルベンゼン",
        "goodsNo": "010577",
        "id": 56585,
        "niceCodeLists": "",
        "remark": "修改注2",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Industrial chemical materials for industry, science, agriculture, horticulture, forest",
        "similarGroup": "0102",
        "similarItem": "0102（七）",
        "similarName": "用于工业、科学、农业、园艺、林业的工业化工原料",
        "type": 0,
        "updateTime": "2021-05-25 11:25:10"
      },
      {
        "category": "1",
        "categoryEnName": "Industrial chemicals",
        "categoryName": "化学品及肥料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "原子堆用燃料",
        "goodsEnName": "fuel for atomic pile",
        "goodsJpName": "原子炉用燃料",
        "goodsNo": "",
        "id": 57257,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Radioactive elements and their chemical products",
        "similarGroup": "0103",
        "similarItem": "0103",
        "similarName": "放射性元素及其化学品",
        "type": 0,
        "updateTime": "2021-05-25 11:03:38"
      },
      {
        "category": "1",
        "categoryEnName": "Industrial chemicals",
        "categoryName": "化学品及肥料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "工业用沸石",
        "goodsEnName": "Zeolite for industrial use",
        "goodsJpName": "工業用ゼオライト",
        "goodsNo": "",
        "id": 56207,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Chemical products for industrial and scientific purposes, chemical preparations, chemical products not used by goods from other classes",
        "similarGroup": "0104",
        "similarItem": "",
        "similarName": "用于工业、科学的化学品、化学制剂,不属于其他类别的产品用的化学制品",
        "type": 0,
        "updateTime": "2021-05-25 10:24:45"
      },
      {
        "category": "1",
        "categoryEnName": "Industrial chemicals",
        "categoryName": "化学品及肥料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "杀真菌剂用化学添加剂",
        "goodsEnName": "Additives, chemical, to fungicides / Chemical additives to fungicides",
        "goodsJpName": "殺菌剤用添加剤",
        "goodsNo": "010309",
        "id": 56473,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Chemical products and chemical preparations for agriculture, horticulture, forest ",
        "similarGroup": "0105",
        "similarItem": "0105Z1",
        "similarName": "用于农业、园艺、林业的化学品、化学制剂",
        "type": 0,
        "updateTime": "2021-05-25 10:36:26"
      },
      {
        "category": "1",
        "categoryEnName": "Industrial chemicals",
        "categoryName": "化学品及肥料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "非医用、非兽医用生物制剂",
        "goodsEnName": "Biological preparations, other than for medical or veterinary purposes",
        "goodsJpName": "生物学的製剤（医療用及び獣医科用のものを除く。）",
        "goodsNo": "010579",
        "id": 66868,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Chemical reagent",
        "similarGroup": "0106",
        "similarItem": "0106",
        "similarName": "化学试剂",
        "type": 0,
        "updateTime": "2021-05-25 10:41:31"
      },
      {
        "category": "1",
        "categoryEnName": "Industrial chemicals",
        "categoryName": "化学品及肥料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "摄影用明胶",
        "goodsEnName": "Gelatine for photographic purposes",
        "goodsJpName": "写真用ゼラチン",
        "goodsNo": "010329",
        "id": 56900,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Chemical appliances and material for photography",
        "similarGroup": "0107",
        "similarItem": "0107",
        "similarName": "摄影用化学用品及材料",
        "type": 0,
        "updateTime": "2021-05-25 11:16:56"
      },
      {
        "category": "1",
        "categoryEnName": "Industrial chemicals",
        "categoryName": "化学品及肥料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "塑料分散剂",
        "goodsEnName": "Dispersions of plastics",
        "goodsJpName": "プラスチック分散剤",
        "goodsNo": "010605",
        "id": 59028,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Unprocessed artificial synthetic resin, unprocessed plastic substances(not including unprocessed natural resin)",
        "similarGroup": "0108",
        "similarItem": "0108（二）",
        "similarName": "未加工的人造合成树脂,未加工塑料物质（不包括未加工的天然树脂）",
        "type": 0,
        "updateTime": "2021-05-25 10:57:58"
      },
      {
        "category": "1",
        "categoryEnName": "Industrial chemicals",
        "categoryName": "化学品及肥料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "鸟粪",
        "goodsEnName": "Guano",
        "goodsJpName": "グアノ",
        "goodsNo": "010342",
        "id": 56459,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Fertilizers",
        "similarGroup": "0109",
        "similarItem": "0109",
        "similarName": "肥料",
        "type": 0,
        "updateTime": "2021-05-25 10:57:59"
      },
      {
        "category": "1",
        "categoryEnName": "Industrial chemicals",
        "categoryName": "化学品及肥料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "消防泡沫液",
        "goodsEnName": "Fire-fighting foam liquid",
        "goodsJpName": "消防バブル液",
        "goodsNo": "C010247",
        "id": 60078,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Complex for fire extinguisher and proofing",
        "similarGroup": "0110",
        "similarItem": "0110",
        "similarName": "灭火、防火用合成物",
        "type": 0,
        "updateTime": "2021-05-25 10:41:47"
      },
      {
        "category": "1",
        "categoryEnName": "Industrial chemicals",
        "categoryName": "化学品及肥料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "金属回火剂",
        "goodsEnName": "Metal tempering preparations",
        "goodsJpName": "焼戻し剤",
        "goodsNo": "010682",
        "id": 69668,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Chemical preparations for quenching ",
        "similarGroup": "0111",
        "similarItem": "0111",
        "similarName": "淬火用化学制剂",
        "type": 0,
        "updateTime": "2021-05-25 10:41:47"
      },
      {
        "category": "1",
        "categoryEnName": "Industrial chemicals",
        "categoryName": "化学品及肥料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "助焊剂",
        "goodsEnName": "Soldering fluxes ",
        "goodsJpName": "はんだ付け用融剤\n",
        "goodsNo": "010584",
        "id": 60085,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Chemical preparations for jointing",
        "similarGroup": "0112",
        "similarItem": "0112",
        "similarName": "焊接用化学制剂",
        "type": 1,
        "updateTime": "2021-05-25 11:03:25"
      },
      {
        "category": "1",
        "categoryEnName": "Industrial chemicals",
        "categoryName": "化学品及肥料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "水果催熟用激素",
        "goodsEnName": "Hormones for hastening the ripening of fruit",
        "goodsJpName": "果実の熟成促進用ホルモン",
        "goodsNo": "010346",
        "id": 58944,
        "niceCodeLists": "",
        "remark": "2018版【新增注】 ",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Chemical substances for foodstuff (not including antiseptic salts for foodstuff)",
        "similarGroup": "0113",
        "similarItem": "0113",
        "similarName": "食药用化学品（不包括食品用防腐盐）",
        "type": 0,
        "updateTime": "2021-05-25 11:03:28"
      },
      {
        "category": "1",
        "categoryEnName": "Industrial chemicals",
        "categoryName": "化学品及肥料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "皮革鞣剂",
        "goodsEnName": "Currying preparations for leather",
        "goodsJpName": "革仕上剤",
        "goodsNo": "010215",
        "id": 72580,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Chemical substances for tanning materials and leathers",
        "similarGroup": "0114",
        "similarItem": "0114",
        "similarName": "鞣料及皮革用化学品",
        "type": 1,
        "updateTime": "2021-05-25 10:42:15"
      },
      {
        "category": "1",
        "categoryEnName": "Industrial chemicals",
        "categoryName": "化学品及肥料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "增塑溶胶",
        "goodsEnName": "Plastisols",
        "goodsJpName": "プラスチゾル",
        "goodsNo": "010439",
        "id": 58601,
        "niceCodeLists": "",
        "remark": "2018版【修改注】 2",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Adhesives and glues for industrial purposes (not including adhesives for paper)",
        "similarGroup": "0115",
        "similarItem": "0115",
        "similarName": "工业用粘合剂和胶（不包括纸用粘合剂）",
        "type": 0,
        "updateTime": "2021-05-25 11:30:58"
      },
      {
        "category": "1",
        "categoryEnName": "Industrial chemicals",
        "categoryName": "化学品及肥料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "纤维素浆",
        "goodsEnName": "Cellulose paste",
        "goodsJpName": "セルロースペースト\n",
        "goodsNo": "C010255",
        "id": 59021,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Paper pulp",
        "similarGroup": "0116",
        "similarItem": "0116",
        "similarName": "纸浆",
        "type": 0,
        "updateTime": "2021-05-25 10:54:46"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "工业用沸石",
    "goodsEnName": "Zeolite for industrial use",
    "goodsJpName": "工業用ゼオライト",
    "goodsNo": "",
    "id": 56207,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": "Chemical products for industrial and scientific purposes, chemical preparations, chemical products not used by goods from other classes",
    "similarGroup": "0104",
    "similarItem": "",
    "similarName": "用于工业、科学的化学品、化学制剂,不属于其他类别的产品用的化学制品",
    "type": 0,
    "updateTime": "2021-05-25 10:24:45"
  },
  {
    "category": "10",
    "categoryEnName": "Medical and nursing apparatus",
    "categoryName": "医疗护理器械",
    "children": [
      {
        "category": "10",
        "categoryEnName": "Medical and nursing apparatus",
        "categoryName": "医疗护理器械",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "医用支架",
        "goodsEnName": "Stents",
        "goodsJpName": "ステント",
        "goodsNo": "100223",
        "id": 57299,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Instruments, mechanics and equipments for surgery, medical treatment and veterinarian (not including electronic, nuclear, electrotherapy, medical s-ray equipments, mechanics and apparatuses)",
        "similarGroup": "1001",
        "similarItem": "1001",
        "similarName": "外科、医疗和兽医用仪器、器械、设备,不包括电子、核子、电疗、医疗用X 光设备、器械及仪器",
        "type": 0,
        "updateTime": "2021-05-25 11:28:24"
      },
      {
        "category": "10",
        "categoryEnName": "Medical and nursing apparatus",
        "categoryName": "医疗护理器械",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "假牙套",
        "goodsEnName": "Pins for artificial teeth",
        "goodsJpName": "人工歯根",
        "goodsNo": "100074",
        "id": 58027,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Dentistry equipments and instruments",
        "similarGroup": "1002",
        "similarItem": "1002",
        "similarName": "牙科设备及器具",
        "type": 0,
        "updateTime": "2021-05-25 10:35:39"
      },
      {
        "category": "10",
        "categoryEnName": "Medical and nursing apparatus",
        "categoryName": "医疗护理器械",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "电子针灸仪",
        "goodsEnName": "Electric acupuncture instruments",
        "goodsJpName": "電気式はり治療器",
        "goodsNo": "100202",
        "id": 60932,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Electronic, nuclear, electrotherapy, x-ray equipments for medical purposes",
        "similarGroup": "1003",
        "similarItem": "1003",
        "similarName": "医疗用电子、核子、电疗和X光设备",
        "type": 0,
        "updateTime": "2021-05-25 11:26:34"
      },
      {
        "category": "10",
        "categoryEnName": "Medical and nursing apparatus",
        "categoryName": "医疗护理器械",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "病人用安全约束装置",
        "goodsEnName": "Patient safety restraint devices",
        "goodsJpName": "病人用安全装置",
        "goodsNo": "",
        "id": 55395,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Auxiliary instruments, equipments and articles",
        "similarGroup": "1004",
        "similarItem": "",
        "similarName": "医疗用辅助器具、设备和用品",
        "type": 0,
        "updateTime": "2021-05-25 10:10:31"
      },
      {
        "category": "10",
        "categoryEnName": "Medical and nursing apparatus",
        "categoryName": "医疗护理器械",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "婴儿用安抚奶嘴",
        "goodsEnName": "Babies’ pacifiers [teats]",
        "goodsJpName": "哺乳瓶用乳首",
        "goodsNo": "100145",
        "id": 93881,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Nipples, feeders",
        "similarGroup": "1005",
        "similarItem": "1005",
        "similarName": "奶嘴,奶瓶",
        "type": 0,
        "updateTime": "2021-05-25 11:31:32"
      },
      {
        "category": "10",
        "categoryEnName": "Medical and nursing apparatus",
        "categoryName": "医疗护理器械",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "避孕用具",
        "goodsEnName": "Contraceptive ",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 71859,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Sexual articles",
        "similarGroup": "1006",
        "similarItem": "1006",
        "similarName": "性用品",
        "type": 0,
        "updateTime": "2021-05-25 10:18:47"
      },
      {
        "category": "10",
        "categoryEnName": "Medical and nursing apparatus",
        "categoryName": "医疗护理器械",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "假肢",
        "goodsEnName": "Artificial limbs",
        "goodsJpName": "義肢",
        "goodsNo": "100117",
        "id": 58069,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Artificial limbs, wigs and artificial organs",
        "similarGroup": "1007",
        "similarItem": "1007",
        "similarName": "假肢,假发和假器官",
        "type": 0,
        "updateTime": "2021-05-25 10:30:37"
      },
      {
        "category": "10",
        "categoryEnName": "Medical and nursing apparatus",
        "categoryName": "医疗护理器械",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "医用穿戴式步行辅助机器人",
        "goodsEnName": "Wearable walking assistant robot, for medical purposes",
        "goodsJpName": "医療用ウォーキング補助ロボット",
        "goodsNo": "",
        "id": 55731,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Orthopedic instruments",
        "similarGroup": "1008",
        "similarItem": "",
        "similarName": "矫形矫正用品",
        "type": 0,
        "updateTime": "2021-05-25 11:05:41"
      },
      {
        "category": "10",
        "categoryEnName": "Medical and nursing apparatus",
        "categoryName": "医疗护理器械",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "缝合用线",
        "goodsEnName": "Suture thread",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 57180,
        "niceCodeLists": "",
        "remark": "可接受，非标准",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Suture materials",
        "similarGroup": "1009",
        "similarItem": "1009",
        "similarName": "缝合用材料",
        "type": 0,
        "updateTime": "2021-05-25 11:46:33"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "病人用安全约束装置",
    "goodsEnName": "Patient safety restraint devices",
    "goodsJpName": "病人用安全装置",
    "goodsNo": "",
    "id": 55395,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": "Auxiliary instruments, equipments and articles",
    "similarGroup": "1004",
    "similarItem": "",
    "similarName": "医疗用辅助器具、设备和用品",
    "type": 0,
    "updateTime": "2021-05-25 10:10:31"
  },
  {
    "category": "11",
    "categoryEnName": "Lights, AC, heating, santiaries",
    "categoryName": "照明烹饪卫生",
    "children": [
      {
        "category": "11",
        "categoryEnName": "Lights, AC, heating, santiaries",
        "categoryName": "照明烹饪卫生",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "电灯丝",
        "goodsEnName": "Filaments for electric lamps",
        "goodsJpName": "電球用フィラメント",
        "goodsNo": "110145",
        "id": 59581,
        "niceCodeLists": "",
        "remark": "2018版【修改注3】【新增注5】",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Equipments and apparatuses for lighting (not including gas lamps and oil lamps)",
        "similarGroup": "1101",
        "similarItem": "1101（一）",
        "similarName": "照明用设备、器具（不包括汽灯、油灯）",
        "type": 0,
        "updateTime": "2021-05-25 11:31:38"
      },
      {
        "category": "11",
        "categoryEnName": "Lights, AC, heating, santiaries",
        "categoryName": "照明烹饪卫生",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "煤气灯",
        "goodsEnName": "Gas lamps",
        "goodsJpName": "ガス灯",
        "goodsNo": "110163",
        "id": 99789,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Gas lamps, oil lamps ",
        "similarGroup": "1103",
        "similarItem": "1103",
        "similarName": "汽灯,油灯",
        "type": 0,
        "updateTime": "2021-05-25 11:29:06"
      },
      {
        "category": "11",
        "categoryEnName": "Lights, AC, heating, santiaries",
        "categoryName": "照明烹饪卫生",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "电热砂锅",
        "goodsEnName": "Electric heating casserole",
        "goodsJpName": "電気フライパン",
        "goodsNo": "",
        "id": 55325,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Electrical heating equipments for civil use and cooking (not including small tools for kitchen and food processing mechanics)",
        "similarGroup": "1104",
        "similarItem": "",
        "similarName": "烹调及民用电气加热设备（不包括厨房用手工用具,食品加工机器）",
        "type": 0,
        "updateTime": "2021-05-25 10:10:30"
      },
      {
        "category": "11",
        "categoryEnName": "Lights, AC, heating, santiaries",
        "categoryName": "照明烹饪卫生",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "冰箱除味器",
        "goodsEnName": "Deordoring device for refrigerator",
        "goodsJpName": "冷蔵庫用脱臭剤",
        "goodsNo": "C110014",
        "id": 56753,
        "niceCodeLists": "",
        "remark": "2018版【新增注】",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Refrigerating equipments (not including refrigerator cars)",
        "similarGroup": "1105",
        "similarItem": "1105",
        "similarName": "制冷、冷藏设备（不包括冷藏车）",
        "type": 0,
        "updateTime": "2021-05-25 10:41:24"
      },
      {
        "category": "11",
        "categoryEnName": "Lights, AC, heating, santiaries",
        "categoryName": "照明烹饪卫生",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "工业用窗式空调",
        "goodsEnName": "Industrial window air conditioning",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 57425,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Drying equipments, draught apparatuses, air conditioning (including cooling and heating equipments)",
        "similarGroup": "1106",
        "similarItem": "1106",
        "similarName": "干燥、通风、空调设备（包括冷暖房设备）,气体净化设备",
        "type": 0,
        "updateTime": "2021-05-25 11:49:07"
      },
      {
        "category": "11",
        "categoryEnName": "Lights, AC, heating, santiaries",
        "categoryName": "照明烹饪卫生",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "工业用炉",
        "goodsEnName": "Furnaces for industrial purposes",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 57117,
        "niceCodeLists": "",
        "remark": "可接受，非标准",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Warming-up devices, steam devices (including furnaces for industrial purposes, boilers, not including locomotive boilers, portable steam engines, steamer boilers)",
        "similarGroup": "1107",
        "similarItem": "1107",
        "similarName": "加温、蒸汽设备（包括工业用炉、锅炉,不包括机车锅炉、锅驼机锅炉、蒸汽机锅炉）",
        "type": 0,
        "updateTime": "2021-05-25 10:44:16"
      },
      {
        "category": "11",
        "categoryEnName": "Lights, AC, heating, santiaries",
        "categoryName": "照明烹饪卫生",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "水分配设备",
        "goodsEnName": "Water distribution installations",
        "goodsJpName": "上水道配管用機械器具及び設備",
        "goodsNo": "110096",
        "id": 60785,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Plumbing pipes",
        "similarGroup": "1108",
        "similarItem": "1108（一）",
        "similarName": "水暖管件",
        "type": 0,
        "updateTime": "2021-05-25 10:33:56"
      },
      {
        "category": "11",
        "categoryEnName": "Lights, AC, heating, santiaries",
        "categoryName": "照明烹饪卫生",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "可移动盥洗室",
        "goodsEnName": "Toilets, portable",
        "goodsJpName": "移動式便所",
        "goodsNo": "110064",
        "id": 56424,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Sanitaries (not including toilet sets)",
        "similarGroup": "1109",
        "similarItem": "1109",
        "similarName": "卫生设备（不包括盥洗室用具）",
        "type": 0,
        "updateTime": "2021-05-25 11:17:15"
      },
      {
        "category": "11",
        "categoryEnName": "Lights, AC, heating, santiaries",
        "categoryName": "照明烹饪卫生",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "油净化器",
        "goodsEnName": "Oil-scrubbing apparatus",
        "goodsJpName": "油精製用装置",
        "goodsNo": "110277",
        "id": 56417,
        "niceCodeLists": "",
        "remark": "删除注1",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Disinfecting and purifying equipments",
        "similarGroup": "1110",
        "similarItem": "1110（一）",
        "similarName": "消毒和净化设备",
        "type": 0,
        "updateTime": "2021-05-25 11:12:17"
      },
      {
        "category": "11",
        "categoryEnName": "Lights, AC, heating, santiaries",
        "categoryName": "照明烹饪卫生",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "怀炉",
        "goodsEnName": "Bosom stove",
        "goodsJpName": "懐炉",
        "goodsNo": "C110033",
        "id": 56816,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Small-sized warmers",
        "similarGroup": "1111",
        "similarItem": "1111",
        "similarName": "小型取暖器",
        "type": 0,
        "updateTime": "2021-05-25 11:09:14"
      },
      {
        "category": "11",
        "categoryEnName": "Lights, AC, heating, santiaries",
        "categoryName": "照明烹饪卫生",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "气体引燃器",
        "goodsEnName": "Gas lighters",
        "goodsJpName": "ガス点火器",
        "goodsNo": "110019",
        "id": 99348,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Lighters, not included in other classes",
        "similarGroup": "1112",
        "similarItem": "1112",
        "similarName": "不属别类的打火器具",
        "type": 1,
        "updateTime": "2021-05-25 11:09:15"
      },
      {
        "category": "11",
        "categoryEnName": "Lights, AC, heating, santiaries",
        "categoryName": "照明烹饪卫生",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "18",
        "flagBit": 0,
        "goodsChName": "原子堆",
        "goodsEnName": "Atomic pile",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 62801,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Nuclear power reaction equipments",
        "similarGroup": "1113",
        "similarItem": "1113",
        "similarName": "核能反应设备",
        "type": 1,
        "updateTime": "2021-05-25 11:09:16"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "电热砂锅",
    "goodsEnName": "Electric heating casserole",
    "goodsJpName": "電気フライパン",
    "goodsNo": "",
    "id": 55325,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": " Electrical heating equipments for civil use and cooking (not including small tools for kitchen and food processing mechanics)",
    "similarGroup": "1104",
    "similarItem": "",
    "similarName": "烹调及民用电气加热设备（不包括厨房用手工用具,食品加工机器）",
    "type": 0,
    "updateTime": "2021-05-25 10:10:30"
  },
  {
    "category": "12",
    "categoryEnName": "Vehicles",
    "categoryName": "运载工具部件",
    "children": [
      {
        "category": "12",
        "categoryEnName": "Vehicles",
        "categoryName": "运载工具部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "铁路车辆缓冲器",
        "goodsEnName": "Buffers for railway rolling stock",
        "goodsJpName": "鉄道車両用緩衝器",
        "goodsNo": "120078",
        "id": 62213,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Trains and their components and parts",
        "similarGroup": "1201",
        "similarItem": "1201",
        "similarName": "火车及其零部件",
        "type": 0,
        "updateTime": "2021-05-25 11:09:18"
      },
      {
        "category": "12",
        "categoryEnName": "Vehicles",
        "categoryName": "运载工具部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "摩托车引擎",
        "goodsEnName": "Motorcycle engines",
        "goodsJpName": "オートバイ用エンジン",
        "goodsNo": "120286",
        "id": 56564,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Automobiles, trolleys and their components and parts (not including tyres)",
        "similarGroup": "1202",
        "similarItem": "1202",
        "similarName": "汽车、电车、摩托车及其零部件（不包括轮胎）",
        "type": 0,
        "updateTime": "2021-05-25 10:26:19"
      },
      {
        "category": "12",
        "categoryEnName": "Vehicles",
        "categoryName": "运载工具部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "自行车用气泵",
        "goodsEnName": "Air pumps for  bicycles",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 58748,
        "niceCodeLists": "",
        "remark": "可接受，非标准",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Bicycles, tricycles and their components and parts (not including tyres)",
        "similarGroup": "1204",
        "similarItem": "1204",
        "similarName": "自行车、三轮车及其零部件（不包括轮胎）",
        "type": 0,
        "updateTime": "2021-05-25 10:12:04"
      },
      {
        "category": "12",
        "categoryEnName": "Vehicles",
        "categoryName": "运载工具部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "3,9",
        "flagBit": 0,
        "goodsChName": "电动运载工具",
        "goodsEnName": "Electric vehicles",
        "goodsJpName": "電動式乗物",
        "goodsNo": "120110",
        "id": 74715,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Cable cars, overhead transportation equipments ",
        "similarGroup": "1205",
        "similarItem": "1201",
        "similarName": "缆车,架空运输设备",
        "type": 0,
        "updateTime": "2021-05-25 10:27:29"
      },
      {
        "category": "12",
        "categoryEnName": "Vehicles",
        "categoryName": "运载工具部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "人力车",
        "goodsEnName": "Rickshaw",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 72685,
        "niceCodeLists": "",
        "remark": "可接受，非标准",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Rolling chairs, handcarts, children vehicles",
        "similarGroup": "1206",
        "similarItem": "1206",
        "similarName": "轮椅,手推车,儿童推车",
        "type": 0,
        "updateTime": "2021-05-25 10:43:02"
      },
      {
        "category": "12",
        "categoryEnName": "Vehicles",
        "categoryName": "运载工具部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "反冲式雪橇",
        "goodsEnName": "Kick sledges",
        "goodsJpName": "人力そり",
        "goodsNo": "120253",
        "id": 71677,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Animal-drawn cart",
        "similarGroup": "1207",
        "similarItem": "1207",
        "similarName": "畜力车辆",
        "type": 0,
        "updateTime": "2021-05-25 11:23:07"
      },
      {
        "category": "12",
        "categoryEnName": "Vehicles",
        "categoryName": "运载工具部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "翻新轮胎用胎面",
        "goodsEnName": "Treads for retreading tires [tyres]",
        "goodsJpName": "トレッド再生タイヤ",
        "goodsNo": "120156",
        "id": 64915,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Tyres and tyre repairing instruments",
        "similarGroup": "1208",
        "similarItem": "1208（一）",
        "similarName": "轮胎及轮胎修理工具",
        "type": 0,
        "updateTime": "2021-05-25 11:34:01"
      },
      {
        "category": "12",
        "categoryEnName": "Vehicles",
        "categoryName": "运载工具部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "3,9",
        "flagBit": 0,
        "goodsChName": "空中运载工具",
        "goodsEnName": "Air vehicles",
        "goodsJpName": "航空機",
        "goodsNo": "120005",
        "id": 61835,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Toters for aerial purposes ",
        "similarGroup": "1209",
        "similarItem": "1209",
        "similarName": "空用运载工具（不包括飞机轮胎）",
        "type": 1,
        "updateTime": "2021-05-25 11:29:43"
      },
      {
        "category": "12",
        "categoryEnName": "Vehicles",
        "categoryName": "运载工具部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "船只分离装置",
        "goodsEnName": "Disengaging gear for boats",
        "goodsJpName": "ボート揚卸し用装置",
        "goodsNo": "120038",
        "id": 59903,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Toters for land purposes",
        "similarGroup": "1210",
        "similarItem": "1210",
        "similarName": "水用运载工具",
        "type": 0,
        "updateTime": "2021-05-25 11:30:12"
      },
      {
        "category": "12",
        "categoryEnName": "Vehicles",
        "categoryName": "运载工具部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "运载工具遮光装置",
        "goodsEnName": "Anti-glare devices for vehicles",
        "goodsJpName": "乗物用防眩装置",
        "goodsNo": "120204",
        "id": 57355,
        "niceCodeLists": "",
        "remark": "2018版【修改注3】",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Parts and fittings for vehicles",
        "similarGroup": "1211",
        "similarItem": "1211",
        "similarName": "运载工具零部件",
        "type": 0,
        "updateTime": "2021-05-25 11:34:40"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "摩托车引擎",
    "goodsEnName": "Motorcycle engines",
    "goodsJpName": "オートバイ用エンジン",
    "goodsNo": "120286",
    "id": 56564,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": " Automobiles, trolleys and their components and parts (not including tyres)",
    "similarGroup": "1202",
    "similarItem": "1202",
    "similarName": "汽车、电车、摩托车及其零部件（不包括轮胎）",
    "type": 0,
    "updateTime": "2021-05-25 10:26:19"
  },
  {
    "category": "13",
    "categoryEnName": "Firearms, fireworks",
    "categoryName": "火器军火烟火",
    "children": [
      {
        "category": "13",
        "categoryEnName": "Firearms, fireworks",
        "categoryName": "火器军火烟火",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "子弹带装弹机",
        "goodsEnName": "Cartridge belt loader",
        "goodsJpName": "弾丸装填機",
        "goodsNo": "",
        "id": 55829,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Firearms, ammunitions and bullets",
        "similarGroup": "1301",
        "similarItem": "",
        "similarName": "火器,军火及子弹",
        "type": 0,
        "updateTime": "2021-05-25 10:55:35"
      },
      {
        "category": "13",
        "categoryEnName": "Firearms, fireworks",
        "categoryName": "火器军火烟火",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "火药棉",
        "goodsEnName": "Gun cotton",
        "goodsJpName": "綿火薬",
        "goodsNo": "130030",
        "id": 59630,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Explosives",
        "similarGroup": "1302",
        "similarItem": "1302",
        "similarName": "爆炸物",
        "type": 0,
        "updateTime": "2021-05-25 10:40:49"
      },
      {
        "category": "13",
        "categoryEnName": "Firearms, fireworks",
        "categoryName": "火器军火烟火",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "爆炸性烟雾信号",
        "goodsEnName": "Fog signals, explosive",
        "goodsJpName": "爆発性霧中信号",
        "goodsNo": "130064",
        "id": 62822,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Fireworks, firecrackers",
        "similarGroup": "1303",
        "similarItem": "1303",
        "similarName": "烟火,爆竹",
        "type": 0,
        "updateTime": "2021-05-25 10:40:51"
      },
      {
        "category": "13",
        "categoryEnName": "Firearms, fireworks",
        "categoryName": "火器军火烟火",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "个人防护用喷雾",
        "goodsEnName": "Sprays for personal defence purposes",
        "goodsJpName": "護身用スプレー",
        "goodsNo": "130073",
        "id": 59763,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Sprays for personal defense purposes ",
        "similarGroup": "1304",
        "similarItem": "1304",
        "similarName": "个人防护用喷雾",
        "type": 1,
        "updateTime": "2021-05-25 11:26:25"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "子弹带装弹机",
    "goodsEnName": "Cartridge belt loader",
    "goodsJpName": "弾丸装填機",
    "goodsNo": "",
    "id": 55829,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": "Firearms, ammunitions and bullets",
    "similarGroup": "1301",
    "similarItem": "",
    "similarName": "火器,军火及子弹",
    "type": 0,
    "updateTime": "2021-05-25 10:55:35"
  },
  {
    "category": "14",
    "categoryEnName": "Jewelry, precious stones, watches",
    "categoryName": "珠宝首饰钟表",
    "children": [
      {
        "category": "14",
        "categoryEnName": "Jewelry, precious stones, watches",
        "categoryName": "珠宝首饰钟表",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "贵金属锭",
        "goodsEnName": "Ingots of precious metals",
        "goodsJpName": "貴金属製インゴット",
        "goodsNo": "140003",
        "id": 57012,
        "niceCodeLists": "",
        "remark": "2019版【修改】“贵重金属锭”改为“贵金属锭”",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Precious metals and their alloys ",
        "similarGroup": "1401",
        "similarItem": "1401",
        "similarName": "贵重金属及其合金",
        "type": 0,
        "updateTime": "2021-05-25 10:44:50"
      },
      {
        "category": "14",
        "categoryEnName": "Jewelry, precious stones, watches",
        "categoryName": "珠宝首饰钟表",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "2",
        "flagBit": 0,
        "goodsChName": "首饰包",
        "goodsEnName": "Jewellery rolls / Jewelry rolls",
        "goodsJpName": "ロール式宝石入れ",
        "goodsNo": "140170",
        "id": 89429,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Precious metal work or articles coating with precious metal (not including jewelry, ornaments, souvenirs)",
        "similarGroup": "1402",
        "similarItem": "1402",
        "similarName": "贵重金属盒",
        "type": 0,
        "updateTime": "2021-05-25 11:15:16"
      },
      {
        "category": "14",
        "categoryEnName": "Jewelry, precious stones, watches",
        "categoryName": "珠宝首饰钟表",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "制首饰用莱茵石",
        "goodsEnName": "Rhinestone for making jewelry",
        "goodsJpName": "アクセサリー用ラインストーン",
        "goodsNo": "",
        "id": 55619,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Gems, jewelry, gemstones and precious metal souvenirs)",
        "similarGroup": "1403",
        "similarItem": "",
        "similarName": "珠宝,首饰,宝石及贵重金属制纪念品",
        "type": 0,
        "updateTime": "2021-05-25 11:07:46"
      },
      {
        "category": "14",
        "categoryEnName": "Jewelry, precious stones, watches",
        "categoryName": "珠宝首饰钟表",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "发条匣（钟表制造）",
        "goodsEnName": "Barrels [clock and watch making]",
        "goodsJpName": "時計用ぜんまい箱",
        "goodsNo": "140014",
        "id": 57775,
        "niceCodeLists": "",
        "remark": "增加注",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Clocks, watches, time equipments and their components and parts ",
        "similarGroup": "1404",
        "similarItem": "1404",
        "similarName": "钟,表,计时器及其零部件",
        "type": 0,
        "updateTime": "2021-05-25 11:34:08"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "制首饰用莱茵石",
    "goodsEnName": "Rhinestone for making jewelry",
    "goodsJpName": "アクセサリー用ラインストーン",
    "goodsNo": "",
    "id": 55619,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": "Gems, jewelry, gemstones and precious metal souvenirs)",
    "similarGroup": "1403",
    "similarItem": "",
    "similarName": "珠宝,首饰,宝石及贵重金属制纪念品",
    "type": 0,
    "updateTime": "2021-05-25 11:07:46"
  },
  {
    "category": "15",
    "categoryEnName": "Musical instruments",
    "categoryName": "乐器及其配件",
    "children": [
      {
        "category": "15",
        "categoryEnName": "Musical instruments",
        "categoryName": "乐器及其配件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "三角铁（乐器）",
        "goodsEnName": "Triangles [musical instruments]",
        "goodsJpName": "トライアングル（楽器）",
        "goodsNo": "150055",
        "id": 56599,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Musical instruments",
        "similarGroup": "1501",
        "similarItem": "1501",
        "similarName": "乐器",
        "type": 0,
        "updateTime": "2021-05-25 11:34:39"
      },
      {
        "category": "15",
        "categoryEnName": "Musical instruments",
        "categoryName": "乐器及其配件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "鼓踏板",
        "goodsEnName": "Drum pedal",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 58370,
        "niceCodeLists": "",
        "remark": "可接受、非标准 2017-4",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Musical instruments auxiliary sets and accessories ",
        "similarGroup": "1502",
        "similarItem": "1502",
        "similarName": "乐器辅助用品及配件",
        "type": 0,
        "updateTime": "2021-05-25 10:47:09"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "三角铁（乐器）",
    "goodsEnName": "Triangles [musical instruments]",
    "goodsJpName": "トライアングル（楽器）",
    "goodsNo": "150055",
    "id": 56599,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": "Musical instruments",
    "similarGroup": "1501",
    "similarItem": "1501",
    "similarName": "乐器",
    "type": 0,
    "updateTime": "2021-05-25 11:34:39"
  },
  {
    "category": "16",
    "categoryEnName": "Office supplies, teaching materials",
    "categoryName": "办公教学用品",
    "children": [
      {
        "category": "16",
        "categoryEnName": "Office supplies, teaching materials",
        "categoryName": "办公教学用品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "塑料贴面底层纸",
        "goodsEnName": "Plastic-coated bottom paper",
        "goodsJpName": "プラスチックベニヤ用底面紙",
        "goodsNo": "C160002",
        "id": 64999,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Papers for industrial purpose",
        "similarGroup": "1601",
        "similarItem": "1601",
        "similarName": "工业用纸",
        "type": 0,
        "updateTime": "2021-05-25 10:51:16"
      },
      {
        "category": "16",
        "categoryEnName": "Office supplies, teaching materials",
        "categoryName": "办公教学用品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "相框内衬卡纸",
        "goodsEnName": "Frame lined carboard",
        "goodsJpName": "フォトフレームの内張り紙",
        "goodsNo": "",
        "id": 55738,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Papers for technology ",
        "similarGroup": "1602",
        "similarItem": "",
        "similarName": "技术用纸（不包括绝缘纸）",
        "type": 0,
        "updateTime": "2021-05-25 11:05:41"
      },
      {
        "category": "16",
        "categoryEnName": "Office supplies, teaching materials",
        "categoryName": "办公教学用品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "马桶坐垫纸",
        "goodsEnName": "Toilet seat paper",
        "goodsJpName": "便座紙",
        "goodsNo": "",
        "id": 55283,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Papers for daily use",
        "similarGroup": "1603",
        "similarItem": "",
        "similarName": "生活用纸",
        "type": 0,
        "updateTime": "2021-05-25 10:14:13"
      },
      {
        "category": "16",
        "categoryEnName": "Office supplies, teaching materials",
        "categoryName": "办公教学用品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "缝纫用型板（印刷品）",
        "goodsEnName": "Pattern board for sewing (printed matter)",
        "goodsJpName": "裁縫用プレート（印刷品）",
        "goodsNo": "",
        "id": 55654,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Paperboards",
        "similarGroup": "1604",
        "similarItem": "",
        "similarName": "纸板",
        "type": 0,
        "updateTime": "2021-05-25 11:04:21"
      },
      {
        "category": "16",
        "categoryEnName": "Office supplies, teaching materials",
        "categoryName": "办公教学用品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "集换式体育卡片专用携带套",
        "goodsEnName": "Covers for trading sports cards",
        "goodsJpName": "集換式スポーツカード専用携帯ケース",
        "goodsNo": "",
        "id": 55661,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Paper articles for office and daily use",
        "similarGroup": "1605",
        "similarItem": "",
        "similarName": "办公、日用纸制品",
        "type": 0,
        "updateTime": "2021-05-25 11:04:21"
      },
      {
        "category": "16",
        "categoryEnName": "Office supplies, teaching materials",
        "categoryName": "办公教学用品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "7,11,12",
        "flagBit": 0,
        "goodsChName": "期刊",
        "goodsEnName": "Periodicals",
        "goodsJpName": "定期刊行物",
        "goodsNo": "160033",
        "id": 57838,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Printed publications",
        "similarGroup": "1606",
        "similarItem": "1606（二）",
        "similarName": "印刷出版物",
        "type": 1,
        "updateTime": "2021-05-25 11:24:29"
      },
      {
        "category": "16",
        "categoryEnName": "Office supplies, teaching materials",
        "categoryName": "办公教学用品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "11,17",
        "flagBit": 0,
        "goodsChName": "美术印刷品",
        "goodsEnName": "Art print",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 57306,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Photos, pictures, drawings",
        "similarGroup": "1607",
        "similarItem": "1607",
        "similarName": "照片,图片,图画",
        "type": 0,
        "updateTime": "2021-05-25 11:39:25"
      },
      {
        "category": "16",
        "categoryEnName": "Office supplies, teaching materials",
        "categoryName": "办公教学用品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "包装用塑料气泡膜",
        "goodsEnName": "Plastic bubble packs for\nwrapping or packaging",
        "goodsJpName": "プラスチック製気泡状包装材料",
        "goodsNo": "160285",
        "id": 58545,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Papers and plastic package articles not belonging to other classes",
        "similarGroup": "1609",
        "similarItem": "1609",
        "similarName": "纸及不属别类的塑料包装物品",
        "type": 0,
        "updateTime": "2021-05-25 11:02:54"
      },
      {
        "category": "16",
        "categoryEnName": "Office supplies, teaching materials",
        "categoryName": "办公教学用品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "家用或办公用光盘粉碎机",
        "goodsEnName": "Disc grinder for  for household or office use",
        "goodsJpName": "家庭用又は事務用の光ディスク粉砕機",
        "goodsNo": "",
        "id": 55311,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Bookbinding and cutting machines for office",
        "similarGroup": "1610",
        "similarItem": "",
        "similarName": "办公装订、切削用具",
        "type": 0,
        "updateTime": "2021-05-25 10:07:44"
      },
      {
        "category": "16",
        "categoryEnName": "Office supplies, teaching materials",
        "categoryName": "办公教学用品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "铅笔握笔器",
        "goodsEnName": "Pencil holder",
        "goodsJpName": "鉛筆のグリップ",
        "goodsNo": "",
        "id": 55486,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Office stationery (not including pens, inks, seals and glues)",
        "similarGroup": "1611",
        "similarItem": "",
        "similarName": "办公文具（不包括笔,墨,印,胶水）",
        "type": 0,
        "updateTime": "2021-05-25 10:55:51"
      },
      {
        "category": "16",
        "categoryEnName": "Office supplies, teaching materials",
        "categoryName": "办公教学用品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "吸墨用具",
        "goodsEnName": "Blotters",
        "goodsJpName": "吸取紙",
        "goodsNo": "160051",
        "id": 57292,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Ink, inkstones",
        "similarGroup": "1612",
        "similarItem": "1612",
        "similarName": "墨,砚",
        "type": 0,
        "updateTime": "2021-05-25 11:17:04"
      },
      {
        "category": "16",
        "categoryEnName": "Office supplies, teaching materials",
        "categoryName": "办公教学用品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "日期滚轮印章",
        "goodsEnName": "Date roller seal",
        "goodsJpName": "日付ローラー印",
        "goodsNo": "",
        "id": 55416,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Seals, stamp-pad ink",
        "similarGroup": "1613",
        "similarItem": "",
        "similarName": "印章,印油",
        "type": 0,
        "updateTime": "2021-05-25 10:55:49"
      },
      {
        "category": "16",
        "categoryEnName": "Office supplies, teaching materials",
        "categoryName": "办公教学用品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "金制笔尖",
        "goodsEnName": "Nibs of gold",
        "goodsJpName": "金製ペン先",
        "goodsNo": "160134",
        "id": 57642,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Pens",
        "similarGroup": "1614",
        "similarItem": "1614",
        "similarName": "笔",
        "type": 0,
        "updateTime": "2021-05-25 11:08:24"
      },
      {
        "category": "16",
        "categoryEnName": "Office supplies, teaching materials",
        "categoryName": "办公教学用品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "文具或家用胶条",
        "goodsEnName": "Adhesive tapes for stationery or household purposes ",
        "goodsJpName": "文房具としての又は家庭用の接着テープ",
        "goodsNo": "160266",
        "id": 59910,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Adhesive tapes or binders for office or family",
        "similarGroup": "1615",
        "similarItem": "1615",
        "similarName": "办公或家庭用胶带或粘合剂",
        "type": 0,
        "updateTime": "2021-05-25 11:25:32"
      },
      {
        "category": "16",
        "categoryEnName": "Office supplies, teaching materials",
        "categoryName": "办公教学用品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "绘图用丁字尺",
        "goodsEnName": "T-squares for drawing",
        "goodsJpName": "製図・デッサン用Ｔ定規",
        "goodsNo": "160298",
        "id": 64544,
        "niceCodeLists": "",
        "remark": "修改注2",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Plotting instruments and painting instruments for office",
        "similarGroup": "1616",
        "similarItem": "1616",
        "similarName": "办公室用绘图仪器,绘画仪器",
        "type": 0,
        "updateTime": "2021-05-25 10:47:17"
      },
      {
        "category": "16",
        "categoryEnName": "Office supplies, teaching materials",
        "categoryName": "办公教学用品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "画家用靠手架",
        "goodsEnName": "Hand-rests for painters",
        "goodsJpName": "画家用腕掛け台",
        "goodsNo": "160019",
        "id": 61954,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Painting instruments (not including plotting instruments, pens)",
        "similarGroup": "1617",
        "similarItem": "1617",
        "similarName": "绘画用具（不包括绘图仪器,笔）",
        "type": 0,
        "updateTime": "2021-05-25 11:18:21"
      },
      {
        "category": "16",
        "categoryEnName": "Office supplies, teaching materials",
        "categoryName": "办公教学用品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "绘图模板",
        "goodsEnName": "Drawing template",
        "goodsJpName": "図形描画テンプレート",
        "goodsNo": "",
        "id": 55745,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Typewriters, transcribers, mimeographs and their accessories (including printed types, machine plates)",
        "similarGroup": "1618",
        "similarItem": "",
        "similarName": "打字机、誊写机、油印机及其附件（包括印刷铅字、印版）",
        "type": 0,
        "updateTime": "2021-05-25 11:05:42"
      },
      {
        "category": "16",
        "categoryEnName": "Office supplies, teaching materials",
        "categoryName": "办公教学用品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "数学教具",
        "goodsEnName": "Maths teaching aid",
        "goodsJpName": "数学教育用器具",
        "goodsNo": "C160106",
        "id": 60120,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Teaching aids (not including apparatuses for teaching experiments)",
        "similarGroup": "1619",
        "similarItem": "1619（一）",
        "similarName": "教学用具（不包括教学实验用仪器）",
        "type": 0,
        "updateTime": "2021-05-25 11:18:28"
      },
      {
        "category": "16",
        "categoryEnName": "Office supplies, teaching materials",
        "categoryName": "办公教学用品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "非牙科用模型蜡",
        "goodsEnName": "Modelling wax, not for dental purposes",
        "goodsJpName": "模型又は塑像製作用のろう（歯科用のものを除く。）",
        "goodsNo": "160091",
        "id": 71915,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Indoor models (not including archetype for teaching)",
        "similarGroup": "1620",
        "similarItem": "1620",
        "similarName": "室内模型物（不包括教学用模型标本）",
        "type": 0,
        "updateTime": "2021-05-25 11:25:27"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "马桶坐垫纸",
    "goodsEnName": "Toilet seat paper",
    "goodsJpName": "便座紙",
    "goodsNo": "",
    "id": 55283,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": "Papers for daily use",
    "similarGroup": "1603",
    "similarItem": "",
    "similarName": "生活用纸",
    "type": 0,
    "updateTime": "2021-05-25 10:14:13"
  },
  {
    "category": "17",
    "categoryEnName": "Plastic and rubber products",
    "categoryName": "塑料绝缘材料",
    "children": [
      {
        "category": "17",
        "categoryEnName": "Plastic and rubber products",
        "categoryName": "塑料绝缘材料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "巴拉塔树胶",
        "goodsEnName": "Balata gum",
        "goodsJpName": "バラタゴム",
        "goodsNo": "170010",
        "id": 60134,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Rubbers not belonging to other classes, gutta-percha, gum ",
        "similarGroup": "1701",
        "similarItem": "1701",
        "similarName": "不属别类的橡胶,古塔胶,树胶",
        "type": 0,
        "updateTime": "2021-05-25 11:18:55"
      },
      {
        "category": "17",
        "categoryEnName": "Plastic and rubber products",
        "categoryName": "塑料绝缘材料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "填充垫圈",
        "goodsEnName": " Joint packings",
        "goodsJpName": "シール材",
        "goodsNo": "170043",
        "id": 70109,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Non-metallic sealed anti-vibration article",
        "similarGroup": "1702",
        "similarItem": "1702",
        "similarName": "非金属密封减震制品",
        "type": 0,
        "updateTime": "2021-05-25 11:25:59"
      },
      {
        "category": "17",
        "categoryEnName": "Plastic and rubber products",
        "categoryName": "塑料绝缘材料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "生产加工用丙烯酸板",
        "goodsEnName": "Acrylic plate for use in manufacturing processes ",
        "goodsJpName": "生産加工用アクリルプレート",
        "goodsNo": "",
        "id": 55556,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Rubber, resin, fiber semi-manufactures",
        "similarGroup": "1703",
        "similarItem": "",
        "similarName": "橡胶,树脂,纤维制品",
        "type": 0,
        "updateTime": "2021-05-25 11:07:43"
      },
      {
        "category": "17",
        "categoryEnName": "Plastic and rubber products",
        "categoryName": "塑料绝缘材料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "农业用塑料软管",
        "goodsEnName": "Plastic hose for agriculture",
        "goodsJpName": "農業用プラスチックホース",
        "goodsNo": "",
        "id": 55367,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Flexible pipe",
        "similarGroup": "1704",
        "similarItem": "",
        "similarName": "软管",
        "type": 0,
        "updateTime": "2021-05-25 11:47:06"
      },
      {
        "category": "17",
        "categoryEnName": "Plastic and rubber products",
        "categoryName": "塑料绝缘材料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "非纺织用石棉纱",
        "goodsEnName": "Asbestos yarn, not for textile use",
        "goodsJpName": "非紡績用石綿糸",
        "goodsNo": "",
        "id": 55255,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Thermal insulation materials, heat-barrier materials, acoustic materials",
        "similarGroup": "1705",
        "similarItem": "",
        "similarName": "保温、隔热、隔音材料",
        "type": 0,
        "updateTime": "2021-05-25 10:02:22"
      },
      {
        "category": "17",
        "categoryEnName": "Plastic and rubber products",
        "categoryName": "塑料绝缘材料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "电气绝缘用塑料薄膜",
        "goodsEnName": "Plastic film for electrical insulation",
        "goodsJpName": "電気絶縁用プラスチックシート",
        "goodsNo": "",
        "id": 55577,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Electrical insulation materials and their articles",
        "similarGroup": "1706",
        "similarItem": "",
        "similarName": "绝缘用材料及其制品",
        "type": 0,
        "updateTime": "2021-05-25 11:07:44"
      },
      {
        "category": "17",
        "categoryEnName": "Plastic and rubber products",
        "categoryName": "塑料绝缘材料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "11",
        "flagBit": 0,
        "goodsChName": "包装用模塑泡沫",
        "goodsEnName": "Molding foam for packaging",
        "goodsJpName": "包装用フォーム",
        "goodsNo": "",
        "id": 55570,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Packing materials, filling materials (including rubber articles and plastic articles)",
        "similarGroup": "1707",
        "similarItem": "",
        "similarName": "包装、填充用材料（包括橡胶、塑料制品）",
        "type": 0,
        "updateTime": "2021-05-25 11:07:43"
      },
      {
        "category": "17",
        "categoryEnName": "Plastic and rubber products",
        "categoryName": "塑料绝缘材料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "8,17",
        "flagBit": 0,
        "goodsChName": "封拉线（卷烟）",
        "goodsEnName": "Sealing pull stripe",
        "goodsJpName": "開封用線（タバコ用）",
        "goodsNo": "C170023",
        "id": 134355,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Single goods",
        "similarGroup": "1708",
        "similarItem": "1708",
        "similarName": "单一商品",
        "type": 1,
        "updateTime": "2021-05-25 11:17:30"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "非纺织用石棉纱",
    "goodsEnName": "Asbestos yarn, not for textile use",
    "goodsJpName": "非紡績用石綿糸",
    "goodsNo": "",
    "id": 55255,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": "Thermal insulation materials, heat-barrier materials, acoustic materials",
    "similarGroup": "1705",
    "similarItem": "",
    "similarName": "保温、隔热、隔音材料",
    "type": 0,
    "updateTime": "2021-05-25 10:02:22"
  },
  {
    "category": "18",
    "categoryEnName": "Leather products ",
    "categoryName": "箱包皮具皮革",
    "children": [
      {
        "category": "18",
        "categoryEnName": "Leather products ",
        "categoryName": "箱包皮具皮革",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "成批出售的皮革",
        "goodsEnName": "Leather for sale in batches",
        "goodsJpName": "大量に売られた皮革",
        "goodsNo": "",
        "id": 55934,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Leather and artificial leather; fur-skins",
        "similarGroup": "1801",
        "similarItem": "",
        "similarName": "皮革和人造皮革,裘皮",
        "type": 0,
        "updateTime": "2021-05-25 11:07:50"
      },
      {
        "category": "18",
        "categoryEnName": "Leather products ",
        "categoryName": "箱包皮具皮革",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "携带动物用包",
        "goodsEnName": "Bag for carrying animals",
        "goodsJpName": "動物用のカバンを携帯する",
        "goodsNo": "",
        "id": 55241,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Leather articles and artificial leather articles not belonging to other classes, cases and luggage, leather articles for daily use\r\n",
        "similarGroup": "1802",
        "similarItem": "",
        "similarName": "不属别类的皮革、人造皮革制品,箱子及旅行袋,日用革制品",
        "type": 0,
        "updateTime": "2021-05-25 10:49:02"
      },
      {
        "category": "18",
        "categoryEnName": "Leather products ",
        "categoryName": "箱包皮具皮革",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "沙滩阳伞",
        "goodsEnName": "Beach umbrella",
        "goodsJpName": "ビーチパラソル",
        "goodsNo": "",
        "id": 56228,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Umbrellas and their accessories",
        "similarGroup": "1804",
        "similarItem": "",
        "similarName": "雨伞及其部件",
        "type": 0,
        "updateTime": "2021-05-25 10:48:02"
      },
      {
        "category": "18",
        "categoryEnName": "Leather products ",
        "categoryName": "箱包皮具皮革",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "折叠手杖",
        "goodsEnName": "Folding cane",
        "goodsJpName": "つえをたたむ",
        "goodsNo": "",
        "id": 55276,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Walking stick",
        "similarGroup": "1805",
        "similarItem": "",
        "similarName": "手杖",
        "type": 0,
        "updateTime": "2021-05-25 10:47:14"
      },
      {
        "category": "18",
        "categoryEnName": "Leather products ",
        "categoryName": "箱包皮具皮革",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "马鞍垫毯",
        "goodsEnName": "Saddle blanket",
        "goodsJpName": "鞍の敷物",
        "goodsNo": "",
        "id": 55304,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Animal fur articles",
        "similarGroup": "1806",
        "similarItem": "",
        "similarName": "动物用具",
        "type": 0,
        "updateTime": "2021-05-25 10:43:28"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "携带动物用包",
    "goodsEnName": "Bag for carrying animals",
    "goodsJpName": "動物用のカバンを携帯する",
    "goodsNo": "",
    "id": 55241,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": " Leather articles and artificial leather articles not belonging to other classes, cases and luggage, leather articles for daily use\r\n",
    "similarGroup": "1802",
    "similarItem": "",
    "similarName": "不属别类的皮革、人造皮革制品,箱子及旅行袋,日用革制品",
    "type": 0,
    "updateTime": "2021-05-25 10:49:02"
  },
  {
    "category": "19",
    "categoryEnName": "Non-metallic building materials",
    "categoryName": "非金属的建材",
    "children": [
      {
        "category": "19",
        "categoryEnName": "Non-metallic building materials",
        "categoryName": "非金属的建材",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "铺地木材",
        "goodsEnName": "Wood paving",
        "goodsJpName": "木製舗装材",
        "goodsNo": "190032",
        "id": 56830,
        "niceCodeLists": "",
        "remark": "2018版【新增注】 ",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Wood semi-manufacturers",
        "similarGroup": "1901",
        "similarItem": "1901",
        "similarName": "半成品木材",
        "type": 0,
        "updateTime": "2021-05-25 10:38:13"
      },
      {
        "category": "19",
        "categoryEnName": "Non-metallic building materials",
        "categoryName": "非金属的建材",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "建筑灰浆",
        "goodsEnName": "Mortar for building",
        "goodsJpName": "建築用モルタル",
        "goodsNo": "190053",
        "id": 56410,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Construction materials such as earth, sand, stone, aggregated rock, gatch, bira nest",
        "similarGroup": "1902",
        "similarItem": "1902",
        "similarName": "土,沙,石,石料,灰泥,炉渣等建筑用料",
        "type": 0,
        "updateTime": "2021-05-25 10:42:33"
      },
      {
        "category": "19",
        "categoryEnName": "Non-metallic building materials",
        "categoryName": "非金属的建材",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "熟石膏",
        "goodsEnName": "Plaster",
        "goodsJpName": "プラスター",
        "goodsNo": "190054",
        "id": 92299,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Gyp",
        "similarGroup": "1903",
        "similarItem": "1903",
        "similarName": "石膏",
        "type": 0,
        "updateTime": "2021-05-25 10:53:02"
      },
      {
        "category": "19",
        "categoryEnName": "Non-metallic building materials",
        "categoryName": "非金属的建材",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "硅酸盐水泥",
        "goodsEnName": " portland cement",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 68247,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Cement",
        "similarGroup": "1904",
        "similarItem": "1904",
        "similarName": "水泥",
        "type": 0,
        "updateTime": "2021-05-25 10:49:04"
      },
      {
        "category": "19",
        "categoryEnName": "Non-metallic building materials",
        "categoryName": "非金属的建材",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "水泥板",
        "goodsEnName": "Cement slabs",
        "goodsJpName": "セメント製スラブ",
        "goodsNo": "190057",
        "id": 67155,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Cement precast-concrete",
        "similarGroup": "1905",
        "similarItem": "1905",
        "similarName": "水泥预制构件",
        "type": 0,
        "updateTime": "2021-05-25 10:53:03"
      },
      {
        "category": "19",
        "categoryEnName": "Non-metallic building materials",
        "categoryName": "非金属的建材",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "陶瓷砖",
        "goodsEnName": "Ceramic tile",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 65566,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Construction brick",
        "similarGroup": "1906",
        "similarItem": "1906",
        "similarName": "建筑砖瓦",
        "type": 0,
        "updateTime": "2021-05-25 10:45:16"
      },
      {
        "category": "19",
        "categoryEnName": "Non-metallic building materials",
        "categoryName": "非金属的建材",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "耐火黏土",
        "goodsEnName": "Fireclay",
        "goodsJpName": "耐火粘土",
        "goodsNo": "190048",
        "id": 69290,
        "niceCodeLists": "",
        "remark": "修改注",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Fire-resistant materials and articles for construction",
        "similarGroup": "1907",
        "similarItem": "1907",
        "similarName": "非金属耐火材料及制品",
        "type": 0,
        "updateTime": "2021-05-25 10:23:39"
      },
      {
        "category": "19",
        "categoryEnName": "Non-metallic building materials",
        "categoryName": "非金属的建材",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "石油沥青",
        "goodsEnName": "Asphalt",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 63627,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Pitch, asphalt and their articles",
        "similarGroup": "1908",
        "similarItem": "1908",
        "similarName": "柏油,沥青及制品",
        "type": 0,
        "updateTime": "2021-05-25 10:48:33"
      },
      {
        "category": "19",
        "categoryEnName": "Non-metallic building materials",
        "categoryName": "非金属的建材",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "非金属、非塑料制水管阀",
        "goodsEnName": "Water-pipe valves, not of metal or plastic",
        "goodsJpName": "送水管用バルブ（金属製のもの及びプラスチック製のものを除く。）",
        "goodsNo": "190077",
        "id": 57173,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Non-metallic construction material and member (not including cement precast-concrete)",
        "similarGroup": "1909",
        "similarItem": "1909（一）",
        "similarName": "非金属建筑材料及构件（不包括水泥预制构件）",
        "type": 0,
        "updateTime": "2021-05-25 10:38:06"
      },
      {
        "category": "19",
        "categoryEnName": "Non-metallic building materials",
        "categoryName": "非金属的建材",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "道路用非金属隔离栏",
        "goodsEnName": "Barriers for roads, not of metal",
        "goodsJpName": "道路用非金属分離柵",
        "goodsNo": "",
        "id": 55808,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Non-metallic buildings",
        "similarGroup": "1910",
        "similarItem": "",
        "similarName": "非金属建筑物",
        "type": 0,
        "updateTime": "2021-05-25 10:55:35"
      },
      {
        "category": "19",
        "categoryEnName": "Non-metallic building materials",
        "categoryName": "非金属的建材",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "建筑用发光玻璃",
        "goodsEnName": "Building luminous glass",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 62759,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Glass for construction and glass material",
        "similarGroup": "1911",
        "similarItem": "1911",
        "similarName": "建筑用玻璃及玻璃材料",
        "type": 0,
        "updateTime": "2021-05-25 10:44:07"
      },
      {
        "category": "19",
        "categoryEnName": "Non-metallic building materials",
        "categoryName": "非金属的建材",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "10",
        "flagBit": 0,
        "goodsChName": "涂层（建筑材料）",
        "goodsEnName": "Coatings [building materials]",
        "goodsJpName": "建築用塗材",
        "goodsNo": "190129",
        "id": 135552,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Coating material for construction",
        "similarGroup": "1912",
        "similarItem": "1912",
        "similarName": "建筑用涂层",
        "type": 1,
        "updateTime": "2021-05-25 10:34:16"
      },
      {
        "category": "19",
        "categoryEnName": "Non-metallic building materials",
        "categoryName": "非金属的建材",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "10",
        "flagBit": 0,
        "goodsChName": "修路用黏合材料",
        "goodsEnName": "Binding material for road repair",
        "goodsJpName": "道路補修用結合材料",
        "goodsNo": "190110",
        "id": 92040,
        "niceCodeLists": "",
        "remark": "修路用粘合材料  改为 修路用黏合材料",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Construction binder",
        "similarGroup": "1913",
        "similarItem": "1913",
        "similarName": "建筑用粘合料",
        "type": 0,
        "updateTime": "2021-05-25 10:49:22"
      },
      {
        "category": "19",
        "categoryEnName": "Non-metallic building materials",
        "categoryName": "非金属的建材",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "石、混凝土或大理石半身雕塑像",
        "goodsEnName": "Busts of stone, concrete or marble",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 58643,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Statues of stone，concrete or marble",
        "similarGroup": "1914",
        "similarItem": "",
        "similarName": "石、混凝土、大理石雕塑品",
        "type": 0,
        "updateTime": "2021-05-25 10:39:16"
      },
      {
        "category": "19",
        "categoryEnName": "Non-metallic building materials",
        "categoryName": "非金属的建材",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "墓穴用非金属围栏",
        "goodsEnName": "Tomb or grave enclosures, not of metal /  Grave or tomb enclosures, not of metal",
        "goodsJpName": "墓用囲い（金属製のものを除く。）",
        "goodsNo": "190081",
        "id": 63452,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Inner and outer coffins, grave monuments",
        "similarGroup": "1915",
        "similarItem": "1915",
        "similarName": "棺椁墓碑",
        "type": 0,
        "updateTime": "2021-05-25 11:19:33"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "道路用非金属隔离栏",
    "goodsEnName": "Barriers for roads, not of metal",
    "goodsJpName": "道路用非金属分離柵",
    "goodsNo": "",
    "id": 55808,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": "Non-metallic buildings",
    "similarGroup": "1910",
    "similarItem": "",
    "similarName": "非金属建筑物",
    "type": 0,
    "updateTime": "2021-05-25 10:55:35"
  },
  {
    "category": "2",
    "categoryEnName": "Pigments, Paints, resin",
    "categoryName": "颜料漆料涂料",
    "children": [
      {
        "category": "2",
        "categoryEnName": "Pigments, Paints, resin",
        "categoryName": "颜料漆料涂料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "姜黄（染料）",
        "goodsEnName": "Turmeric [colorant]",
        "goodsJpName": "うこん粉（着色剤）",
        "goodsNo": "020060",
        "id": 58419,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Colourants, mordant dyes (not for eating)",
        "similarGroup": "0201",
        "similarItem": "0201",
        "similarName": "染料,媒染剂（不包括食用）",
        "type": 0,
        "updateTime": "2021-05-25 10:54:49"
      },
      {
        "category": "2",
        "categoryEnName": "Pigments, Paints, resin",
        "categoryName": "颜料漆料涂料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "色母粒",
        "goodsEnName": "Color batch",
        "goodsJpName": "カラーバッチ",
        "goodsNo": "C020001",
        "id": 57488,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Pigments (not for eating and insulation), metal forming and metal powder used for painting, decoration, printing and art",
        "similarGroup": "0202",
        "similarItem": "0202",
        "similarName": "颜料（不包括食用、绝缘用）,绘画、装饰、印刷和艺术用金属箔及金属粉",
        "type": 0,
        "updateTime": "2021-05-25 11:26:28"
      },
      {
        "category": "2",
        "categoryEnName": "Pigments, Paints, resin",
        "categoryName": "颜料漆料涂料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "麦芽色素",
        "goodsEnName": "Malt colorants",
        "goodsJpName": "麦芽用色素",
        "goodsNo": "020048",
        "id": 58482,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Food strainers",
        "similarGroup": "0203",
        "similarItem": "0203",
        "similarName": "食品着色剂",
        "type": 0,
        "updateTime": "2021-05-25 11:29:03"
      },
      {
        "category": "2",
        "categoryEnName": "Pigments, Paints, resin",
        "categoryName": "颜料漆料涂料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "传真机用已填充墨盒",
        "goodsEnName": "Filled ink cartridge for fax machine",
        "goodsJpName": "ファックス用のインクカートリッジを充填しました。",
        "goodsNo": "",
        "id": 55507,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Printing ink",
        "similarGroup": "0204",
        "similarItem": "",
        "similarName": "油墨",
        "type": 0,
        "updateTime": "2021-05-25 10:55:52"
      },
      {
        "category": "2",
        "categoryEnName": "Pigments, Paints, resin",
        "categoryName": "颜料漆料涂料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "聚氨酯清漆",
        "goodsEnName": "Polyurethane varnish",
        "goodsJpName": "ウレタンワニス",
        "goodsNo": "",
        "id": 55549,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Coating materials, paints and additional materials (not including insulated paints), ceramic glazes ",
        "similarGroup": "0205",
        "similarItem": "",
        "similarName": "涂料,油漆及附料（不包括绝缘漆）",
        "type": 0,
        "updateTime": "2021-05-25 11:05:38"
      },
      {
        "category": "2",
        "categoryEnName": "Pigments, Paints, resin",
        "categoryName": "颜料漆料涂料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "木材防腐用杂酚油",
        "goodsEnName": "Creosote for wood preservation",
        "goodsJpName": "木材保存用クレオソート",
        "goodsNo": "020056",
        "id": 90829,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Antirust additives, wood preservatives",
        "similarGroup": "0206",
        "similarItem": "0206",
        "similarName": "防锈剂,木材防腐剂",
        "type": 0,
        "updateTime": "2021-05-25 11:33:57"
      },
      {
        "category": "2",
        "categoryEnName": "Pigments, Paints, resin",
        "categoryName": "颜料漆料涂料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "树胶脂",
        "goodsEnName": "Gum resins",
        "goodsJpName": "ガムロジン",
        "goodsNo": "020078",
        "id": 58489,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Unprocessed natural resin",
        "similarGroup": "0207",
        "similarItem": "0207",
        "similarName": "未加工的天然树脂",
        "type": 0,
        "updateTime": "2021-05-25 11:34:21"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "传真机用已填充墨盒",
    "goodsEnName": "Filled ink cartridge for fax machine",
    "goodsJpName": "ファックス用のインクカートリッジを充填しました。",
    "goodsNo": "",
    "id": 55507,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": "Printing ink",
    "similarGroup": "0204",
    "similarItem": "",
    "similarName": "油墨",
    "type": 0,
    "updateTime": "2021-05-25 10:55:52"
  },
  {
    "category": "20",
    "categoryEnName": "Furnitures, containers, bone and horn",
    "categoryName": "家具容器骨石",
    "children": [
      {
        "category": "20",
        "categoryEnName": "Furnitures, containers, bone and horn",
        "categoryName": "家具容器骨石",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "钥匙柜",
        "goodsEnName": "Key cabinet",
        "goodsJpName": "鍵たんす",
        "goodsNo": "",
        "id": 56165,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Furniture",
        "similarGroup": "2001",
        "similarItem": "",
        "similarName": "家具",
        "type": 0,
        "updateTime": "2021-05-25 11:28:35"
      },
      {
        "category": "20",
        "categoryEnName": "Furnitures, containers, bone and horn",
        "categoryName": "家具容器骨石",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "玩具箱",
        "goodsEnName": "Chests for toys",
        "goodsJpName": "おもちゃ箱",
        "goodsNo": "200256",
        "id": 57502,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Non-metallic containers and accessories",
        "similarGroup": "2002",
        "similarItem": "2002",
        "similarName": "非金属容器及附件",
        "type": 0,
        "updateTime": "2021-05-25 10:45:14"
      },
      {
        "category": "20",
        "categoryEnName": "Furnitures, containers, bone and horn",
        "categoryName": "家具容器骨石",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "木或塑料梯",
        "goodsEnName": "Ladders of wood or plastics",
        "goodsJpName": "木製又はプラスチック製のはしご",
        "goodsNo": "200092",
        "id": 65755,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Industrial and constructional auxiliary articles, not included in other classes",
        "similarGroup": "2003",
        "similarItem": "2003",
        "similarName": "不属别类的工业、建筑配件",
        "type": 1,
        "updateTime": "2021-05-25 11:22:28"
      },
      {
        "category": "20",
        "categoryEnName": "Furnitures, containers, bone and horn",
        "categoryName": "家具容器骨石",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "剃须镜",
        "goodsEnName": "Shaving mirrors",
        "goodsJpName": "ひげそり用鏡",
        "goodsNo": "",
        "id": 58405,
        "niceCodeLists": "",
        "remark": "可接受、非标准 2017-4",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Mirror, picture frame and components",
        "similarGroup": "2004",
        "similarItem": "2004",
        "similarName": "镜子、画框及部件",
        "type": 0,
        "updateTime": "2021-05-25 10:48:41"
      },
      {
        "category": "20",
        "categoryEnName": "Furnitures, containers, bone and horn",
        "categoryName": "家具容器骨石",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "绣花绷子",
        "goodsEnName": "Embroidery frames",
        "goodsJpName": "ししゅう用枠",
        "goodsNo": "200032",
        "id": 57593,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Bamboo articles, canework, palm fiber articles, grass articles all not belonging to other classes",
        "similarGroup": "2005",
        "similarItem": "2005",
        "similarName": "不属别类的竹、藤、棕、草制品",
        "type": 0,
        "updateTime": "2021-05-25 11:23:38"
      },
      {
        "category": "20",
        "categoryEnName": "Furnitures, containers, bone and horn",
        "categoryName": "家具容器骨石",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "动物标本",
        "goodsEnName": "Animal specimens",
        "goodsJpName": "動物標本",
        "goodsNo": "200006",
        "id": 57523,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Unprocessed or semi-processed bones, horns, teeth, armour and art works not belonging to other classes",
        "similarGroup": "2006",
        "similarItem": "2006",
        "similarName": "未加工或半加工的骨、角、牙、介及不属别类的工艺品",
        "type": 0,
        "updateTime": "2021-05-25 11:27:28"
      },
      {
        "category": "20",
        "categoryEnName": "Furnitures, containers, bone and horn",
        "categoryName": "家具容器骨石",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "广告气球",
        "goodsEnName": "An advertising balloon",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 64376,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Non-metallic license plate",
        "similarGroup": "2007",
        "similarItem": "2007",
        "similarName": "非金属牌照",
        "type": 0,
        "updateTime": "2021-05-25 10:44:47"
      },
      {
        "category": "20",
        "categoryEnName": "Furnitures, containers, bone and horn",
        "categoryName": "家具容器骨石",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "8",
        "flagBit": 0,
        "goodsChName": "食品用塑料装饰品",
        "goodsEnName": "Decorations of plastic for foodstuffs",
        "goodsJpName": "食品用プラスチック製装飾品",
        "goodsNo": "200003",
        "id": 69822,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Plastic articles for food",
        "similarGroup": "2008",
        "similarItem": "2008",
        "similarName": "食品用塑料装饰品",
        "type": 1,
        "updateTime": "2021-05-25 11:24:12"
      },
      {
        "category": "20",
        "categoryEnName": "Furnitures, containers, bone and horn",
        "categoryName": "家具容器骨石",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "蜂箱用巢础",
        "goodsEnName": "Comb foundations for beehives",
        "goodsJpName": "養蜂用巣礎",
        "goodsNo": "200039",
        "id": 67631,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Articles for birds and livestock",
        "similarGroup": "2009",
        "similarItem": "2009",
        "similarName": "禽、畜等动物用制品",
        "type": 0,
        "updateTime": "2021-05-25 11:27:32"
      },
      {
        "category": "20",
        "categoryEnName": "Furnitures, containers, bone and horn",
        "categoryName": "家具容器骨石",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "8",
        "flagBit": 0,
        "goodsChName": "非金属制身份鉴别手环",
        "goodsEnName": "Identification bracelets, not of metal",
        "goodsJpName": "識別腕輪（金属製のものを除く。）",
        "goodsNo": "200221",
        "id": 148019,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Identification bracelets, not of metal",
        "similarGroup": "2010",
        "similarItem": "2010",
        "similarName": "非金属制身份鉴别手环",
        "type": 1,
        "updateTime": "2021-05-25 10:13:29"
      },
      {
        "category": "20",
        "categoryEnName": "Furnitures, containers, bone and horn",
        "categoryName": "家具容器骨石",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "8",
        "flagBit": 0,
        "goodsChName": "棺材用非金属附件",
        "goodsEnName": "Coffin fittings, not of metal",
        "goodsJpName": "棺の付属品及び金具（金属製のものを除く。）",
        "goodsNo": "200048",
        "id": 107461,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Non-metallic coffins and accessories",
        "similarGroup": "2011",
        "similarItem": "2011",
        "similarName": "非金属棺材及附件",
        "type": 0,
        "updateTime": "2021-05-25 10:35:43"
      },
      {
        "category": "20",
        "categoryEnName": "Furnitures, containers, bone and horn",
        "categoryName": "家具容器骨石",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "球形非金属把手",
        "goodsEnName": "Knobs, not of metal",
        "goodsJpName": "ノブ（金属製のものを除く。）",
        "goodsNo": "200277",
        "id": 64782,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Non-metallic furniture accessories",
        "similarGroup": "2012",
        "similarItem": "2012",
        "similarName": "非金属家具附件",
        "type": 0,
        "updateTime": "2021-05-25 11:49:01"
      },
      {
        "category": "20",
        "categoryEnName": "Furnitures, containers, bone and horn",
        "categoryName": "家具容器骨石",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "玉枕",
        "goodsEnName": "Jade pillow",
        "goodsJpName": "玉枕",
        "goodsNo": "C200034",
        "id": 67967,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Pads, pillows",
        "similarGroup": "2013",
        "similarItem": "2013",
        "similarName": "垫,枕",
        "type": 0,
        "updateTime": "2021-05-25 11:49:03"
      },
      {
        "category": "20",
        "categoryEnName": "Furnitures, containers, bone and horn",
        "categoryName": "家具容器骨石",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "非金属膨胀螺栓",
        "goodsEnName": "Plugs [dowels], not of metal / Wall plugs, not of metal",
        "goodsJpName": "プラグ（合わせくぎ）（金属製のものを除く。）",
        "goodsNo": "200232",
        "id": 56396,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Non-metallic door and window accessories and fasteners",
        "similarGroup": "2014",
        "similarItem": "2014",
        "similarName": "非金属紧固件及门窗附件",
        "type": 0,
        "updateTime": "2021-05-25 11:06:41"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "钥匙柜",
    "goodsEnName": "Key cabinet",
    "goodsJpName": "鍵たんす",
    "goodsNo": "",
    "id": 56165,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": " Furniture",
    "similarGroup": "2001",
    "similarItem": "",
    "similarName": "家具",
    "type": 0,
    "updateTime": "2021-05-25 11:28:35"
  },
  {
    "category": "21",
    "categoryEnName": "Household and kitchen utensils",
    "categoryName": "家居厨房器具",
    "children": [
      {
        "category": "21",
        "categoryEnName": "Household and kitchen utensils",
        "categoryName": "家居厨房器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "馅饼排气笛",
        "goodsEnName": "Pie exhaust whistle",
        "goodsJpName": "パイの排気笛",
        "goodsNo": "",
        "id": 55297,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Cooking utensils and containers for kitchen ",
        "similarGroup": "2101",
        "similarItem": "",
        "similarName": "厨房炊事用具及容器（包括不属别类的餐具）",
        "type": 0,
        "updateTime": "2021-05-25 10:03:15"
      },
      {
        "category": "21",
        "categoryEnName": "Household and kitchen utensils",
        "categoryName": "家居厨房器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "玻璃碗",
        "goodsEnName": "Glass bowls",
        "goodsJpName": "ガラス製ボウル",
        "goodsNo": "C210059",
        "id": 57495,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Glass utensils not belonging to other classes ",
        "similarGroup": "2102",
        "similarItem": "2102",
        "similarName": "不属别类的玻璃器皿",
        "type": 0,
        "updateTime": "2021-05-25 11:46:31"
      },
      {
        "category": "21",
        "categoryEnName": "Household and kitchen utensils",
        "categoryName": "家居厨房器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "陶制平底锅",
        "goodsEnName": "Earthenware saucepans",
        "goodsJpName": "陶器製シチューなべ",
        "goodsNo": "210181",
        "id": 56543,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Porcelain, earthenware (except tea set and wine set)",
        "similarGroup": "2103",
        "similarItem": "2103",
        "similarName": "瓷器,陶器（茶具,酒具除外）",
        "type": 0,
        "updateTime": "2021-05-25 11:46:32"
      },
      {
        "category": "21",
        "categoryEnName": "Household and kitchen utensils",
        "categoryName": "家居厨房器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "陶制半身雕像",
        "goodsEnName": "Pottery bust statue",
        "goodsJpName": "陶製半身の彫像",
        "goodsNo": "",
        "id": 55626,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Glass handicraft, porcelain handicraft, pottery handicraft",
        "similarGroup": "2104",
        "similarItem": "",
        "similarName": "玻璃、瓷、陶的工艺品",
        "type": 0,
        "updateTime": "2021-05-25 11:07:46"
      },
      {
        "category": "21",
        "categoryEnName": "Household and kitchen utensils",
        "categoryName": "家居厨房器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "角形饮水器",
        "goodsEnName": "Drinking horns",
        "goodsJpName": "飲料用角杯",
        "goodsNo": "210086",
        "id": 57481,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Tea set, wine set, coffee set and drinking utensil ",
        "similarGroup": "2105",
        "similarItem": "2105",
        "similarName": "茶具、酒具、咖啡具及饮水用具",
        "type": 0,
        "updateTime": "2021-05-25 11:28:28"
      },
      {
        "category": "21",
        "categoryEnName": "Household and kitchen utensils",
        "categoryName": "家居厨房器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "木制靴撑",
        "goodsEnName": "Wooden boot trees",
        "goodsJpName": "木製のブーツホルダー",
        "goodsNo": "",
        "id": 55388,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Household daily utensils and sanitary",
        "similarGroup": "2106",
        "similarItem": "",
        "similarName": "家庭日用及卫生器具",
        "type": 0,
        "updateTime": "2021-05-25 11:12:04"
      },
      {
        "category": "21",
        "categoryEnName": "Household and kitchen utensils",
        "categoryName": "家居厨房器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "宠物祛毛梳",
        "goodsEnName": "Pet hair comb",
        "goodsJpName": "ペットは毛束をとる",
        "goodsNo": "",
        "id": 55451,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Comb, brush, material for making brush (not including toothbrush)",
        "similarGroup": "2107",
        "similarItem": "",
        "similarName": "梳子,刷子（不包括牙刷）,制刷材料",
        "type": 0,
        "updateTime": "2021-05-25 10:55:50"
      },
      {
        "category": "21",
        "categoryEnName": "Household and kitchen utensils",
        "categoryName": "家居厨房器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "电动牙刷替换头",
        "goodsEnName": "Heads for electric toothbrushes",
        "goodsJpName": "電気式歯ブラシ用ヘッド",
        "goodsNo": "210375",
        "id": 73441,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Utensils for brushing one’s teeth",
        "similarGroup": "2108",
        "similarItem": "2108",
        "similarName": "刷牙用具",
        "type": 0,
        "updateTime": "2021-05-25 10:11:42"
      },
      {
        "category": "21",
        "categoryEnName": "Household and kitchen utensils",
        "categoryName": "家居厨房器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "7,8",
        "flagBit": 0,
        "goodsChName": "牙签",
        "goodsEnName": "Toothpicks",
        "goodsJpName": "ようじ",
        "goodsNo": "210105",
        "id": 111871,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Toothpicks",
        "similarGroup": "2109",
        "similarItem": "2109",
        "similarName": "牙签",
        "type": 1,
        "updateTime": "2021-05-25 11:23:40"
      },
      {
        "category": "21",
        "categoryEnName": "Household and kitchen utensils",
        "categoryName": "家居厨房器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "面部去角质垫",
        "goodsEnName": "Facial exfoliating pad",
        "goodsJpName": "顔の角質除去パッド",
        "goodsNo": "",
        "id": 56032,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Cosmetic utensils",
        "similarGroup": "2110",
        "similarItem": "",
        "similarName": "化妆用具",
        "type": 0,
        "updateTime": "2021-05-25 11:04:17"
      },
      {
        "category": "21",
        "categoryEnName": "Household and kitchen utensils",
        "categoryName": "家居厨房器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "保温瓶",
        "goodsEnName": "Insulating flasks",
        "goodsJpName": "魔法瓶",
        "goodsNo": "210046",
        "id": 59553,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Heat-insulating utensils",
        "similarGroup": "2111",
        "similarItem": "2111",
        "similarName": "隔热用具",
        "type": 0,
        "updateTime": "2021-05-25 11:28:51"
      },
      {
        "category": "21",
        "categoryEnName": "Household and kitchen utensils",
        "categoryName": "家居厨房器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "清洁用废羊毛",
        "goodsEnName": "Wool waste for cleaning",
        "goodsJpName": "清掃用毛くず",
        "goodsNo": "210163",
        "id": 62703,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Household utensils",
        "similarGroup": "2112",
        "similarItem": "2112",
        "similarName": "家务用具",
        "type": 0,
        "updateTime": "2021-05-25 11:29:20"
      },
      {
        "category": "21",
        "categoryEnName": "Household and kitchen utensils",
        "categoryName": "家居厨房器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "合成灵敏导电玻璃",
        "goodsEnName": "Glass incorporating fine electrical conductors",
        "goodsJpName": "電導体入りガラス",
        "goodsNo": "210229",
        "id": 73721,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Unprocessed or semi-processed glasses (not including glasses for construction)",
        "similarGroup": "2113",
        "similarItem": "2113",
        "similarName": "未加工或半加工玻璃（不包括建筑用玻璃）",
        "type": 0,
        "updateTime": "2021-05-25 11:29:23"
      },
      {
        "category": "21",
        "categoryEnName": "Household and kitchen utensils",
        "categoryName": "家居厨房器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "宠物用笼子",
        "goodsEnName": "Pet cage",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 57999,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Utensils for plants and animals not belonging to other classes",
        "similarGroup": "2114",
        "similarItem": "2114",
        "similarName": "不属别类的动植物器具",
        "type": 0,
        "updateTime": "2021-05-25 10:44:28"
      },
      {
        "category": "21",
        "categoryEnName": "Household and kitchen utensils",
        "categoryName": "家居厨房器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "蝇拍",
        "goodsEnName": "Fly swatters",
        "goodsJpName": "はえたたき",
        "goodsNo": "210069",
        "id": 63214,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Deinsectization and deratization utensils for household",
        "similarGroup": "2115",
        "similarItem": "2115",
        "similarName": "家用灭虫、灭鼠用具",
        "type": 0,
        "updateTime": "2021-05-25 11:19:12"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "馅饼排气笛",
    "goodsEnName": "Pie exhaust whistle",
    "goodsJpName": "パイの排気笛",
    "goodsNo": "",
    "id": 55297,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": " Cooking utensils and containers for kitchen ",
    "similarGroup": "2101",
    "similarItem": "",
    "similarName": "厨房炊事用具及容器（包括不属别类的餐具）",
    "type": 0,
    "updateTime": "2021-05-25 10:03:15"
  },
  {
    "category": "22",
    "categoryEnName": "Ropes, nets, tents, canvas",
    "categoryName": "绳网布帆填料",
    "children": [
      {
        "category": "22",
        "categoryEnName": "Ropes, nets, tents, canvas",
        "categoryName": "绳网布帆填料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "麻带",
        "goodsEnName": "Hemp bands",
        "goodsJpName": "麻製ひも",
        "goodsNo": "220015",
        "id": 64719,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Cable, rope, thread, belt ",
        "similarGroup": "2201",
        "similarItem": "2201",
        "similarName": "缆,绳,线,带",
        "type": 0,
        "updateTime": "2021-05-25 11:33:16"
      },
      {
        "category": "22",
        "categoryEnName": "Ropes, nets, tents, canvas",
        "categoryName": "绳网布帆填料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "帐篷",
        "goodsEnName": "Tents",
        "goodsJpName": "テント",
        "goodsNo": "220071",
        "id": 57831,
        "niceCodeLists": "",
        "remark": "2019版【修改】220071“帐篷”改为“帐篷*”",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Net, awning, tent, waterproof tilt, sail ",
        "similarGroup": "2202",
        "similarItem": "2202（五）",
        "similarName": "网,遮篷,帐篷,防水帆布,帆",
        "type": 1,
        "updateTime": "2021-05-25 11:35:23"
      },
      {
        "category": "22",
        "categoryEnName": "Ropes, nets, tents, canvas",
        "categoryName": "绳网布帆填料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "尼龙编织袋（仿麻袋）",
        "goodsEnName": "Nylon woven bag (imitation hemp bag)",
        "goodsJpName": "ナイロンバッグ",
        "goodsNo": "C220011",
        "id": 56984,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Sack, article for stevedoring and packing ",
        "similarGroup": "2203",
        "similarItem": "2203（一）",
        "similarName": "袋子,装卸、包装用物品",
        "type": 0,
        "updateTime": "2021-05-25 11:24:35"
      },
      {
        "category": "22",
        "categoryEnName": "Ropes, nets, tents, canvas",
        "categoryName": "绳网布帆填料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "羽绒",
        "goodsEnName": "Down [feathers]",
        "goodsJpName": "綿毛（羽毛）",
        "goodsNo": "220028",
        "id": 81113,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Backing strap, filling material, sealed articles (not including rubber and plastic articles)",
        "similarGroup": "2204",
        "similarItem": "2204",
        "similarName": "衬垫,填充料,密封物品（不包括橡胶、塑料制品）",
        "type": 0,
        "updateTime": "2021-05-25 10:48:30"
      },
      {
        "category": "22",
        "categoryEnName": "Ropes, nets, tents, canvas",
        "categoryName": "绳网布帆填料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "未加工的纺织纤维",
        "goodsEnName": "Unprocessed textile fibre",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 57530,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Fiber raw material ",
        "similarGroup": "2205",
        "similarItem": "2205",
        "similarName": "纤维原料",
        "type": 0,
        "updateTime": "2021-05-25 10:48:11"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "尼龙编织袋（仿麻袋）",
    "goodsEnName": "Nylon woven bag (imitation hemp bag)",
    "goodsJpName": "ナイロンバッグ",
    "goodsNo": "C220011",
    "id": 56984,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": " Sack, article for stevedoring and packing ",
    "similarGroup": "2203",
    "similarItem": "2203（一）",
    "similarName": "袋子,装卸、包装用物品",
    "type": 0,
    "updateTime": "2021-05-25 11:24:35"
  },
  {
    "category": "23",
    "categoryEnName": "Yarns and threads for textile use",
    "categoryName": "纺织用纱和线",
    "children": [
      {
        "category": "23",
        "categoryEnName": "Yarns and threads for textile use",
        "categoryName": "纺织用纱和线",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "精纺毛纱",
        "goodsEnName": "Worsted yarn",
        "goodsJpName": "綿紡糸",
        "goodsNo": "",
        "id": 60281,
        "niceCodeLists": "",
        "remark": "可接受、非标准 2017-4",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Yarn and silk for spinning and weaving ",
        "similarGroup": "2301",
        "similarItem": "2301",
        "similarName": "纺织用纱、丝",
        "type": 0,
        "updateTime": "2021-05-25 11:40:43"
      },
      {
        "category": "23",
        "categoryEnName": "Yarns and threads for textile use",
        "categoryName": "纺织用纱和线",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "细线和细纱",
        "goodsEnName": "Spun thread and yarn",
        "goodsJpName": "紡績糸",
        "goodsNo": "230010",
        "id": 57236,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Thread ",
        "similarGroup": "2302",
        "similarItem": "2301",
        "similarName": "线",
        "type": 0,
        "updateTime": "2021-05-25 11:00:00"
      },
      {
        "category": "23",
        "categoryEnName": "Yarns and threads for textile use",
        "categoryName": "纺织用纱和线",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "膨体线",
        "goodsEnName": "Lofted yarn / Bulk yarn",
        "goodsJpName": "バルキーヤーン",
        "goodsNo": "C230013",
        "id": 57852,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Knitting wool ",
        "similarGroup": "2303",
        "similarItem": "2303",
        "similarName": "毛线",
        "type": 0,
        "updateTime": "2021-05-25 10:33:03"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "细线和细纱",
    "goodsEnName": "Spun thread and yarn",
    "goodsJpName": "紡績糸",
    "goodsNo": "230010",
    "id": 57236,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": " Thread ",
    "similarGroup": "2302",
    "similarItem": "2301",
    "similarName": "线",
    "type": 0,
    "updateTime": "2021-05-25 11:00:00"
  },
  {
    "category": "24",
    "categoryEnName": "Textile for daily use",
    "categoryName": "日常用纺织品",
    "children": [
      {
        "category": "24",
        "categoryEnName": "Textile for daily use",
        "categoryName": "日常用纺织品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "纺织的弹性布料",
        "goodsEnName": "Elastic woven material",
        "goodsJpName": "弾性織物",
        "goodsNo": "240046",
        "id": 56627,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Dry goods, cloth ",
        "similarGroup": "2401",
        "similarItem": "2401",
        "similarName": "纺织品,布料",
        "type": 0,
        "updateTime": "2021-05-25 11:12:07"
      },
      {
        "category": "24",
        "categoryEnName": "Textile for daily use",
        "categoryName": "日常用纺织品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "帘子布",
        "goodsEnName": "Tyre cord fabric",
        "goodsJpName": "ブラインドクロス ",
        "goodsNo": "C240016",
        "id": 59602,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Textile for special use ",
        "similarGroup": "2402",
        "similarItem": "2402",
        "similarName": "特殊用织物",
        "type": 0,
        "updateTime": "2021-05-25 10:54:14"
      },
      {
        "category": "24",
        "categoryEnName": "Textile for daily use",
        "categoryName": "日常用纺织品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "丝织、交织图画",
        "goodsEnName": "Silk complect picture",
        "goodsJpName": "絹編み絵",
        "goodsNo": "C240021",
        "id": 59413,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Wall hangings of textile ",
        "similarGroup": "2403",
        "similarItem": "2403",
        "similarName": "纺织品壁挂",
        "type": 0,
        "updateTime": "2021-05-25 11:20:43"
      },
      {
        "category": "24",
        "categoryEnName": "Textile for daily use",
        "categoryName": "日常用纺织品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "4,8",
        "flagBit": 0,
        "goodsChName": "毡",
        "goodsEnName": "Felt",
        "goodsJpName": "フェルト",
        "goodsNo": "240027",
        "id": 65265,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Felt and felted texture ",
        "similarGroup": "2404",
        "similarItem": "2404",
        "similarName": "毡及毡制品",
        "type": 1,
        "updateTime": "2021-05-25 11:20:43"
      },
      {
        "category": "24",
        "categoryEnName": "Textile for daily use",
        "categoryName": "日常用纺织品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "连帽浴巾",
        "goodsEnName": "Hooded Bath Towel",
        "goodsJpName": "フード付きのバスタオル",
        "goodsNo": "",
        "id": 57796,
        "niceCodeLists": "",
        "remark": "可接受、非标准 2017-4",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Towel, washcloth, handkerchief ",
        "similarGroup": "2405",
        "similarItem": "2405",
        "similarName": "毛巾,浴巾,手帕",
        "type": 0,
        "updateTime": "2021-05-25 10:27:31"
      },
      {
        "category": "24",
        "categoryEnName": "Textile for daily use",
        "categoryName": "日常用纺织品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "床上用毯",
        "goodsEnName": "Bed blankets",
        "goodsJpName": "ベッド用毛布",
        "goodsNo": "240114",
        "id": 60246,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Bedding ",
        "similarGroup": "2406",
        "similarItem": "2406",
        "similarName": "床上用品",
        "type": 0,
        "updateTime": "2021-05-25 11:20:47"
      },
      {
        "category": "24",
        "categoryEnName": "Textile for daily use",
        "categoryName": "日常用纺织品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "波幔窗帘",
        "goodsEnName": "Wave curtain",
        "goodsJpName": "カーテン",
        "goodsNo": "",
        "id": 55409,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Interior overcover ",
        "similarGroup": "2407",
        "similarItem": "",
        "similarName": "室内遮盖物",
        "type": 0,
        "updateTime": "2021-05-25 10:55:49"
      },
      {
        "category": "24",
        "categoryEnName": "Textile for daily use",
        "categoryName": "日常用纺织品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "8",
        "flagBit": 0,
        "goodsChName": "哈达",
        "goodsEnName": "Hada (a piece of silk used as a greeting gift among the zang and mongol nationalities)",
        "goodsJpName": "チベット族や一部の蒙古族の間で敬意・祝意を表わすために献ずる白・黄・青色などの薄絹",
        "goodsNo": "C240048",
        "id": 74988,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Cloth for special use ",
        "similarGroup": "2409",
        "similarItem": "2409（二）",
        "similarName": "特殊用布",
        "type": 1,
        "updateTime": "2021-05-25 11:48:28"
      },
      {
        "category": "24",
        "categoryEnName": "Textile for daily use",
        "categoryName": "日常用纺织品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "纺织品制旗",
        "goodsEnName": "Banners made of textiles",
        "goodsJpName": "紡織品製旗",
        "goodsNo": "",
        "id": 59889,
        "niceCodeLists": "",
        "remark": "可接受、非标准 2017-4",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Flag of textile or plastic ",
        "similarGroup": "2410",
        "similarItem": "2410",
        "similarName": "纺织品制或塑料制旗",
        "type": 0,
        "updateTime": "2021-05-25 11:40:30"
      },
      {
        "category": "24",
        "categoryEnName": "Textile for daily use",
        "categoryName": "日常用纺织品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "8",
        "flagBit": 0,
        "goodsChName": "寿衣",
        "goodsEnName": "Shrouds",
        "goodsJpName": "遺体覆い",
        "goodsNo": "240041",
        "id": 91543,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Graveclothes ",
        "similarGroup": "2411",
        "similarItem": "2411",
        "similarName": "寿衣",
        "type": 1,
        "updateTime": "2021-05-25 11:48:28"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "波幔窗帘",
    "goodsEnName": "Wave curtain",
    "goodsJpName": "カーテン",
    "goodsNo": "",
    "id": 55409,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": " Interior overcover ",
    "similarGroup": "2407",
    "similarItem": "",
    "similarName": "室内遮盖物",
    "type": 0,
    "updateTime": "2021-05-25 10:55:49"
  },
  {
    "category": "25",
    "categoryEnName": "Clothing, footwear, headgear",
    "categoryName": "服装服饰鞋帽",
    "children": [
      {
        "category": "25",
        "categoryEnName": "Clothing, footwear, headgear",
        "categoryName": "服装服饰鞋帽",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "羊绒衫",
        "goodsEnName": "Cashmere sweater",
        "goodsJpName": "カシミヤのシャツ",
        "goodsNo": "",
        "id": 56221,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Clothing and other articles of daily use ",
        "similarGroup": "2501",
        "similarItem": "",
        "similarName": "衣物",
        "type": 0,
        "updateTime": "2021-05-25 11:12:46"
      },
      {
        "category": "25",
        "categoryEnName": "Clothing, footwear, headgear",
        "categoryName": "服装服饰鞋帽",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "非纸制围涎",
        "goodsEnName": "Bibs, not of paper",
        "goodsJpName": "よだれ掛け又は胸当て（紙製の\nものを除く。）",
        "goodsNo": "250128",
        "id": 83402,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Dry goods for baby ",
        "similarGroup": "2502",
        "similarItem": "2502",
        "similarName": "婴儿纺织用品",
        "type": 0,
        "updateTime": "2021-05-25 11:08:40"
      },
      {
        "category": "25",
        "categoryEnName": "Clothing, footwear, headgear",
        "categoryName": "服装服饰鞋帽",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "滑水服",
        "goodsEnName": "Water skiing clothing",
        "goodsJpName": "水上スキー服",
        "goodsNo": "",
        "id": 57845,
        "niceCodeLists": "",
        "remark": "可接受，非标准",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Special type gym unit ",
        "similarGroup": "2503",
        "similarItem": "2503",
        "similarName": "特种运动服装",
        "type": 0,
        "updateTime": "2021-05-25 11:40:11"
      },
      {
        "category": "25",
        "categoryEnName": "Clothing, footwear, headgear",
        "categoryName": "服装服饰鞋帽",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "雨衣",
        "goodsEnName": "Raincoat",
        "goodsJpName": "レインコート",
        "goodsNo": "C250011",
        "id": 58223,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Not-permeable clothes ",
        "similarGroup": "2504",
        "similarItem": "2504",
        "similarName": "不透水服装",
        "type": 0,
        "updateTime": "2021-05-25 10:29:35"
      },
      {
        "category": "25",
        "categoryEnName": "Clothing, footwear, headgear",
        "categoryName": "服装服饰鞋帽",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "芭蕾舞短裙",
        "goodsEnName": "Tutu",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 59056,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Theatrical costume ",
        "similarGroup": "2505",
        "similarItem": "2505",
        "similarName": "戏装",
        "type": 0,
        "updateTime": "2021-05-25 10:46:12"
      },
      {
        "category": "25",
        "categoryEnName": "Clothing, footwear, headgear",
        "categoryName": "服装服饰鞋帽",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "徒步靴",
        "goodsEnName": "Hiking boots",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 56445,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Shoes ",
        "similarGroup": "2507",
        "similarItem": "2507",
        "similarName": "鞋",
        "type": 0,
        "updateTime": "2021-05-25 11:10:13"
      },
      {
        "category": "25",
        "categoryEnName": "Clothing, footwear, headgear",
        "categoryName": "服装服饰鞋帽",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "2,4,12,16,17",
        "flagBit": 0,
        "goodsChName": "帽",
        "goodsEnName": "Hats",
        "goodsJpName": "帽子",
        "goodsNo": "250046",
        "id": 61674,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Caps ",
        "similarGroup": "2508",
        "similarItem": "2508",
        "similarName": "帽",
        "type": 1,
        "updateTime": "2021-05-25 10:30:05"
      },
      {
        "category": "25",
        "categoryEnName": "Clothing, footwear, headgear",
        "categoryName": "服装服饰鞋帽",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "长袜",
        "goodsEnName": "Stockings",
        "goodsJpName": "ストッキング及びユニホーム用\nストッキング",
        "goodsNo": "250006",
        "id": 59896,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Hoses ",
        "similarGroup": "2509",
        "similarItem": "2509",
        "similarName": "袜",
        "type": 0,
        "updateTime": "2021-05-25 11:02:59"
      },
      {
        "category": "25",
        "categoryEnName": "Clothing, footwear, headgear",
        "categoryName": "服装服饰鞋帽",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "皮手笼",
        "goodsEnName": "Leather hand cage",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 69787,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Gloves (not including gloves of special type) ",
        "similarGroup": "2510",
        "similarItem": "2510",
        "similarName": "手套（不包括特种手套）",
        "type": 0,
        "updateTime": "2021-05-25 11:41:04"
      },
      {
        "category": "25",
        "categoryEnName": "Clothing, footwear, headgear",
        "categoryName": "服装服饰鞋帽",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "2,4,16,17",
        "flagBit": 0,
        "goodsChName": "领带",
        "goodsEnName": "Neckties",
        "goodsJpName": "ネクタイ",
        "goodsNo": "250060",
        "id": 56851,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Necktie, scarf, shawl, veil ",
        "similarGroup": "2511",
        "similarItem": "2511",
        "similarName": "领带,围巾,披巾,面纱",
        "type": 1,
        "updateTime": "2021-05-25 10:28:14"
      },
      {
        "category": "25",
        "categoryEnName": "Clothing, footwear, headgear",
        "categoryName": "服装服饰鞋帽",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "2",
        "flagBit": 0,
        "goodsChName": "服装带（衣服）",
        "goodsEnName": "Belts [clothing]",
        "goodsJpName": "ベルト",
        "goodsNo": "250031",
        "id": 76395,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Waistband, dress belt ",
        "similarGroup": "2512",
        "similarItem": "2512",
        "similarName": "腰带,服装带",
        "type": 1,
        "updateTime": "2021-05-25 10:28:16"
      },
      {
        "category": "25",
        "categoryEnName": "Clothing, footwear, headgear",
        "categoryName": "服装服饰鞋帽",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "2",
        "flagBit": 0,
        "goodsChName": "浴帽",
        "goodsEnName": "Shower caps",
        "goodsJpName": "シャワーキャップ",
        "goodsNo": "250158",
        "id": 85138,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Single goods ",
        "similarGroup": "2513",
        "similarItem": "2513（二）",
        "similarName": "单一商品",
        "type": 1,
        "updateTime": "2021-05-25 11:36:15"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "羊绒衫",
    "goodsEnName": "Cashmere sweater",
    "goodsJpName": "カシミヤのシャツ",
    "goodsNo": "",
    "id": 56221,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": " Clothing and other articles of daily use ",
    "similarGroup": "2501",
    "similarItem": "",
    "similarName": "衣物",
    "type": 0,
    "updateTime": "2021-05-25 11:12:46"
  },
  {
    "category": "26",
    "categoryEnName": "Lace, embroidery, false hair",
    "categoryName": "花边配饰假发",
    "children": [
      {
        "category": "26",
        "categoryEnName": "Lace, embroidery, false hair",
        "categoryName": "花边配饰假发",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "2",
        "flagBit": 0,
        "goodsChName": "花边",
        "goodsEnName": "Lace for edgings",
        "goodsJpName": "縁飾り用レース",
        "goodsNo": "260019",
        "id": 57194,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Lace, ornament and braid ",
        "similarGroup": "2601",
        "similarItem": "2601",
        "similarName": "花边,饰品及编带",
        "type": 0,
        "updateTime": "2021-05-25 10:28:10"
      },
      {
        "category": "26",
        "categoryEnName": "Lace, embroidery, false hair",
        "categoryName": "花边配饰假发",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "安全别针",
        "goodsEnName": "Safety pins",
        "goodsJpName": "セーフティピン",
        "goodsNo": "",
        "id": 60519,
        "niceCodeLists": "",
        "remark": "可接受、非标准 2017-4",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Dress ornament not belonging to other classes, ornamental needle ",
        "similarGroup": "2602",
        "similarItem": "2602",
        "similarName": "不属别类的服饰品,饰针",
        "type": 0,
        "updateTime": "2021-05-25 11:39:20"
      },
      {
        "category": "26",
        "categoryEnName": "Lace, embroidery, false hair",
        "categoryName": "花边配饰假发",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "搭扣带",
        "goodsEnName": "Hook and pile fastening tapes",
        "goodsJpName": "面ファスナー",
        "goodsNo": "260116",
        "id": 58867,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Button, tack, zipper ",
        "similarGroup": "2603",
        "similarItem": "2603",
        "similarName": "纽扣,领钩扣,拉链",
        "type": 0,
        "updateTime": "2021-05-25 11:31:15"
      },
      {
        "category": "26",
        "categoryEnName": "Lace, embroidery, false hair",
        "categoryName": "花边配饰假发",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "2",
        "flagBit": 0,
        "goodsChName": "接发片",
        "goodsEnName": "Hair extensions",
        "goodsJpName": "ヘアエクステンション",
        "goodsNo": "260125",
        "id": 59427,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Wig, artificial beard ",
        "similarGroup": "2604",
        "similarItem": "2604",
        "similarName": "假发,假胡须",
        "type": 0,
        "updateTime": "2021-05-25 10:33:05"
      },
      {
        "category": "26",
        "categoryEnName": "Lace, embroidery, false hair",
        "categoryName": "花边配饰假发",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "装订针",
        "goodsEnName": "Binding needles",
        "goodsJpName": "綴じ針",
        "goodsNo": "260006",
        "id": 58692,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Articles for sewing (except thread)",
        "similarGroup": "2605",
        "similarItem": "2605",
        "similarName": "缝纫用具（线除外）",
        "type": 0,
        "updateTime": "2021-05-25 11:31:18"
      },
      {
        "category": "26",
        "categoryEnName": "Lace, embroidery, false hair",
        "categoryName": "花边配饰假发",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "绢花",
        "goodsEnName": "Silk flower ",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 57187,
        "niceCodeLists": "",
        "remark": "2018-6, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Artificial flowers ",
        "similarGroup": "2606",
        "similarItem": "2606",
        "similarName": "假花",
        "type": 0,
        "updateTime": "2021-05-25 10:46:30"
      },
      {
        "category": "26",
        "categoryEnName": "Lace, embroidery, false hair",
        "categoryName": "花边配饰假发",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "胸罩衬骨",
        "goodsEnName": "Corset busks",
        "goodsJpName": "コルセットの胸部の張り枠",
        "goodsNo": "260012",
        "id": 63263,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Lining bone with hard base ",
        "similarGroup": "2607",
        "similarItem": "2607",
        "similarName": "硬托衬骨",
        "type": 0,
        "updateTime": "2021-05-25 11:26:26"
      },
      {
        "category": "26",
        "categoryEnName": "Lace, embroidery, false hair",
        "categoryName": "花边配饰假发",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "4",
        "flagBit": 0,
        "goodsChName": "修补纺织品用热黏合补片",
        "goodsEnName": "Heat adhesive patches for repairing textile articles",
        "goodsJpName": "繊維製品修繕用の加熱粘着パッ\nチ",
        "goodsNo": "260081",
        "id": 187926,
        "niceCodeLists": "",
        "remark": "修补纺织品用热粘合补片  改为 修补纺织品用热黏合补片",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Thermal film for repairing textile ",
        "similarGroup": "2608",
        "similarItem": "2608",
        "similarName": "修补纺织品用热粘胶片",
        "type": 1,
        "updateTime": "2021-05-25 10:10:10"
      },
      {
        "category": "26",
        "categoryEnName": "Lace, embroidery, false hair",
        "categoryName": "花边配饰假发",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "竞赛者用号码",
        "goodsEnName": "Competitors' numbers",
        "goodsJpName": "競技用ゼッケン",
        "goodsNo": "260112",
        "id": 71579,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Laval articles for labeling",
        "similarGroup": "2609",
        "similarItem": "2609",
        "similarName": "亚麻布标记用品",
        "type": 0,
        "updateTime": "2021-05-25 11:26:27"
      },
      {
        "category": "26",
        "categoryEnName": "Lace, embroidery, false hair",
        "categoryName": "花边配饰假发",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "提胸贴片",
        "goodsEnName": "Breast lift tapes",
        "goodsJpName": "胸当て板",
        "goodsNo": "260144",
        "id": 221855,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "",
        "similarGroup": "2611",
        "similarItem": "",
        "similarName": "",
        "type": 0,
        "updateTime": "2021-05-25 11:10:35"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "绢花",
    "goodsEnName": "Silk flower ",
    "goodsJpName": "",
    "goodsNo": "",
    "id": 57187,
    "niceCodeLists": "",
    "remark": "2018-6, 五方可接受",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": " Artificial flowers ",
    "similarGroup": "2606",
    "similarItem": "2606",
    "similarName": "假花",
    "type": 0,
    "updateTime": "2021-05-25 10:46:30"
  },
  {
    "category": "27",
    "categoryEnName": "Carpet, mats, wallpaper",
    "categoryName": "地板料覆盖物",
    "children": [
      {
        "category": "27",
        "categoryEnName": "Carpet, mats, wallpaper",
        "categoryName": "地板料覆盖物",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "方块地毯",
        "goodsEnName": "Square carpets",
        "goodsJpName": "方形のラグ",
        "goodsNo": "",
        "id": 109862,
        "niceCodeLists": "",
        "remark": "可接受、非标准 2017-4",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Carpet ",
        "similarGroup": "2701",
        "similarItem": "2701",
        "similarName": "地毯",
        "type": 0,
        "updateTime": "2021-05-25 11:12:47"
      },
      {
        "category": "27",
        "categoryEnName": "Carpet, mats, wallpaper",
        "categoryName": "地板料覆盖物",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "枕席",
        "goodsEnName": "Pillow mat",
        "goodsJpName": "枕の上に敷くござ",
        "goodsNo": "C270002",
        "id": 63067,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Mat ",
        "similarGroup": "2702",
        "similarItem": "2702",
        "similarName": "席类",
        "type": 0,
        "updateTime": "2021-05-25 10:33:44"
      },
      {
        "category": "27",
        "categoryEnName": "Carpet, mats, wallpaper",
        "categoryName": "地板料覆盖物",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "地毯底衬",
        "goodsEnName": "Carpet underlay",
        "goodsJpName": "カーペットの下敷き",
        "goodsNo": "270015",
        "id": 59294,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Pad and other articles for covering floor ",
        "similarGroup": "2703",
        "similarItem": "2703",
        "similarName": "垫及其他可移动铺地板用品",
        "type": 0,
        "updateTime": "2021-05-25 11:26:54"
      },
      {
        "category": "27",
        "categoryEnName": "Carpet, mats, wallpaper",
        "categoryName": "地板料覆盖物",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "壁纸",
        "goodsEnName": "Wallpaper ",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 62164,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Wallpaper, non-textile wall hanging, wall hangings, not of textile ",
        "similarGroup": "2704",
        "similarItem": "2704",
        "similarName": "墙纸,非纺织品墙帷及非纺织品壁挂",
        "type": 0,
        "updateTime": "2021-05-25 11:41:00"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "地毯底衬",
    "goodsEnName": "Carpet underlay",
    "goodsJpName": "カーペットの下敷き",
    "goodsNo": "270015",
    "id": 59294,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": " Pad and other articles for covering floor ",
    "similarGroup": "2703",
    "similarItem": "2703",
    "similarName": "垫及其他可移动铺地板用品",
    "type": 0,
    "updateTime": "2021-05-25 11:26:54"
  },
  {
    "category": "28",
    "categoryEnName": "Games and toys",
    "categoryName": "游戏运动器具",
    "children": [
      {
        "category": "28",
        "categoryEnName": "Games and toys",
        "categoryName": "游戏运动器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "游乐园用游戏机",
        "goodsEnName": "Amusement park games",
        "goodsJpName": "遊園地用ゲーム機",
        "goodsNo": "",
        "id": 56305,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Recreational facilities, recreational articles ",
        "similarGroup": "2801",
        "similarItem": "",
        "similarName": "娱乐器械,娱乐物品",
        "type": 0,
        "updateTime": "2021-05-25 10:46:48"
      },
      {
        "category": "28",
        "categoryEnName": "Games and toys",
        "categoryName": "游戏运动器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "儿童用多功能活动玩具",
        "goodsEnName": "Multifunctional activity toys for children",
        "goodsJpName": "子供用マルチファンクション玩具",
        "goodsNo": "",
        "id": 55332,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Toy ",
        "similarGroup": "2802",
        "similarItem": "",
        "similarName": "玩具",
        "type": 0,
        "updateTime": "2021-05-25 10:10:34"
      },
      {
        "category": "28",
        "categoryEnName": "Games and toys",
        "categoryName": "游戏运动器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "宾果游戏用标记片",
        "goodsEnName": "Bingo game marker",
        "goodsJpName": "ビンゴ用マーカー",
        "goodsNo": "",
        "id": 55759,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Chess, cards and assistant instrument (not including playing cards)",
        "similarGroup": "2803",
        "similarItem": "",
        "similarName": "棋,牌及辅助器材",
        "type": 0,
        "updateTime": "2021-05-25 11:05:42"
      },
      {
        "category": "28",
        "categoryEnName": "Games and toys",
        "categoryName": "游戏运动器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "网球球网中分带",
        "goodsEnName": "Tennis net median",
        "goodsJpName": "テニスネットの中でテープを分けます。",
        "goodsNo": "",
        "id": 56053,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Balls and equipments ",
        "similarGroup": "2804",
        "similarItem": "",
        "similarName": "球类及器材",
        "type": 0,
        "updateTime": "2021-05-25 11:04:18"
      },
      {
        "category": "28",
        "categoryEnName": "Games and toys",
        "categoryName": "游戏运动器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "有氧踏步机",
        "goodsEnName": "Aerobic treadmill",
        "goodsJpName": "有酸素ステップマシン",
        "goodsNo": "",
        "id": 55318,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Body-building facilities ",
        "similarGroup": "2805",
        "similarItem": "",
        "similarName": "健身器材",
        "type": 0,
        "updateTime": "2021-05-25 10:06:58"
      },
      {
        "category": "28",
        "categoryEnName": "Games and toys",
        "categoryName": "游戏运动器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "弓箭瞄用开放式准器",
        "goodsEnName": "Open collimator for bow and arrow aiming",
        "goodsJpName": "弓矢照準器",
        "goodsNo": "",
        "id": 56060,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Bowing facilities ",
        "similarGroup": "2806",
        "similarItem": "",
        "similarName": "射箭运动器材",
        "type": 0,
        "updateTime": "2021-05-25 11:04:18"
      },
      {
        "category": "28",
        "categoryEnName": "Games and toys",
        "categoryName": "游戏运动器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "拳击台",
        "goodsEnName": "Boxing ring",
        "goodsJpName": "ボクシングのリング",
        "goodsNo": "",
        "id": 56312,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Exercisers for gymnastics, weightlifting, track and field, skating and skiing and other exercisers belonging to this class ",
        "similarGroup": "2807",
        "similarItem": "",
        "similarName": "体操、举重、田径、冰雪及属于本类的其他运动器材",
        "type": 0,
        "updateTime": "2021-05-25 10:48:16"
      },
      {
        "category": "28",
        "categoryEnName": "Games and toys",
        "categoryName": "游戏运动器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "7",
        "flagBit": 0,
        "goodsChName": "塑胶跑道",
        "goodsEnName": "Plastic cement racetrack",
        "goodsJpName": "プラスチック製のトラック",
        "goodsNo": "C280084",
        "id": 83654,
        "niceCodeLists": "",
        "remark": "修改“塑料跑道”改为“塑胶跑道”",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Swimming pool and track ",
        "similarGroup": "2808",
        "similarItem": "2808（二）",
        "similarName": "游泳池及跑道",
        "type": 1,
        "updateTime": "2021-05-25 11:14:01"
      },
      {
        "category": "28",
        "categoryEnName": "Games and toys",
        "categoryName": "游戏运动器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "运动员用松香",
        "goodsEnName": "Rosin used by athletes",
        "goodsJpName": "ロージン（運動選手用）",
        "goodsNo": "280186",
        "id": 57600,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Sport protective equipment and skating boots ",
        "similarGroup": "2809",
        "similarItem": "2809（一）",
        "similarName": "运动防护器具及冰鞋",
        "type": 0,
        "updateTime": "2021-05-25 11:11:28"
      },
      {
        "category": "28",
        "categoryEnName": "Games and toys",
        "categoryName": "游戏运动器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "合成材料制圣诞树",
        "goodsEnName": "Christmas trees of synthetic material",
        "goodsJpName": "合成材料製クリスマスツリー",
        "goodsNo": "280006",
        "id": 60939,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Adornment for decorating Christmas tree ",
        "similarGroup": "2810",
        "similarItem": "2810",
        "similarName": "圣诞树用的装饰品",
        "type": 0,
        "updateTime": "2021-05-25 11:11:29"
      },
      {
        "category": "28",
        "categoryEnName": "Games and toys",
        "categoryName": "游戏运动器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "钓鱼用浮子",
        "goodsEnName": "Floats for fishing",
        "goodsJpName": "釣り用浮き",
        "goodsNo": "280069",
        "id": 56662,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Fishing tackle ",
        "similarGroup": "2811",
        "similarItem": "2811",
        "similarName": "钓具",
        "type": 0,
        "updateTime": "2021-05-25 11:11:30"
      },
      {
        "category": "28",
        "categoryEnName": "Games and toys",
        "categoryName": "游戏运动器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "7",
        "flagBit": 0,
        "goodsChName": "伪装掩蔽物（体育用品）",
        "goodsEnName": "Camouflage screens [sports articles] / screens (camouflage -) [sports articles]",
        "goodsJpName": "狩猟用偽装覆い布（運動用具）",
        "goodsNo": "280195",
        "id": 114307,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Single goods ",
        "similarGroup": "2812",
        "similarItem": "2812",
        "similarName": "单一商品",
        "type": 1,
        "updateTime": "2021-05-25 10:13:28"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "有氧踏步机",
    "goodsEnName": "Aerobic treadmill",
    "goodsJpName": "有酸素ステップマシン",
    "goodsNo": "",
    "id": 55318,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": " Body-building facilities ",
    "similarGroup": "2805",
    "similarItem": "",
    "similarName": "健身器材",
    "type": 0,
    "updateTime": "2021-05-25 10:06:58"
  },
  {
    "category": "29",
    "categoryEnName": "Cooked vegetables/fruits, meat/egg/cream",
    "categoryName": "蔬果肉蛋奶油",
    "children": [
      {
        "category": "29",
        "categoryEnName": "Cooked vegetables/fruits, meat/egg/cream",
        "categoryName": "蔬果肉蛋奶油",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "牛蛙肉",
        "goodsEnName": "Bullfrog meat",
        "goodsJpName": "カエルの肉",
        "goodsNo": "",
        "id": 55850,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Meat, Not-alive fowl, game, gravy ",
        "similarGroup": "2901",
        "similarItem": "",
        "similarName": "肉,非活的家禽,野味,肉汁",
        "type": 0,
        "updateTime": "2021-05-25 10:55:36"
      },
      {
        "category": "29",
        "categoryEnName": "Cooked vegetables/fruits, meat/egg/cream",
        "categoryName": "蔬果肉蛋奶油",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "鲑鱼子酱",
        "goodsEnName": "Salmon caviar",
        "goodsJpName": "さけのペースト",
        "goodsNo": "",
        "id": 55423,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Not-alive aquatic product ",
        "similarGroup": "2902",
        "similarItem": "",
        "similarName": "非活水产品",
        "type": 0,
        "updateTime": "2021-05-25 10:55:49"
      },
      {
        "category": "29",
        "categoryEnName": "Cooked vegetables/fruits, meat/egg/cream",
        "categoryName": "蔬果肉蛋奶油",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "蜗牛罐头",
        "goodsEnName": "Snail, tinned [canned (Am.)]",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 63592,
        "niceCodeLists": "",
        "remark": "可接受，非标准",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Canned food (soft packed food is not included, belonging to raw material product ",
        "similarGroup": "2903",
        "similarItem": "2903",
        "similarName": "罐头食品（软包装食品不包括在内,随原料制成品归组）",
        "type": 0,
        "updateTime": "2021-05-25 11:40:52"
      },
      {
        "category": "29",
        "categoryEnName": "Cooked vegetables/fruits, meat/egg/cream",
        "categoryName": "蔬果肉蛋奶油",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "桂花",
        "goodsEnName": "Sweet-scented osmanthus",
        "goodsJpName": "モクセイを原材料とするソース",
        "goodsNo": "C290028",
        "id": 56536,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Souse fruit, dry-cure fruit and product ",
        "similarGroup": "2904",
        "similarItem": "2904（一）",
        "similarName": "腌渍、干制水果及制品",
        "type": 0,
        "updateTime": "2021-05-25 10:36:08"
      },
      {
        "category": "29",
        "categoryEnName": "Cooked vegetables/fruits, meat/egg/cream",
        "categoryName": "蔬果肉蛋奶油",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "干海带",
        "goodsEnName": "Dried kelp",
        "goodsJpName": "乾燥昆布",
        "goodsNo": "",
        "id": 56130,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Pickles, dried vegetables ",
        "similarGroup": "2905",
        "similarItem": "",
        "similarName": "腌制、干制蔬菜",
        "type": 0,
        "updateTime": "2021-05-25 11:14:09"
      },
      {
        "category": "29",
        "categoryEnName": "Cooked vegetables/fruits, meat/egg/cream",
        "categoryName": "蔬果肉蛋奶油",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "鸡蛋",
        "goodsEnName": "Egg",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 66287,
        "niceCodeLists": "",
        "remark": "可接受，非标准",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Egg product ",
        "similarGroup": "2906",
        "similarItem": "2906",
        "similarName": "蛋品",
        "type": 0,
        "updateTime": "2021-05-25 10:43:14"
      },
      {
        "category": "29",
        "categoryEnName": "Cooked vegetables/fruits, meat/egg/cream",
        "categoryName": "蔬果肉蛋奶油",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "乳清粉",
        "goodsEnName": "Whey powder",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 57397,
        "niceCodeLists": "",
        "remark": "可接受，非标准",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Milk and dairy product ",
        "similarGroup": "2907",
        "similarItem": "2907",
        "similarName": "奶及乳制品",
        "type": 0,
        "updateTime": "2021-05-25 10:59:24"
      },
      {
        "category": "29",
        "categoryEnName": "Cooked vegetables/fruits, meat/egg/cream",
        "categoryName": "蔬果肉蛋奶油",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "色拉油",
        "goodsEnName": "Salad oil",
        "goodsJpName": "サラダ油",
        "goodsNo": "",
        "id": 56193,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Edible oil and fat ",
        "similarGroup": "2908",
        "similarItem": "",
        "similarName": "食用油脂",
        "type": 0,
        "updateTime": "2021-05-25 10:59:26"
      },
      {
        "category": "29",
        "categoryEnName": "Cooked vegetables/fruits, meat/egg/cream",
        "categoryName": "蔬果肉蛋奶油",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "烹饪用藻酸盐",
        "goodsEnName": "Alginates for culinary purposes",
        "goodsJpName": "料理用アルギン酸塩",
        "goodsNo": "290116",
        "id": 58384,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Edible pectin ",
        "similarGroup": "2910",
        "similarItem": "2910",
        "similarName": "食用果胶",
        "type": 0,
        "updateTime": "2021-05-25 11:27:58"
      },
      {
        "category": "29",
        "categoryEnName": "Cooked vegetables/fruits, meat/egg/cream",
        "categoryName": "蔬果肉蛋奶油",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "加工过的核桃",
        "goodsEnName": " Processed walnut",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 57208,
        "niceCodeLists": "",
        "remark": "可接受，非标准",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Processed nut ",
        "similarGroup": "2911",
        "similarItem": "2911",
        "similarName": "加工过的坚果",
        "type": 0,
        "updateTime": "2021-05-25 10:58:51"
      },
      {
        "category": "29",
        "categoryEnName": "Cooked vegetables/fruits, meat/egg/cream",
        "categoryName": "蔬果肉蛋奶油",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "干羊肚菌",
        "goodsEnName": "Dried morchella",
        "goodsJpName": "羊の胃菌",
        "goodsNo": "",
        "id": 55248,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Dried mushroom ",
        "similarGroup": "2912",
        "similarItem": "",
        "similarName": "菌类干制品",
        "type": 0,
        "updateTime": "2021-05-25 10:02:31"
      },
      {
        "category": "29",
        "categoryEnName": "Cooked vegetables/fruits, meat/egg/cream",
        "categoryName": "蔬果肉蛋奶油",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "腐竹",
        "goodsEnName": "Dried bean milk cream in tight rolls",
        "goodsJpName": "ロールで乾燥された豆乳クリーム",
        "goodsNo": "C290091",
        "id": 91158,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Food protein, bean curd product ",
        "similarGroup": "2913",
        "similarItem": "2913",
        "similarName": "食物蛋白,豆腐制品",
        "type": 0,
        "updateTime": "2021-05-25 10:34:58"
      },
      {
        "category": "29",
        "categoryEnName": "Cooked vegetables/fruits, meat/egg/cream",
        "categoryName": "蔬果肉蛋奶油",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "天然或人造的香肠肠衣",
        "goodsEnName": "Sausage casings, natural or artificial",
        "goodsJpName": "天然又は人工のソーセージ用ケ\nーシング",
        "goodsNo": "290226",
        "id": 189074,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Sausage casings ",
        "similarGroup": "2914",
        "similarItem": "2914",
        "similarName": "肠衣",
        "type": 0,
        "updateTime": "2021-05-25 11:07:38"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "干羊肚菌",
    "goodsEnName": "Dried morchella",
    "goodsJpName": "羊の胃菌",
    "goodsNo": "",
    "id": 55248,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": " Dried mushroom ",
    "similarGroup": "2912",
    "similarItem": "",
    "similarName": "菌类干制品",
    "type": 0,
    "updateTime": "2021-05-25 10:02:31"
  },
  {
    "category": "3",
    "categoryEnName": "Cosmetics, cleaning products",
    "categoryName": "洁护品化妆品",
    "children": [
      {
        "category": "3",
        "categoryEnName": "Cosmetics, cleaning products",
        "categoryName": "洁护品化妆品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "洗衣用浆粉",
        "goodsEnName": "Laundry starch",
        "goodsJpName": "洗濯用でん粉のり",
        "goodsNo": "030010",
        "id": 60036,
        "niceCodeLists": "",
        "remark": "增加注10",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Soaps, fancy soaps and cleaning articles for other people use, bleachers for laundry and other materials",
        "similarGroup": "0301",
        "similarItem": "0301Z3",
        "similarName": "肥皂,香皂及其他人用洗洁物品,洗衣用漂白剂及其他物料",
        "type": 0,
        "updateTime": "2021-05-25 11:33:34"
      },
      {
        "category": "3",
        "categoryEnName": "Cosmetics, cleaning products",
        "categoryName": "洁护品化妆品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "家具装饰品用清洁剂",
        "goodsEnName": "Cleaning preparations for furniture decoration",
        "goodsJpName": "家具用アクセサリー",
        "goodsNo": "",
        "id": 55696,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Preparations for cleaning and decontaminating",
        "similarGroup": "0302",
        "similarItem": "",
        "similarName": "清洁、去渍用制剂",
        "type": 0,
        "updateTime": "2021-05-25 11:05:39"
      },
      {
        "category": "3",
        "categoryEnName": "Cosmetics, cleaning products",
        "categoryName": "洁护品化妆品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "吉他琴体上光剂",
        "goodsEnName": "Polish for guitar body",
        "goodsJpName": "ギターのオルガン",
        "goodsNo": "",
        "id": 55703,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Preparations for polishing and rubbing",
        "similarGroup": "0303",
        "similarItem": "",
        "similarName": "抛光、擦亮制剂",
        "type": 0,
        "updateTime": "2021-05-25 11:05:40"
      },
      {
        "category": "3",
        "categoryEnName": "Cosmetics, cleaning products",
        "categoryName": "洁护品化妆品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "研磨膏",
        "goodsEnName": "Abrasive paste",
        "goodsJpName": "研摩クリーム",
        "goodsNo": "C030033",
        "id": 60561,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Materials and preparations for grinding",
        "similarGroup": "0304",
        "similarItem": "0304",
        "similarName": "研磨用材料及其制剂",
        "type": 0,
        "updateTime": "2021-05-25 10:57:43"
      },
      {
        "category": "3",
        "categoryEnName": "Cosmetics, cleaning products",
        "categoryName": "洁护品化妆品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "安神用香精油",
        "goodsEnName": "Calming essence",
        "goodsJpName": "落ち着かせるエッセンス",
        "goodsNo": "C030070",
        "id": 56760,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Perfumes, essential oils",
        "similarGroup": "0305",
        "similarItem": "0305",
        "similarName": "香料,香精油",
        "type": 0,
        "updateTime": "2021-05-25 10:52:33"
      },
      {
        "category": "3",
        "categoryEnName": "Cosmetics, cleaning products",
        "categoryName": "洁护品化妆品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "2",
        "flagBit": 0,
        "goodsChName": "指甲油",
        "goodsEnName": "Nail varnish ",
        "goodsJpName": "ネイルエナメル",
        "goodsNo": "030032",
        "id": 56788,
        "niceCodeLists": "",
        "remark": "增加注7",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Cosmetics (not including cosmetics for animals)",
        "similarGroup": "0306",
        "similarItem": "0306",
        "similarName": "化妆品（不包括动物用化妆品）",
        "type": 0,
        "updateTime": "2021-05-25 10:52:34"
      },
      {
        "category": "3",
        "categoryEnName": "Cosmetics, cleaning products",
        "categoryName": "洁护品化妆品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "非医用漱口剂",
        "goodsEnName": "Mouthwashes, not for medical purposes",
        "goodsJpName": "口内洗浄剤（医療用のものを除く。）",
        "goodsNo": "030031",
        "id": 57411,
        "niceCodeLists": "",
        "remark": "增加备注",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Toothpastes, preparations for water toothpick",
        "similarGroup": "0307",
        "similarItem": "0307",
        "similarName": "牙膏,洗牙用制剂",
        "type": 0,
        "updateTime": "2021-05-25 10:34:08"
      },
      {
        "category": "3",
        "categoryEnName": "Cosmetics, cleaning products",
        "categoryName": "洁护品化妆品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "芳香松果（熏香用）",
        "goodsEnName": "Fragrant pinecones (for incense)",
        "goodsJpName": "芳香松果（燻製用）",
        "goodsNo": "",
        "id": 55689,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Fumets",
        "similarGroup": "0308",
        "similarItem": "",
        "similarName": "熏料",
        "type": 0,
        "updateTime": "2021-05-25 11:05:39"
      },
      {
        "category": "3",
        "categoryEnName": "Cosmetics, cleaning products",
        "categoryName": "洁护品化妆品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "2,18",
        "flagBit": 0,
        "goodsChName": "动物用化妆品",
        "goodsEnName": "Cosmetics for animals",
        "goodsJpName": "愛玩動物用化粧品",
        "goodsNo": "030063",
        "id": 65650,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Cosmetics for animals",
        "similarGroup": "0309",
        "similarItem": "0309",
        "similarName": "动物用化妆品",
        "type": 1,
        "updateTime": "2021-05-25 10:34:09"
      },
      {
        "category": "3",
        "categoryEnName": "Cosmetics, cleaning products",
        "categoryName": "洁护品化妆品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "2",
        "flagBit": 0,
        "goodsChName": "家用芳香剂",
        "goodsEnName": "Domestic aromatics",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 71439,
        "niceCodeLists": "",
        "remark": "可接受，非标准",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Indoor fragrancing preparations",
        "similarGroup": "0310",
        "similarItem": "0310",
        "similarName": "室内芳香剂",
        "type": 0,
        "updateTime": "2021-05-25 10:20:11"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "芳香松果（熏香用）",
    "goodsEnName": "Fragrant pinecones (for incense)",
    "goodsJpName": "芳香松果（燻製用）",
    "goodsNo": "",
    "id": 55689,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": "Fumets",
    "similarGroup": "0308",
    "similarItem": "",
    "similarName": "熏料",
    "type": 0,
    "updateTime": "2021-05-25 11:05:39"
  },
  {
    "category": "30",
    "categoryEnName": "Coffee, pastry, condiments",
    "categoryName": "咖啡面点调料",
    "children": [
      {
        "category": "30",
        "categoryEnName": "Coffee, pastry, condiments",
        "categoryName": "咖啡面点调料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "巧克力饮料",
        "goodsEnName": "Beverages (chocolate-based -) / chocolate-based beverages",
        "goodsJpName": "チョコレート飲料",
        "goodsNo": "300151",
        "id": 62934,
        "niceCodeLists": "",
        "remark": "修改注1 删除注3",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Coffee, coffee substitute, cocoa ",
        "similarGroup": "3001",
        "similarItem": "3001",
        "similarName": "咖啡,咖啡代用品,可可",
        "type": 0,
        "updateTime": "2021-05-25 10:35:01"
      },
      {
        "category": "30",
        "categoryEnName": "Coffee, pastry, condiments",
        "categoryName": "咖啡面点调料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "荞麦茶",
        "goodsEnName": "Buckwheat tea",
        "goodsJpName": "そば茶",
        "goodsNo": "",
        "id": 56235,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Tea and tea substitute ",
        "similarGroup": "3002",
        "similarItem": "",
        "similarName": "茶、茶饮料",
        "type": 0,
        "updateTime": "2021-05-25 11:14:18"
      },
      {
        "category": "30",
        "categoryEnName": "Coffee, pastry, condiments",
        "categoryName": "咖啡面点调料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "烹饪用葡萄糖",
        "goodsEnName": "Glucose for culinary purposes",
        "goodsJpName": "料理用グルコース",
        "goodsNo": "300077",
        "id": 61170,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Sugar ",
        "similarGroup": "3003",
        "similarItem": "3003",
        "similarName": "糖",
        "type": 0,
        "updateTime": "2021-05-25 11:12:19"
      },
      {
        "category": "30",
        "categoryEnName": "Coffee, pastry, condiments",
        "categoryName": "咖啡面点调料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "皮糖",
        "goodsEnName": "Pitang (a kind of sweets made from sugar and some starch)",
        "goodsJpName": "砂糖といくつかのでんぷんから作られた菓子の一種）",
        "goodsNo": "C300015",
        "id": 56655,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Confectionery, south sugar, sugar ",
        "similarGroup": "3004",
        "similarItem": "3004",
        "similarName": "糖果,南糖,糖",
        "type": 0,
        "updateTime": "2021-05-25 10:39:24"
      },
      {
        "category": "30",
        "categoryEnName": "Coffee, pastry, condiments",
        "categoryName": "咖啡面点调料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "虫草鸡精",
        "goodsEnName": "Chinese caterpillar fungus-supplementde chicken essence",
        "goodsJpName": "冬虫夏草製エッセンス",
        "goodsNo": "C300027",
        "id": 63872,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "",
        "similarGroup": "3005",
        "similarItem": "3005",
        "similarName": "蜂蜜,蜂王浆等营养食品",
        "type": 0,
        "updateTime": "2021-05-25 10:39:26"
      },
      {
        "category": "30",
        "categoryEnName": "Coffee, pastry, condiments",
        "categoryName": "咖啡面点调料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "洋葱或奶酪饼干",
        "goodsEnName": "Onion or cheese biscuit",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 59651,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Bread, cake ",
        "similarGroup": "3006",
        "similarItem": "3006",
        "similarName": "面包,糕点",
        "type": 0,
        "updateTime": "2021-05-25 10:16:17"
      },
      {
        "category": "30",
        "categoryEnName": "Coffee, pastry, condiments",
        "categoryName": "咖啡面点调料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "红豆粥",
        "goodsEnName": "Red bean porridge",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 58860,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Convenience food ",
        "similarGroup": "3007",
        "similarItem": "3007",
        "similarName": "方便食品",
        "type": 0,
        "updateTime": "2021-05-25 10:59:21"
      },
      {
        "category": "30",
        "categoryEnName": "Coffee, pastry, condiments",
        "categoryName": "咖啡面点调料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "米粉（粉状）",
        "goodsEnName": "Rice flour (powder)",
        "goodsJpName": "米粉（粉末）",
        "goodsNo": "C300134",
        "id": 60414,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Rice, wheat flour (including both refined and coarse grains)",
        "similarGroup": "3008",
        "similarItem": "3008",
        "similarName": "米,面粉（包括五谷杂粮）",
        "type": 0,
        "updateTime": "2021-05-25 10:36:41"
      },
      {
        "category": "30",
        "categoryEnName": "Coffee, pastry, condiments",
        "categoryName": "咖啡面点调料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "蛋糕面团",
        "goodsEnName": "Cake dough",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 57362,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Noodle and rice product and flour product ",
        "similarGroup": "3009",
        "similarItem": "3009",
        "similarName": "面条及米面制品",
        "type": 0,
        "updateTime": "2021-05-25 11:13:03"
      },
      {
        "category": "30",
        "categoryEnName": "Coffee, pastry, condiments",
        "categoryName": "咖啡面点调料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "加工过的爆米花",
        "goodsEnName": "Processed popcorn",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 62906,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Bulking food ",
        "similarGroup": "3010",
        "similarItem": "3010",
        "similarName": "谷物膨化食品",
        "type": 0,
        "updateTime": "2021-05-25 11:49:13"
      },
      {
        "category": "30",
        "categoryEnName": "Coffee, pastry, condiments",
        "categoryName": "咖啡面点调料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "烹饪食品用增稠剂",
        "goodsEnName": "Thickening agents for cooking foodstuffs",
        "goodsJpName": "食材調理用増粘剤",
        "goodsNo": "300050",
        "id": 59266,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Edible starch and their product ",
        "similarGroup": "3012",
        "similarItem": "3012",
        "similarName": "食用淀粉及其制品",
        "type": 0,
        "updateTime": "2021-05-25 10:36:45"
      },
      {
        "category": "30",
        "categoryEnName": "Coffee, pastry, condiments",
        "categoryName": "咖啡面点调料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "素食冰淇淋",
        "goodsEnName": "Vegan ice cream",
        "goodsJpName": "ベジタリアンアイスクリーム",
        "goodsNo": "",
        "id": 55360,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Edible ice, icy product ",
        "similarGroup": "3013",
        "similarItem": "",
        "similarName": "食用冰,冰制品",
        "type": 0,
        "updateTime": "2021-05-25 10:49:33"
      },
      {
        "category": "30",
        "categoryEnName": "Coffee, pastry, condiments",
        "categoryName": "咖啡面点调料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "食物防腐盐",
        "goodsEnName": "Salt for preserving foodstuffs",
        "goodsJpName": "食品保存用の塩",
        "goodsNo": "300014",
        "id": 58657,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Table salt ",
        "similarGroup": "3014",
        "similarItem": "3014",
        "similarName": "食盐",
        "type": 0,
        "updateTime": "2021-05-25 11:28:14"
      },
      {
        "category": "30",
        "categoryEnName": "Coffee, pastry, condiments",
        "categoryName": "咖啡面点调料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "巴萨米克醋（意大利黑醋）",
        "goodsEnName": "Barsmic vinegar (Italian black vinegar)",
        "goodsJpName": "バルサミック酢（イタリア黒酢）",
        "goodsNo": "",
        "id": 56081,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Soy sauce, vinegar ",
        "similarGroup": "3015",
        "similarItem": "",
        "similarName": "酱油,醋",
        "type": 0,
        "updateTime": "2021-05-25 11:04:19"
      },
      {
        "category": "30",
        "categoryEnName": "Coffee, pastry, condiments",
        "categoryName": "咖啡面点调料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "胡椒粉（调味品）",
        "goodsEnName": "Pepper [condiments]",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 57089,
        "niceCodeLists": "",
        "remark": "可接受，非标准",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Condiment such as mustard, aginomoto, sauce, jam ",
        "similarGroup": "3016",
        "similarItem": "3016",
        "similarName": "芥末,味精,沙司,酱等调味品",
        "type": 0,
        "updateTime": "2021-05-25 11:49:09"
      },
      {
        "category": "30",
        "categoryEnName": "Coffee, pastry, condiments",
        "categoryName": "咖啡面点调料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "酵母粉",
        "goodsEnName": "Yeast powder ",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 90857,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Yeast ",
        "similarGroup": "3017",
        "similarItem": "3017",
        "similarName": "酵母",
        "type": 0,
        "updateTime": "2021-05-25 11:00:47"
      },
      {
        "category": "30",
        "categoryEnName": "Coffee, pastry, condiments",
        "categoryName": "咖啡面点调料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "除香精油外的蛋糕用调味品",
        "goodsEnName": "Flavourings, other than essential oils, for cakes",
        "goodsJpName": "精油以外の菓子用香味料",
        "goodsNo": "300070",
        "id": 97969,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Flavouring essence, perfume ",
        "similarGroup": "3018",
        "similarItem": "3016",
        "similarName": "食用香精,香料",
        "type": 0,
        "updateTime": "2021-05-25 10:59:17"
      },
      {
        "category": "30",
        "categoryEnName": "Coffee, pastry, condiments",
        "categoryName": "咖啡面点调料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "烹饪用谷蛋白添加剂",
        "goodsEnName": "Gluten additives for culinary purposes",
        "goodsJpName": "料理用グルテン添加物",
        "goodsNo": "300216",
        "id": 108133,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Single goods ",
        "similarGroup": "3019",
        "similarItem": "3019Z3",
        "similarName": "单一商品",
        "type": 0,
        "updateTime": "2021-05-25 11:32:42"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "素食冰淇淋",
    "goodsEnName": "Vegan ice cream",
    "goodsJpName": "ベジタリアンアイスクリーム",
    "goodsNo": "",
    "id": 55360,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": " Edible ice, icy product ",
    "similarGroup": "3013",
    "similarItem": "",
    "similarName": "食用冰,冰制品",
    "type": 0,
    "updateTime": "2021-05-25 10:49:33"
  },
  {
    "category": "31",
    "categoryEnName": "Fresh fruit, vegetables, flowers",
    "categoryName": "农园林水产品",
    "children": [
      {
        "category": "31",
        "categoryEnName": "Fresh fruit, vegetables, flowers",
        "categoryName": "农园林水产品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "榴莲树苗",
        "goodsEnName": "Durian seedlings",
        "goodsJpName": "ドリアンの苗木",
        "goodsNo": "",
        "id": 55878,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Unprocessed forestry product ",
        "similarGroup": "3101",
        "similarItem": "",
        "similarName": "未加工的林业产品",
        "type": 0,
        "updateTime": "2021-05-25 11:07:48"
      },
      {
        "category": "31",
        "categoryEnName": "Fresh fruit, vegetables, flowers",
        "categoryName": "农园林水产品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "未加工的高粱",
        "goodsEnName": "Unprocessed sorghum",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 59679,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Unprocessed grain and agricultural product (not including vegetable, seed)",
        "similarGroup": "3102",
        "similarItem": "3102",
        "similarName": "未加工的谷物及农产品（不包括蔬菜,种子）",
        "type": 0,
        "updateTime": "2021-05-25 11:14:20"
      },
      {
        "category": "31",
        "categoryEnName": "Fresh fruit, vegetables, flowers",
        "categoryName": "农园林水产品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "新鲜草本植物",
        "goodsEnName": "Fresh herbs",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 56431,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Flowers and plants, horticulture product, grass and trees ",
        "similarGroup": "3103",
        "similarItem": "3103",
        "similarName": "花卉,园艺产品,草本植物",
        "type": 0,
        "updateTime": "2021-05-25 11:48:02"
      },
      {
        "category": "31",
        "categoryEnName": "Fresh fruit, vegetables, flowers",
        "categoryName": "农园林水产品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "磷虾（活的）",
        "goodsEnName": "Krill, live",
        "goodsJpName": "オキアミ",
        "goodsNo": "",
        "id": 55430,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Living creature ",
        "similarGroup": "3104",
        "similarItem": "",
        "similarName": "活动物",
        "type": 0,
        "updateTime": "2021-05-25 10:55:50"
      },
      {
        "category": "31",
        "categoryEnName": "Fresh fruit, vegetables, flowers",
        "categoryName": "农园林水产品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "新鲜棕榈果",
        "goodsEnName": "Fresh palm fruit",
        "goodsJpName": "新鮮なパルミチン",
        "goodsNo": "",
        "id": 55444,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Unprocessed fruit and dried fruit ",
        "similarGroup": "3105",
        "similarItem": "",
        "similarName": "未加工的水果及干果",
        "type": 0,
        "updateTime": "2021-05-25 10:55:50"
      },
      {
        "category": "31",
        "categoryEnName": "Fresh fruit, vegetables, flowers",
        "categoryName": "农园林水产品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "新鲜海带",
        "goodsEnName": "Fresh kelp",
        "goodsJpName": "新鮮昆布",
        "goodsNo": "",
        "id": 55353,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Fresh vegetable ",
        "similarGroup": "3106",
        "similarItem": "",
        "similarName": "新鲜蔬菜",
        "type": 0,
        "updateTime": "2021-05-25 11:38:16"
      },
      {
        "category": "31",
        "categoryEnName": "Fresh fruit, vegetables, flowers",
        "categoryName": "农园林水产品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "园艺用种子",
        "goodsEnName": "Seeds for gardening",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 75107,
        "niceCodeLists": "",
        "remark": "可接受，非标准",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Seed ",
        "similarGroup": "3107",
        "similarItem": "3107",
        "similarName": "种子",
        "type": 0,
        "updateTime": "2021-05-25 11:14:37"
      },
      {
        "category": "31",
        "categoryEnName": "Fresh fruit, vegetables, flowers",
        "categoryName": "农园林水产品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "宠物猫食用的木天蓼粉",
        "goodsEnName": "Powder of Polygonum viviparum for pet cat ",
        "goodsJpName": "ペット猫用のタデ",
        "goodsNo": "",
        "id": 55857,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Animal forage ",
        "similarGroup": "3108",
        "similarItem": "",
        "similarName": "动物饲料",
        "type": 0,
        "updateTime": "2021-05-25 10:55:36"
      },
      {
        "category": "31",
        "categoryEnName": "Fresh fruit, vegetables, flowers",
        "categoryName": "农园林水产品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "酿酒麦芽",
        "goodsEnName": "Malt for brewing and distilling",
        "goodsJpName": "醸造用及び蒸留用麦芽",
        "goodsNo": "310084",
        "id": 106334,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Malt ",
        "similarGroup": "3109",
        "similarItem": "3109",
        "similarName": "麦芽",
        "type": 1,
        "updateTime": "2021-05-25 10:39:07"
      },
      {
        "category": "31",
        "categoryEnName": "Fresh fruit, vegetables, flowers",
        "categoryName": "农园林水产品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "猫砂",
        "goodsEnName": "Cat litter",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 90640,
        "niceCodeLists": "",
        "remark": "可接受，非标准",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Litter for animals, etc.",
        "similarGroup": "3110",
        "similarItem": "3110",
        "similarName": "动物栖息用干草等制品",
        "type": 0,
        "updateTime": "2021-05-25 11:15:37"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "新鲜海带",
    "goodsEnName": "Fresh kelp",
    "goodsJpName": "新鮮昆布",
    "goodsNo": "",
    "id": 55353,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": " Fresh vegetable ",
    "similarGroup": "3106",
    "similarItem": "",
    "similarName": "新鲜蔬菜",
    "type": 0,
    "updateTime": "2021-05-25 11:38:16"
  },
  {
    "category": "32",
    "categoryEnName": "Beer, beverages, syrups",
    "categoryName": "啤酒饮料糖浆",
    "children": [
      {
        "category": "32",
        "categoryEnName": "Beer, beverages, syrups",
        "categoryName": "啤酒饮料糖浆",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "低酒精啤酒",
        "goodsEnName": "Low alcohol beer",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 58251,
        "niceCodeLists": "",
        "remark": "可接受、非标准 2017-4",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Beer ",
        "similarGroup": "3201",
        "similarItem": "3201",
        "similarName": "啤酒",
        "type": 0,
        "updateTime": "2021-05-25 11:15:32"
      },
      {
        "category": "32",
        "categoryEnName": "Beer, beverages, syrups",
        "categoryName": "啤酒饮料糖浆",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "芦荟汁",
        "goodsEnName": "Aloe juice",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 56550,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Non-alcoholic beverage ",
        "similarGroup": "3202",
        "similarItem": "3202",
        "similarName": "不含酒精饮料",
        "type": 0,
        "updateTime": "2021-05-25 11:01:21"
      },
      {
        "category": "32",
        "categoryEnName": "Beer, beverages, syrups",
        "categoryName": "啤酒饮料糖浆",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "饮料用麦芽糖浆",
        "goodsEnName": "Drink with maltose syrup",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 90913,
        "niceCodeLists": "",
        "remark": "可接受、非标准 2017-4",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Syrup and other preparation for drink ",
        "similarGroup": "3203",
        "similarItem": "3203",
        "similarName": "糖浆及其他供饮料用的制剂",
        "type": 0,
        "updateTime": "2021-05-25 11:02:03"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "芦荟汁",
    "goodsEnName": "Aloe juice",
    "goodsJpName": "",
    "goodsNo": "",
    "id": 56550,
    "niceCodeLists": "",
    "remark": "2018, 五方可接受",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": " Non-alcoholic beverage ",
    "similarGroup": "3202",
    "similarItem": "3202",
    "similarName": "不含酒精饮料",
    "type": 0,
    "updateTime": "2021-05-25 11:01:21"
  },
  {
    "category": "33",
    "categoryEnName": "Alcohol",
    "categoryName": "烈酒含酒饮料",
    "children": [
      {
        "category": "33",
        "categoryEnName": "Alcohol",
        "categoryName": "烈酒含酒饮料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "阿夸维特酒",
        "goodsEnName": "Aquavite",
        "goodsJpName": "アーチェリー",
        "goodsNo": "",
        "id": 55346,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Alcoholic beverages (except beers)",
        "similarGroup": "3301",
        "similarItem": "",
        "similarName": "含酒精的饮料（啤酒除外）",
        "type": 0,
        "updateTime": "2021-05-25 10:02:51"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "阿夸维特酒",
    "goodsEnName": "Aquavite",
    "goodsJpName": "アーチェリー",
    "goodsNo": "",
    "id": 55346,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": " Alcoholic beverages (except beers)",
    "similarGroup": "3301",
    "similarItem": "",
    "similarName": "含酒精的饮料（啤酒除外）",
    "type": 0,
    "updateTime": "2021-05-25 10:02:51"
  },
  {
    "category": "34",
    "categoryEnName": "Tobacco, smoking articles",
    "categoryName": "烟草烟具火柴",
    "children": [
      {
        "category": "34",
        "categoryEnName": "Tobacco, smoking articles",
        "categoryName": "烟草烟具火柴",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "无烟烟草",
        "goodsEnName": "Smokeless tobacco",
        "goodsJpName": "無煙たばこ",
        "goodsNo": "",
        "id": 66840,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Tobacco and tobacco product ",
        "similarGroup": "3401",
        "similarItem": "3401",
        "similarName": "烟草及其制品",
        "type": 0,
        "updateTime": "2021-05-25 10:59:50"
      },
      {
        "category": "34",
        "categoryEnName": "Tobacco, smoking articles",
        "categoryName": "烟草烟具火柴",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "雪茄管",
        "goodsEnName": "Cigar tube",
        "goodsJpName": "葉巻の管",
        "goodsNo": "",
        "id": 56361,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Smoking paraphernalia ",
        "similarGroup": "3402",
        "similarItem": "",
        "similarName": "烟具",
        "type": 0,
        "updateTime": "2021-05-25 11:00:36"
      },
      {
        "category": "34",
        "categoryEnName": "Tobacco, smoking articles",
        "categoryName": "烟草烟具火柴",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "安全火柴",
        "goodsEnName": "Safety match",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 58090,
        "niceCodeLists": "",
        "remark": "可接受，非标准",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Match ",
        "similarGroup": "3403",
        "similarItem": "3403",
        "similarName": "火柴",
        "type": 0,
        "updateTime": "2021-05-25 11:14:42"
      },
      {
        "category": "34",
        "categoryEnName": "Tobacco, smoking articles",
        "categoryName": "烟草烟具火柴",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "打火石",
        "goodsEnName": "Firestones",
        "goodsJpName": "ライター石",
        "goodsNo": "340027",
        "id": 57635,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Cigarette lighters ",
        "similarGroup": "3404",
        "similarItem": "3404",
        "similarName": "吸烟用打火机",
        "type": 0,
        "updateTime": "2021-05-25 10:54:07"
      },
      {
        "category": "34",
        "categoryEnName": "Tobacco, smoking articles",
        "categoryName": "烟草烟具火柴",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "香烟滤嘴",
        "goodsEnName": "Cigarette filter",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 60155,
        "niceCodeLists": "",
        "remark": "可接受、非标准 2017-4",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Smoke paper, filter tip ",
        "similarGroup": "3405",
        "similarItem": "3405",
        "similarName": "烟纸,过滤嘴",
        "type": 0,
        "updateTime": "2021-05-25 11:02:04"
      },
      {
        "category": "34",
        "categoryEnName": "Tobacco, smoking articles",
        "categoryName": "烟草烟具火柴",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "18",
        "flagBit": 0,
        "goodsChName": "除香精油外的烟草用调味品",
        "goodsEnName": "Flavorings, other than essential oils, for tobacco / Flavourings, other than essential oils, for tobacco",
        "goodsJpName": "たばこ用香味料（精油を除く）",
        "goodsNo": "340042",
        "id": 89667,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Flavorings,  for use in electronic cigarettes ",
        "similarGroup": "3406",
        "similarItem": "3406",
        "similarName": "香烟用调味品",
        "type": 1,
        "updateTime": "2021-05-25 10:49:57"
      },
      {
        "category": "34",
        "categoryEnName": "Tobacco, smoking articles",
        "categoryName": "烟草烟具火柴",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "用作传统香烟替代品的电子香烟",
        "goodsEnName": "E-cigarettes as a substitute for traditional cigarettes",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 152590,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "",
        "similarGroup": "3407",
        "similarItem": "3407",
        "similarName": "",
        "type": 0,
        "updateTime": "2021-05-25 10:06:55"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "雪茄管",
    "goodsEnName": "Cigar tube",
    "goodsJpName": "葉巻の管",
    "goodsNo": "",
    "id": 56361,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": " Smoking paraphernalia ",
    "similarGroup": "3402",
    "similarItem": "",
    "similarName": "烟具",
    "type": 0,
    "updateTime": "2021-05-25 11:00:36"
  },
  {
    "category": "35",
    "categoryEnName": "Advertising, office affairs",
    "categoryName": " 广告商业事务",
    "children": [
      {
        "category": "35",
        "categoryEnName": "Advertising, office affairs",
        "categoryName": " 广告商业事务",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "通过发放优惠券推广他人的商品和服务",
        "goodsEnName": "Promoting others' goods and services by issuing coupons",
        "goodsJpName": "クーポンを配布することで、他人の商品やサービスを広めることができます。",
        "goodsNo": "",
        "id": 55780,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Advertisement ",
        "similarGroup": "3501",
        "similarItem": "",
        "similarName": "广告",
        "type": 0,
        "updateTime": "2021-05-25 11:05:43"
      },
      {
        "category": "35",
        "categoryEnName": "Advertising, office affairs",
        "categoryName": " 广告商业事务",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "允许会员用里程兑换其他会员奖励计划积分或奖励的飞行常客项目管理",
        "goodsEnName": "Frequent flyer program management that allows members to redeem miles for other member incentive program points or rewards ",
        "goodsJpName": "会員がマイレージで他の会員を両替することができます。インセンティブプランのポイントやボーナスのフライト常連プログラムの管理を行います。",
        "goodsNo": "",
        "id": 55766,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Commercial and industrial management assisting ",
        "similarGroup": "3502",
        "similarItem": "",
        "similarName": "工商管理辅助业",
        "type": 0,
        "updateTime": "2021-05-25 11:05:42"
      },
      {
        "category": "35",
        "categoryEnName": "Advertising, office affairs",
        "categoryName": " 广告商业事务",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "安排拍卖",
        "goodsEnName": "Arrange the auction",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 65188,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Sales promotion for others ",
        "similarGroup": "3503",
        "similarItem": "3503",
        "similarName": "替他人推销",
        "type": 0,
        "updateTime": "2021-05-25 11:14:05"
      },
      {
        "category": "35",
        "categoryEnName": "Advertising, office affairs",
        "categoryName": " 广告商业事务",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "16,17",
        "flagBit": 0,
        "goodsChName": "人力资源管理",
        "goodsEnName": "Human resource management",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 62626,
        "niceCodeLists": "",
        "remark": "可接受，非标准",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Human resource management assisting ",
        "similarGroup": "3504",
        "similarItem": "3504",
        "similarName": "人事管理辅助业",
        "type": 0,
        "updateTime": "2021-05-25 11:14:03"
      },
      {
        "category": "35",
        "categoryEnName": "Advertising, office affairs",
        "categoryName": " 广告商业事务",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "打字机和复印机出租",
        "goodsEnName": "Rental of typewriters and photocopiers",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 58083,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Office affairs ",
        "similarGroup": "3506",
        "similarItem": "3506",
        "similarName": "办公事务",
        "type": 0,
        "updateTime": "2021-05-25 11:11:14"
      },
      {
        "category": "35",
        "categoryEnName": "Advertising, office affairs",
        "categoryName": " 广告商业事务",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "财务报表的审计",
        "goodsEnName": "Audit of financial statements",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 62297,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Accounting ",
        "similarGroup": "3507",
        "similarItem": "3507",
        "similarName": "财会",
        "type": 0,
        "updateTime": "2021-05-25 11:11:16"
      },
      {
        "category": "35",
        "categoryEnName": "Advertising, office affairs",
        "categoryName": " 广告商业事务",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "销售展示架出租",
        "goodsEnName": "Rental of sales stands",
        "goodsJpName": "販売用スタンドの貸与",
        "goodsNo": "350109",
        "id": 98263,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Single services ",
        "similarGroup": "3508",
        "similarItem": "3508Z3",
        "similarName": "单一服务",
        "type": 1,
        "updateTime": "2021-05-25 10:13:06"
      },
      {
        "category": "35",
        "categoryEnName": "Advertising, office affairs",
        "categoryName": " 广告商业事务",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "13",
        "flagBit": 0,
        "goodsChName": "药用、兽医用、卫生用制剂和医疗用品的零售服务",
        "goodsEnName": "Retail services for pharmaceutical, veterinary and sanitary preparations and medical supplies",
        "goodsJpName": "薬剤及び医療補助品の小売又は卸売の業務において行われる顧客に対する便益の提供",
        "goodsNo": "350108",
        "id": 64908,
        "niceCodeLists": "",
        "remark": " 2018版【修改】“药用、兽医用、卫生用制剂和医疗用品的零售或批发服务”改为“药用、兽医用、卫生用制剂和医疗用品的零售服务”",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Retail or wholesale services related to medicines and medical supplies ",
        "similarGroup": "3509",
        "similarItem": "3509Z1",
        "similarName": "药品、医疗用品零售或批发服务",
        "type": 1,
        "updateTime": "2021-05-25 10:11:50"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "允许会员用里程兑换其他会员奖励计划积分或奖励的飞行常客项目管理",
    "goodsEnName": "Frequent flyer program management that allows members to redeem miles for other member incentive program points or rewards ",
    "goodsJpName": "会員がマイレージで他の会員を両替することができます。インセンティブプランのポイントやボーナスのフライト常連プログラムの管理を行います。",
    "goodsNo": "",
    "id": 55766,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": " Commercial and industrial management assisting ",
    "similarGroup": "3502",
    "similarItem": "",
    "similarName": "工商管理辅助业",
    "type": 0,
    "updateTime": "2021-05-25 11:05:42"
  },
  {
    "category": "36",
    "categoryEnName": "Insurance, financial affairs",
    "categoryName": "保险金融服务",
    "children": [
      {
        "category": "36",
        "categoryEnName": "Insurance, financial affairs",
        "categoryName": "保险金融服务",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "保险精算服务",
        "goodsEnName": "Actuarial services",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 61156,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Insurance ",
        "similarGroup": "3601",
        "similarItem": "3601",
        "similarName": "保险",
        "type": 0,
        "updateTime": "2021-05-25 11:13:28"
      },
      {
        "category": "36",
        "categoryEnName": "Insurance, financial affairs",
        "categoryName": "保险金融服务",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "慈善基金投资",
        "goodsEnName": "Charitable fund investment",
        "goodsJpName": "慈善基金投資",
        "goodsNo": "",
        "id": 55290,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Banking ",
        "similarGroup": "3602",
        "similarItem": "",
        "similarName": "金融事务",
        "type": 0,
        "updateTime": "2021-05-25 10:05:42"
      },
      {
        "category": "36",
        "categoryEnName": "Insurance, financial affairs",
        "categoryName": "保险金融服务",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "邮票估价",
        "goodsEnName": "Stamp appraisal",
        "goodsJpName": "切手の評価",
        "goodsNo": "360064",
        "id": 58664,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Gem evaluation ",
        "similarGroup": "3603",
        "similarItem": "3603",
        "similarName": "珍品估价",
        "type": 0,
        "updateTime": "2021-05-25 11:33:37"
      },
      {
        "category": "36",
        "categoryEnName": "Insurance, financial affairs",
        "categoryName": "保险金融服务",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "14",
        "flagBit": 0,
        "goodsChName": "不动产经纪",
        "goodsEnName": "Real estate brokerage",
        "goodsJpName": "建物の売買又は貸借の代理又は媒介",
        "goodsNo": "360008",
        "id": 57453,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Real estate affairs ",
        "similarGroup": "3604",
        "similarItem": "3604",
        "similarName": "不动产事务",
        "type": 0,
        "updateTime": "2021-05-25 11:33:38"
      },
      {
        "category": "36",
        "categoryEnName": "Insurance, financial affairs",
        "categoryName": "保险金融服务",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "14",
        "flagBit": 0,
        "goodsChName": "海关金融经纪服务",
        "goodsEnName": "Financial customs brokerage services",
        "goodsJpName": "関税に関する手続きの代行",
        "goodsNo": "360011",
        "id": 190530,
        "niceCodeLists": "",
        "remark": "修改：“海关经纪”改为“海关金融经纪服务”",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Brokerage ",
        "similarGroup": "3605",
        "similarItem": "3605",
        "similarName": "经纪",
        "type": 1,
        "updateTime": "2021-05-25 11:42:10"
      },
      {
        "category": "36",
        "categoryEnName": "Insurance, financial affairs",
        "categoryName": "保险金融服务",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "金融担保（担保服务）",
        "goodsEnName": "Financial guarantee (guarantee service)",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 56669,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Guarantee ",
        "similarGroup": "3606",
        "similarItem": "3606",
        "similarName": "担保",
        "type": 0,
        "updateTime": "2021-05-25 10:26:11"
      },
      {
        "category": "36",
        "categoryEnName": "Insurance, financial affairs",
        "categoryName": "保险金融服务",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "14",
        "flagBit": 0,
        "goodsChName": "募集慈善基金",
        "goodsEnName": "Charitable fund raising",
        "goodsJpName": "慈善のための募金",
        "goodsNo": "360015",
        "id": 78523,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Charitable contribution ",
        "similarGroup": "3607",
        "similarItem": "3607",
        "similarName": "慈善募捐",
        "type": 1,
        "updateTime": "2021-05-25 11:29:30"
      },
      {
        "category": "36",
        "categoryEnName": "Insurance, financial affairs",
        "categoryName": "保险金融服务",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "不动产信托管理",
        "goodsEnName": "Real estate trust management",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 57859,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Trustee management ",
        "similarGroup": "3608",
        "similarItem": "3608",
        "similarName": "受托管理",
        "type": 0,
        "updateTime": "2021-05-25 10:18:54"
      },
      {
        "category": "36",
        "categoryEnName": "Insurance, financial affairs",
        "categoryName": "保险金融服务",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "典当经纪",
        "goodsEnName": "Pawnbrokerage",
        "goodsJpName": "質屋による資金の貸付",
        "goodsNo": "360031",
        "id": 129609,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Lending against security ",
        "similarGroup": "3609",
        "similarItem": "3609",
        "similarName": "典当",
        "type": 0,
        "updateTime": "2021-05-25 11:29:30"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "慈善基金投资",
    "goodsEnName": "Charitable fund investment",
    "goodsJpName": "慈善基金投資",
    "goodsNo": "",
    "id": 55290,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": " Banking ",
    "similarGroup": "3602",
    "similarItem": "",
    "similarName": "金融事务",
    "type": 0,
    "updateTime": "2021-05-25 10:05:42"
  },
  {
    "category": "37",
    "categoryEnName": "Construction, repair, installation",
    "categoryName": "建筑修理安装",
    "children": [
      {
        "category": "37",
        "categoryEnName": "Construction, repair, installation",
        "categoryName": "建筑修理安装",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "18",
        "flagBit": 0,
        "goodsChName": "建筑咨询",
        "goodsEnName": "Construction consultancy",
        "goodsJpName": "建築工事に関する助言",
        "goodsNo": "370131",
        "id": 64516,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Construction, maintaining information service ",
        "similarGroup": "3701",
        "similarItem": "3701",
        "similarName": "建设、维修信息服务",
        "type": 1,
        "updateTime": "2021-05-25 11:29:31"
      },
      {
        "category": "37",
        "categoryEnName": "Construction, repair, installation",
        "categoryName": "建筑修理安装",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "挖掘机出租",
        "goodsEnName": "Rental of excavators",
        "goodsJpName": "掘削機の貸与",
        "goodsNo": "370044",
        "id": 58468,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Construction project service ",
        "similarGroup": "3702",
        "similarItem": "3702",
        "similarName": "建筑工程服务",
        "type": 0,
        "updateTime": "2021-05-25 11:33:43"
      },
      {
        "category": "37",
        "categoryEnName": "Construction, repair, installation",
        "categoryName": "建筑修理安装",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "18",
        "flagBit": 0,
        "goodsChName": "钻井",
        "goodsEnName": "Drilling of wells",
        "goodsJpName": "さく井",
        "goodsNo": "370114",
        "id": 68919,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Mining service ",
        "similarGroup": "3703",
        "similarItem": "3703",
        "similarName": "开采服务",
        "type": 0,
        "updateTime": "2021-05-25 11:30:02"
      },
      {
        "category": "37",
        "categoryEnName": "Construction, repair, installation",
        "categoryName": "建筑修理安装",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "室内外油漆",
        "goodsEnName": "Painting, interior and exterior",
        "goodsJpName": "屋内及び屋外の塗装",
        "goodsNo": "370068",
        "id": 58174,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Building decoration and maintenance service ",
        "similarGroup": "3704",
        "similarItem": "3704",
        "similarName": "建筑物装饰修理服务",
        "type": 0,
        "updateTime": "2021-05-25 11:30:03"
      },
      {
        "category": "37",
        "categoryEnName": "Construction, repair, installation",
        "categoryName": "建筑修理安装",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "10,18",
        "flagBit": 0,
        "goodsChName": "加热设备安装和修理",
        "goodsEnName": "Heating equipment installation and repair",
        "goodsJpName": "暖房設備工事及び暖房装置の修理",
        "goodsNo": "370024",
        "id": 63550,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Heating plant service ",
        "similarGroup": "3705",
        "similarItem": "3705",
        "similarName": "供暖设备的安装与修理",
        "type": 1,
        "updateTime": "2021-05-25 11:34:13"
      },
      {
        "category": "37",
        "categoryEnName": "Construction, repair, installation",
        "categoryName": "建筑修理安装",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "计算机硬件维护",
        "goodsEnName": "Computer hardware maintenance",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 56690,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Installation and mending of mechanics and electrical appliance equipment ",
        "similarGroup": "3706",
        "similarItem": "3706",
        "similarName": "机械、电器设备的安装与修理",
        "type": 0,
        "updateTime": "2021-05-25 10:59:43"
      },
      {
        "category": "37",
        "categoryEnName": "Construction, repair, installation",
        "categoryName": "建筑修理安装",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "自行车修理",
        "goodsEnName": "Bicycle repair",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 59616,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Land mechanic vehicles mending ",
        "similarGroup": "3707",
        "similarItem": "3707",
        "similarName": "陆地机械车辆维修",
        "type": 0,
        "updateTime": "2021-05-25 11:13:25"
      },
      {
        "category": "37",
        "categoryEnName": "Construction, repair, installation",
        "categoryName": "建筑修理安装",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "飞机维护",
        "goodsEnName": "Aircraft maintenance",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 57250,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Airplane mending ",
        "similarGroup": "3708",
        "similarItem": "3708",
        "similarName": "飞机维修",
        "type": 0,
        "updateTime": "2021-05-25 10:59:44"
      },
      {
        "category": "37",
        "categoryEnName": "Construction, repair, installation",
        "categoryName": "建筑修理安装",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "3",
        "flagBit": 0,
        "goodsChName": "造船",
        "goodsEnName": "Shipbuilding",
        "goodsJpName": "船舶の建造",
        "goodsNo": "370021",
        "id": 125017,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Shipbuilding service ",
        "similarGroup": "3709",
        "similarItem": "3709",
        "similarName": "造船服务",
        "type": 0,
        "updateTime": "2021-05-25 11:30:07"
      },
      {
        "category": "37",
        "categoryEnName": "Construction, repair, installation",
        "categoryName": "建筑修理安装",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "照相机修理",
        "goodsEnName": "Camera repair",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 70186,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Visual display equipment mending ",
        "similarGroup": "3710",
        "similarItem": "3710",
        "similarName": "影视器材维修",
        "type": 0,
        "updateTime": "2021-05-25 11:13:09"
      },
      {
        "category": "37",
        "categoryEnName": "Construction, repair, installation",
        "categoryName": "建筑修理安装",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "10",
        "flagBit": 0,
        "goodsChName": "钟表修理服务",
        "goodsEnName": "Watch repair service",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 85285,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Clocks and watches mending ",
        "similarGroup": "3711",
        "similarItem": "3711",
        "similarName": "钟表修理",
        "type": 0,
        "updateTime": "2021-05-25 10:25:23"
      },
      {
        "category": "37",
        "categoryEnName": "Construction, repair, installation",
        "categoryName": "建筑修理安装",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "修保险锁",
        "goodsEnName": "Repair of security locks",
        "goodsJpName": "安全鍵の修理",
        "goodsNo": "370125",
        "id": 70123,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Insurance equipment mending ",
        "similarGroup": "3712",
        "similarItem": "3712",
        "similarName": "保险装置的维修",
        "type": 0,
        "updateTime": "2021-05-25 11:47:59"
      },
      {
        "category": "37",
        "categoryEnName": "Construction, repair, installation",
        "categoryName": "建筑修理安装",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "3,8,10,18",
        "flagBit": 0,
        "goodsChName": "防锈",
        "goodsEnName": "Rustproofing",
        "goodsJpName": "乗物及び建築物の防錆処理",
        "goodsNo": "370037",
        "id": 92684,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Special treatment service ",
        "similarGroup": "3713",
        "similarItem": "3713",
        "similarName": "特殊处理服务",
        "type": 1,
        "updateTime": "2021-05-25 11:29:55"
      },
      {
        "category": "37",
        "categoryEnName": "Construction, repair, installation",
        "categoryName": "建筑修理安装",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "3",
        "flagBit": 0,
        "goodsChName": "橡胶轮胎修补",
        "goodsEnName": "Rubber tires [tyres] [repair]",
        "goodsJpName": "ゴムタイヤの修理",
        "goodsNo": "C370016",
        "id": 82933,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Tyre repairing service ",
        "similarGroup": "3714",
        "similarItem": "3714",
        "similarName": "轮胎维修服务",
        "type": 0,
        "updateTime": "2021-05-25 11:29:55"
      },
      {
        "category": "37",
        "categoryEnName": "Construction, repair, installation",
        "categoryName": "建筑修理安装",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "榻榻米垫的修理",
        "goodsEnName": "Repair of tatami mats",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 97234,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Furniture repairing and maintaining ",
        "similarGroup": "3715",
        "similarItem": "3715",
        "similarName": "家具的修复、保养",
        "type": 0,
        "updateTime": "2021-05-25 10:11:48"
      },
      {
        "category": "37",
        "categoryEnName": "Construction, repair, installation",
        "categoryName": "建筑修理安装",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "地毯清洗",
        "goodsEnName": "Carpet cleaning",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 57446,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Repairing, protecting and cleaning service for clothes and leather ",
        "similarGroup": "3716",
        "similarItem": "3716",
        "similarName": "衣服、皮革的修补、保护、洗涤服务",
        "type": 0,
        "updateTime": "2021-05-25 11:13:21"
      },
      {
        "category": "37",
        "categoryEnName": "Construction, repair, installation",
        "categoryName": "建筑修理安装",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "餐具消毒",
        "goodsEnName": "Tableware disinfection",
        "goodsJpName": "食器消毒",
        "goodsNo": "",
        "id": 74995,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Deinsectation, disinfection service ",
        "similarGroup": "3717",
        "similarItem": "3717",
        "similarName": "灭虫,消毒服务",
        "type": 0,
        "updateTime": "2021-05-25 11:13:24"
      },
      {
        "category": "37",
        "categoryEnName": "Construction, repair, installation",
        "categoryName": "建筑修理安装",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "艺术品修复",
        "goodsEnName": "Restoration of works of art",
        "goodsJpName": "美術品の修復",
        "goodsNo": "370126",
        "id": 59518,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Single services ",
        "similarGroup": "3718",
        "similarItem": "3718Z10",
        "similarName": "单一服务",
        "type": 1,
        "updateTime": "2021-05-25 10:51:08"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "计算机硬件维护",
    "goodsEnName": "Computer hardware maintenance",
    "goodsJpName": "",
    "goodsNo": "",
    "id": 56690,
    "niceCodeLists": "",
    "remark": "2018, 五方可接受",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": " Installation and mending of mechanics and electrical appliance equipment ",
    "similarGroup": "3706",
    "similarItem": "3706",
    "similarName": "机械、电器设备的安装与修理",
    "type": 0,
    "updateTime": "2021-05-25 10:59:43"
  },
  {
    "category": "38",
    "categoryEnName": "Telecommunications",
    "categoryName": "电信通信服务",
    "children": [
      {
        "category": "38",
        "categoryEnName": "Telecommunications",
        "categoryName": "电信通信服务",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "无线电节目广播",
        "goodsEnName": "Radio programme broadcasting",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 58188,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Broadcasting radio and TV program service ",
        "similarGroup": "3801",
        "similarItem": "3801",
        "similarName": "进行播放无线电或电视节目的服务",
        "type": 0,
        "updateTime": "2021-05-25 10:50:25"
      },
      {
        "category": "38",
        "categoryEnName": "Telecommunications",
        "categoryName": "电信通信服务",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "通过电子通信网络进行信息传输",
        "goodsEnName": "Information transmission through an electronic communication network",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 56928,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Communication service ",
        "similarGroup": "3802",
        "similarItem": "3802",
        "similarName": "通讯服务",
        "type": 0,
        "updateTime": "2021-05-25 10:14:19"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "通过电子通信网络进行信息传输",
    "goodsEnName": "Information transmission through an electronic communication network",
    "goodsJpName": "",
    "goodsNo": "",
    "id": 56928,
    "niceCodeLists": "",
    "remark": "2018, 五方可接受",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": " Communication service ",
    "similarGroup": "3802",
    "similarItem": "3802",
    "similarName": "通讯服务",
    "type": 0,
    "updateTime": "2021-05-25 10:14:19"
  },
  {
    "category": "39",
    "categoryEnName": "Transport, storage",
    "categoryName": "运输旅行安排",
    "children": [
      {
        "category": "39",
        "categoryEnName": "Transport, storage",
        "categoryName": "运输旅行安排",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "货物发运",
        "goodsEnName": "Freight forwarding",
        "goodsJpName": "貨物の発送",
        "goodsNo": "390060",
        "id": 61716,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Transportation and pre-transportation packing service ",
        "similarGroup": "3901",
        "similarItem": "3901（一）",
        "similarName": "运输及运输前的包装服务",
        "type": 0,
        "updateTime": "2021-05-25 11:08:31"
      },
      {
        "category": "39",
        "categoryEnName": "Transport, storage",
        "categoryName": "运输旅行安排",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "船只出租",
        "goodsEnName": "Boat rental",
        "goodsJpName": "船舶の貸与",
        "goodsNo": "390012",
        "id": 56620,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Water transportation and related service ",
        "similarGroup": "3902",
        "similarItem": "3902（一）",
        "similarName": "水上运输及相关服务",
        "type": 1,
        "updateTime": "2021-05-25 11:12:30"
      },
      {
        "category": "39",
        "categoryEnName": "Transport, storage",
        "categoryName": "运输旅行安排",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "装甲车运输",
        "goodsEnName": "Armored–car transport",
        "goodsJpName": "装甲車による輸送",
        "goodsNo": "390062",
        "id": 58916,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Land transportation ",
        "similarGroup": "3903",
        "similarItem": "3903",
        "similarName": "陆地运输",
        "type": 0,
        "updateTime": "2021-05-25 10:53:42"
      },
      {
        "category": "39",
        "categoryEnName": "Transport, storage",
        "categoryName": "运输旅行安排",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "航空货运服务",
        "goodsEnName": "Air cargo service",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 74211,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Air transportation ",
        "similarGroup": "3904",
        "similarItem": "3904",
        "similarName": "空中运输",
        "type": 0,
        "updateTime": "2021-05-25 11:14:32"
      },
      {
        "category": "39",
        "categoryEnName": "Transport, storage",
        "categoryName": "运输旅行安排",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "拖拉机出租",
        "goodsEnName": "Rental of tractors",
        "goodsJpName": "トラクターの貸与",
        "goodsNo": "390110",
        "id": 58076,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Other transportation and related service ",
        "similarGroup": "3905",
        "similarItem": "3905（一）",
        "similarName": "其他运输及相关服务",
        "type": 0,
        "updateTime": "2021-05-25 11:15:00"
      },
      {
        "category": "39",
        "categoryEnName": "Transport, storage",
        "categoryName": "运输旅行安排",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "（剧院、酒店）衣帽存放服务",
        "goodsEnName": "(theater, hotel) clothing storage service",
        "goodsJpName": "（劇場、ホテル）クローク保管サービス",
        "goodsNo": "",
        "id": 55955,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Cargo storage ",
        "similarGroup": "3906",
        "similarItem": "",
        "similarName": "货物的贮藏",
        "type": 0,
        "updateTime": "2021-05-25 11:07:51"
      },
      {
        "category": "39",
        "categoryEnName": "Transport, storage",
        "categoryName": "运输旅行安排",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "6,17",
        "flagBit": 0,
        "goodsChName": "潜水服出租",
        "goodsEnName": "Rental of diving suits",
        "goodsJpName": "潜水服の貸与",
        "goodsNo": "390079",
        "id": 106439,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Diving facilities rent",
        "similarGroup": "3907",
        "similarItem": "3907",
        "similarName": "潜水工具出租",
        "type": 1,
        "updateTime": "2021-05-25 11:22:43"
      },
      {
        "category": "39",
        "categoryEnName": "Transport, storage",
        "categoryName": "运输旅行安排",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "配电",
        "goodsEnName": "Electricity distribution",
        "goodsJpName": "電気の配給",
        "goodsNo": "390031",
        "id": 66028,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Water and electric supply service ",
        "similarGroup": "3908",
        "similarItem": "3908",
        "similarName": "供水电气服务",
        "type": 0,
        "updateTime": "2021-05-25 10:53:46"
      },
      {
        "category": "39",
        "categoryEnName": "Transport, storage",
        "categoryName": "运输旅行安排",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "17",
        "flagBit": 0,
        "goodsChName": "操作运河水闸",
        "goodsEnName": "Operating canal locks",
        "goodsJpName": "水門の操作",
        "goodsNo": "390032",
        "id": 102911,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Water gate management service ",
        "similarGroup": "3909",
        "similarItem": "3909",
        "similarName": "水闸管理服务",
        "type": 1,
        "updateTime": "2021-05-25 10:53:47"
      },
      {
        "category": "39",
        "categoryEnName": "Transport, storage",
        "categoryName": "运输旅行安排",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "信件快递",
        "goodsEnName": "Message courier services",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 83528,
        "niceCodeLists": "",
        "remark": "可接受，非标准",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Delivery service ",
        "similarGroup": "3910",
        "similarItem": "3910",
        "similarName": "投递服务",
        "type": 0,
        "updateTime": "2021-05-25 10:50:22"
      },
      {
        "category": "39",
        "categoryEnName": "Transport, storage",
        "categoryName": "运输旅行安排",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "旅行信息",
        "goodsEnName": "Travel information",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 70298,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Travel arrangement ",
        "similarGroup": "3911",
        "similarItem": "3911",
        "similarName": "旅行安排",
        "type": 0,
        "updateTime": "2021-05-25 11:14:29"
      },
      {
        "category": "39",
        "categoryEnName": "Transport, storage",
        "categoryName": "运输旅行安排",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "轮椅出租",
        "goodsEnName": "Rental of wheelchairs",
        "goodsJpName": "車いすの貸与",
        "goodsNo": "390092",
        "id": 80588,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Single service ",
        "similarGroup": "3912",
        "similarItem": "3912Z2",
        "similarName": "单一服务",
        "type": 1,
        "updateTime": "2021-05-25 11:22:46"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "（剧院、酒店）衣帽存放服务",
    "goodsEnName": "(theater, hotel) clothing storage service",
    "goodsJpName": "（劇場、ホテル）クローク保管サービス",
    "goodsNo": "",
    "id": 55955,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": " Cargo storage ",
    "similarGroup": "3906",
    "similarItem": "",
    "similarName": "货物的贮藏",
    "type": 0,
    "updateTime": "2021-05-25 11:07:51"
  },
  {
    "category": "4",
    "categoryEnName": "Industrial oils and fuels",
    "categoryName": "油脂照明燃料",
    "children": [
      {
        "category": "4",
        "categoryEnName": "Industrial oils and fuels",
        "categoryName": "油脂照明燃料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "煤焦油",
        "goodsEnName": "Coal tar",
        "goodsJpName": "",
        "goodsNo": "040053",
        "id": 57649,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Oils and greases for industrial purposes, lubricants (not including fuel oils)",
        "similarGroup": "0401",
        "similarItem": "0401",
        "similarName": "工业用油及油脂,润滑油,润滑剂（不包括燃料用油）",
        "type": 0,
        "updateTime": "2021-05-25 11:19:56"
      },
      {
        "category": "4",
        "categoryEnName": "Industrial oils and fuels",
        "categoryName": "油脂照明燃料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "燃料油",
        "goodsEnName": "Fuel oil / Combustible oil",
        "goodsJpName": "可燃性油",
        "goodsNo": "040084",
        "id": 56844,
        "niceCodeLists": "",
        "remark": "修改注2",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Wet fuels, gas fuels and lighting fuels",
        "similarGroup": "0402",
        "similarItem": "0402",
        "similarName": "挥发性燃料混合物,汽车燃料",
        "type": 0,
        "updateTime": "2021-05-25 11:02:21"
      },
      {
        "category": "4",
        "categoryEnName": "Industrial oils and fuels",
        "categoryName": "油脂照明燃料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "褐煤",
        "goodsEnName": "Lignite",
        "goodsJpName": "亜炭",
        "goodsNo": "040062",
        "id": 60421,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Solidified fuels",
        "similarGroup": "0403",
        "similarItem": "0403",
        "similarName": "固体燃料",
        "type": 0,
        "updateTime": "2021-05-25 11:33:49"
      },
      {
        "category": "4",
        "categoryEnName": "Industrial oils and fuels",
        "categoryName": "油脂照明燃料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "地蜡",
        "goodsEnName": "Ozocerite / ozekerite",
        "goodsJpName": "オゾケライト",
        "goodsNo": "040069",
        "id": 61345,
        "niceCodeLists": "",
        "remark": "2018版【修改】“地蜡（石蜡）”改为“地蜡”，2018版删除注1",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Waxes for industrial purposes",
        "similarGroup": "0404",
        "similarItem": "0404",
        "similarName": "工业用蜡",
        "type": 0,
        "updateTime": "2021-05-25 11:33:50"
      },
      {
        "category": "4",
        "categoryEnName": "Industrial oils and fuels",
        "categoryName": "油脂照明燃料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "夜间照明物（蜡烛）",
        "goodsEnName": "Nightlights [candles]",
        "goodsJpName": "ろうそく",
        "goodsNo": "040076",
        "id": 70305,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Candles, lampwick",
        "similarGroup": "0405",
        "similarItem": "0405",
        "similarName": "照明用蜡烛和灯芯",
        "type": 0,
        "updateTime": "2021-05-25 11:33:53"
      },
      {
        "category": "4",
        "categoryEnName": "Industrial oils and fuels",
        "categoryName": "油脂照明燃料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "18",
        "flagBit": 0,
        "goodsChName": "除尘制剂",
        "goodsEnName": "Dust removing preparations",
        "goodsJpName": "塵埃除去剤",
        "goodsNo": "040038",
        "id": 64138,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Alliances for absorbing, insufflating and cohering dusts",
        "similarGroup": "0406",
        "similarItem": "0406",
        "similarName": "吸收、润湿和粘结灰尘用合成物",
        "type": 1,
        "updateTime": "2021-05-25 11:33:54"
      },
      {
        "category": "4",
        "categoryEnName": "Industrial oils and fuels",
        "categoryName": "油脂照明燃料",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "10",
        "flagBit": 0,
        "goodsChName": "核聚变产生的能源",
        "goodsEnName": "Energy produced by nuclear fusion",
        "goodsJpName": "核融合によって生成されるエネルギー",
        "goodsNo": "C040027",
        "id": 122154,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Energy",
        "similarGroup": "0407",
        "similarItem": "0407",
        "similarName": "能源",
        "type": 0,
        "updateTime": "2021-05-25 11:28:21"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "燃料油",
    "goodsEnName": "Fuel oil / Combustible oil",
    "goodsJpName": "可燃性油",
    "goodsNo": "040084",
    "id": 56844,
    "niceCodeLists": "",
    "remark": "修改注2",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": "Wet fuels, gas fuels and lighting fuels",
    "similarGroup": "0402",
    "similarItem": "0402",
    "similarName": "挥发性燃料混合物,汽车燃料",
    "type": 0,
    "updateTime": "2021-05-25 11:02:21"
  },
  {
    "category": "40",
    "categoryEnName": "Material treatment",
    "categoryName": "材料水气处理",
    "children": [
      {
        "category": "40",
        "categoryEnName": "Material treatment",
        "categoryName": "材料水气处理",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "打磨",
        "goodsEnName": "Abrasion",
        "goodsJpName": "研磨",
        "goodsNo": "400001",
        "id": 93587,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Comprehensive processing and information service ",
        "similarGroup": "4001",
        "similarItem": "4001",
        "similarName": "综合加工及提供信息服务",
        "type": 1,
        "updateTime": "2021-05-25 10:58:14"
      },
      {
        "category": "40",
        "categoryEnName": "Material treatment",
        "categoryName": "材料水气处理",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "金属回火",
        "goodsEnName": "Metal tempering",
        "goodsJpName": "焼戻し",
        "goodsNo": "400043",
        "id": 64810,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Metal material treatment or processing service ",
        "similarGroup": "4002",
        "similarItem": "4002",
        "similarName": "金属材料处理或加工服务",
        "type": 0,
        "updateTime": "2021-05-25 10:50:08"
      },
      {
        "category": "40",
        "categoryEnName": "Material treatment",
        "categoryName": "材料水气处理",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "10",
        "flagBit": 0,
        "goodsChName": "纺织品化学处理",
        "goodsEnName": "Cloth treating / textile treating",
        "goodsJpName": "布地の処理",
        "goodsNo": "400058",
        "id": 66917,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Textile chemical treatment or processing service ",
        "similarGroup": "4003",
        "similarItem": "4003",
        "similarName": "纺织品化学处理或加工服务",
        "type": 0,
        "updateTime": "2021-05-25 11:23:16"
      },
      {
        "category": "40",
        "categoryEnName": "Material treatment",
        "categoryName": "材料水气处理",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "10",
        "flagBit": 0,
        "goodsChName": "木器制作",
        "goodsEnName": "Woodworking",
        "goodsJpName": "木材の加工",
        "goodsNo": "400009",
        "id": 123484,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Timber processing service ",
        "similarGroup": "4004",
        "similarItem": "4004",
        "similarName": "木材加工服务",
        "type": 1,
        "updateTime": "2021-05-25 11:23:17"
      },
      {
        "category": "40",
        "categoryEnName": "Material treatment",
        "categoryName": "材料水气处理",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "纸张处理",
        "goodsEnName": "Paper treating",
        "goodsJpName": "紙の加工",
        "goodsNo": "400061",
        "id": 76605,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Paper finishing service ",
        "similarGroup": "4005",
        "similarItem": "4005",
        "similarName": "纸张加工服务",
        "type": 0,
        "updateTime": "2021-05-25 10:56:57"
      },
      {
        "category": "40",
        "categoryEnName": "Material treatment",
        "categoryName": "材料水气处理",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "光学透镜研磨",
        "goodsEnName": "Optical lens grinding",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 64509,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Glass processing service ",
        "similarGroup": "4006",
        "similarItem": "4006",
        "similarName": "玻璃加工服务",
        "type": 0,
        "updateTime": "2021-05-25 10:50:03"
      },
      {
        "category": "40",
        "categoryEnName": "Material treatment",
        "categoryName": "材料水气处理",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "陶瓷加工",
        "goodsEnName": "Ceramic processing",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 113292,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Pottery utensil processing service ",
        "similarGroup": "4007",
        "similarItem": "4007",
        "similarName": "陶器加工服务",
        "type": 0,
        "updateTime": "2021-05-25 10:50:21"
      },
      {
        "category": "40",
        "categoryEnName": "Material treatment",
        "categoryName": "材料水气处理",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "1",
        "flagBit": 0,
        "goodsChName": "油料加工",
        "goodsEnName": "Processing of oil",
        "goodsJpName": "油の加工",
        "goodsNo": "400091",
        "id": 56452,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Food, beverage processing service ",
        "similarGroup": "4008",
        "similarItem": "4008",
        "similarName": "食物、饮料加工服务",
        "type": 0,
        "updateTime": "2021-05-25 10:56:58"
      },
      {
        "category": "40",
        "categoryEnName": "Material treatment",
        "categoryName": "材料水气处理",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "屠宰",
        "goodsEnName": "Slaughtering",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 58265,
        "niceCodeLists": "",
        "remark": "可接受、非标准 2017-4",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Make processing service ",
        "similarGroup": "4009",
        "similarItem": "4009",
        "similarName": "剥制加工服务",
        "type": 0,
        "updateTime": "2021-05-25 10:26:59"
      },
      {
        "category": "40",
        "categoryEnName": "Material treatment",
        "categoryName": "材料水气处理",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "服装修改",
        "goodsEnName": "Clothing alteration",
        "goodsJpName": "被服の寸法直し",
        "goodsNo": "400098",
        "id": 56648,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Leather, clothes processing service ",
        "similarGroup": "4010",
        "similarItem": "4010",
        "similarName": "皮革、服装加工服务",
        "type": 0,
        "updateTime": "2021-05-25 11:09:23"
      },
      {
        "category": "40",
        "categoryEnName": "Material treatment",
        "categoryName": "材料水气处理",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "照片放大",
        "goodsEnName": "Photo magnification",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 62416,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Image processing treatment service ",
        "similarGroup": "4011",
        "similarItem": "4011",
        "similarName": "影像加工处理服务",
        "type": 0,
        "updateTime": "2021-05-25 10:50:06"
      },
      {
        "category": "40",
        "categoryEnName": "Material treatment",
        "categoryName": "材料水气处理",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "核废料处理",
        "goodsEnName": "Nuclear waste disposal",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 59560,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Sewage treatment service ",
        "similarGroup": "4012",
        "similarItem": "4012",
        "similarName": "污物处理服务",
        "type": 0,
        "updateTime": "2021-05-25 11:14:30"
      },
      {
        "category": "40",
        "categoryEnName": "Material treatment",
        "categoryName": "材料水气处理",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "10",
        "flagBit": 0,
        "goodsChName": "空气净化",
        "goodsEnName": "Air purification",
        "goodsJpName": "空気の浄化処理",
        "goodsNo": "400003",
        "id": 75618,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Air purge service ",
        "similarGroup": "4013",
        "similarItem": "4013",
        "similarName": "空气调节服务",
        "type": 1,
        "updateTime": "2021-05-25 10:57:01"
      },
      {
        "category": "40",
        "categoryEnName": "Material treatment",
        "categoryName": "材料水气处理",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "水处理和净化",
        "goodsEnName": "Water treatment and purification",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 74232,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Water treatment service ",
        "similarGroup": "4014",
        "similarItem": "4014",
        "similarName": "水处理服务",
        "type": 0,
        "updateTime": "2021-05-25 11:13:30"
      },
      {
        "category": "40",
        "categoryEnName": "Material treatment",
        "categoryName": "材料水气处理",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "假发套的定制",
        "goodsEnName": "Customization of wig cover",
        "goodsJpName": "ウィッグカバーのカスタマイズ",
        "goodsNo": "",
        "id": 55787,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Single service ",
        "similarGroup": "4015",
        "similarItem": "",
        "similarName": "单一服务",
        "type": 0,
        "updateTime": "2021-05-25 11:05:43"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "假发套的定制",
    "goodsEnName": "Customization of wig cover",
    "goodsJpName": "ウィッグカバーのカスタマイズ",
    "goodsNo": "",
    "id": 55787,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": " Single service ",
    "similarGroup": "4015",
    "similarItem": "",
    "similarName": "单一服务",
    "type": 0,
    "updateTime": "2021-05-25 11:05:43"
  },
  {
    "category": "41",
    "categoryEnName": "Education, entertainment",
    "categoryName": "教育娱乐文体",
    "children": [
      {
        "category": "41",
        "categoryEnName": "Education, entertainment",
        "categoryName": "教育娱乐文体",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "美发培训",
        "goodsEnName": "Hairdressing training",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 62234,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Education ",
        "similarGroup": "4101",
        "similarItem": "4101",
        "similarName": "教育",
        "type": 0,
        "updateTime": "2021-05-25 11:15:04"
      },
      {
        "category": "41",
        "categoryEnName": "Education, entertainment",
        "categoryName": "教育娱乐文体",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "12,15",
        "flagBit": 0,
        "goodsChName": "安排和组织会议",
        "goodsEnName": "Arranging and conducting of conferences",
        "goodsJpName": "会議の手配及び運営",
        "goodsNo": "410045",
        "id": 60792,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Organizing and arranging activities of education, culture and entertainment ",
        "similarGroup": "4102",
        "similarItem": "4102",
        "similarName": "组织和安排教育、文化、娱乐等活动",
        "type": 1,
        "updateTime": "2021-05-25 11:17:38"
      },
      {
        "category": "41",
        "categoryEnName": "Education, entertainment",
        "categoryName": "教育娱乐文体",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "11,16",
        "flagBit": 0,
        "goodsChName": "出借书籍和其他出版物",
        "goodsEnName": "Lending books and other publications",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 82975,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Library service ",
        "similarGroup": "4103",
        "similarItem": "4103",
        "similarName": "图书馆服务",
        "type": 0,
        "updateTime": "2021-05-25 11:15:54"
      },
      {
        "category": "41",
        "categoryEnName": "Education, entertainment",
        "categoryName": "教育娱乐文体",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "评论出版",
        "goodsEnName": "Review publication",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 62976,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Publishing service ",
        "similarGroup": "4104",
        "similarItem": "4104",
        "similarName": "出版服务",
        "type": 0,
        "updateTime": "2021-05-25 11:07:09"
      },
      {
        "category": "41",
        "categoryEnName": "Education, entertainment",
        "categoryName": "教育娱乐文体",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "空中摄像服务",
        "goodsEnName": "Aerial camera service",
        "goodsJpName": "空中カメラサービス",
        "goodsNo": "",
        "id": 55612,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Recreational and sport activities service ",
        "similarGroup": "4105",
        "similarItem": "",
        "similarName": "文娱、体育活动的服务",
        "type": 0,
        "updateTime": "2021-05-25 11:07:45"
      },
      {
        "category": "41",
        "categoryEnName": "Education, entertainment",
        "categoryName": "教育娱乐文体",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "15",
        "flagBit": 0,
        "goodsChName": "动物训练",
        "goodsEnName": "Animal training",
        "goodsJpName": "動物の調教",
        "goodsNo": "410005",
        "id": 110576,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Beast training ",
        "similarGroup": "4106",
        "similarItem": "4106",
        "similarName": "驯兽",
        "type": 0,
        "updateTime": "2021-05-25 11:11:44"
      },
      {
        "category": "41",
        "categoryEnName": "Education, entertainment",
        "categoryName": "教育娱乐文体",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "12,15",
        "flagBit": 0,
        "goodsChName": "为艺术家提供模特服务",
        "goodsEnName": "Modelling for artists",
        "goodsJpName": "美術用モデルの実演",
        "goodsNo": "410036",
        "id": 104927,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Single service ",
        "similarGroup": "4107",
        "similarItem": "4107Z1",
        "similarName": "单一服务",
        "type": 1,
        "updateTime": "2021-05-25 10:34:30"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "空中摄像服务",
    "goodsEnName": "Aerial camera service",
    "goodsJpName": "空中カメラサービス",
    "goodsNo": "",
    "id": 55612,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": " Recreational and sport activities service ",
    "similarGroup": "4105",
    "similarItem": "",
    "similarName": "文娱、体育活动的服务",
    "type": 0,
    "updateTime": "2021-05-25 11:07:45"
  },
  {
    "category": "42",
    "categoryEnName": "Design, consulting, development",
    "categoryName": "设计咨询开发",
    "children": [
      {
        "category": "42",
        "categoryEnName": "Design, consulting, development",
        "categoryName": "设计咨询开发",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "13",
        "flagBit": 0,
        "goodsChName": "技术项目研究",
        "goodsEnName": "Technical project studies",
        "goodsJpName": "技術的課題の研究",
        "goodsNo": "420061",
        "id": 59539,
        "niceCodeLists": "",
        "remark": "增加注5",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Specialized consultation, research and development service for non-trade ",
        "similarGroup": "4209",
        "similarItem": "4209（一）",
        "similarName": "提供研究和开发服务",
        "type": 0,
        "updateTime": "2021-05-25 10:34:31"
      },
      {
        "category": "42",
        "categoryEnName": "Design, consulting, development",
        "categoryName": "设计咨询开发",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "土地测量",
        "goodsEnName": "Land surveying",
        "goodsJpName": "土地の測量",
        "goodsNo": "420079",
        "id": 62514,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Geological survey, research, exploitation service ",
        "similarGroup": "4210",
        "similarItem": "4210",
        "similarName": "提供地质调查、研究、开发服务",
        "type": 0,
        "updateTime": "2021-05-25 11:11:47"
      },
      {
        "category": "42",
        "categoryEnName": "Design, consulting, development",
        "categoryName": "设计咨询开发",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "生物化学研究",
        "goodsEnName": "Biochemical research",
        "goodsJpName": "生物化学の研究",
        "goodsNo": "",
        "id": 55234,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Chemical research service ",
        "similarGroup": "4211",
        "similarItem": "",
        "similarName": "提供化学研究服务",
        "type": 0,
        "updateTime": "2021-05-25 11:24:55"
      },
      {
        "category": "42",
        "categoryEnName": "Design, consulting, development",
        "categoryName": "设计咨询开发",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "生物技术研究",
        "goodsEnName": "Biotechnology research",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 61002,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Biological research service ",
        "similarGroup": "4212",
        "similarItem": "4212",
        "similarName": "提供生物学研究服务",
        "type": 0,
        "updateTime": "2021-05-25 10:40:56"
      },
      {
        "category": "42",
        "categoryEnName": "Design, consulting, development",
        "categoryName": "设计咨询开发",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "15",
        "flagBit": 0,
        "goodsChName": "气象信息",
        "goodsEnName": "Meteorological information",
        "goodsJpName": "気象情報の提供",
        "goodsNo": "420076",
        "id": 57047,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Meteorological information service ",
        "similarGroup": "4213",
        "similarItem": "4213",
        "similarName": "提供气象情报服务",
        "type": 1,
        "updateTime": "2021-05-25 11:08:32"
      },
      {
        "category": "42",
        "categoryEnName": "Design, consulting, development",
        "categoryName": "设计咨询开发",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "材料检测咨询",
        "goodsEnName": "Material testing consultation",
        "goodsJpName": "材料検査コンサルティング",
        "goodsNo": "",
        "id": 55598,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Other scientific research service ",
        "similarGroup": "4214",
        "similarItem": "",
        "similarName": "提供测试服务",
        "type": 0,
        "updateTime": "2021-05-25 11:07:45"
      },
      {
        "category": "42",
        "categoryEnName": "Design, consulting, development",
        "categoryName": "设计咨询开发",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "汽车设计",
        "goodsEnName": "Vehicle design",
        "goodsJpName": "車のデザイン",
        "goodsNo": "",
        "id": 56284,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Outward appearance design service ",
        "similarGroup": "4216",
        "similarItem": "",
        "similarName": "外观设计服务",
        "type": 0,
        "updateTime": "2021-05-25 11:30:44"
      },
      {
        "category": "42",
        "categoryEnName": "Design, consulting, development",
        "categoryName": "设计咨询开发",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "15",
        "flagBit": 0,
        "goodsChName": "通过网站提供室内设计信息",
        "goodsEnName": "Provide interior design information through website",
        "goodsJpName": "ウェブサイトを通じてインテリアデザイン情報を提供します。",
        "goodsNo": "",
        "id": 55542,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Building design and consultation service ",
        "similarGroup": "4217",
        "similarItem": "",
        "similarName": "建筑物的设计、咨询服务",
        "type": 0,
        "updateTime": "2021-05-25 11:05:38"
      },
      {
        "category": "42",
        "categoryEnName": "Design, consulting, development",
        "categoryName": "设计咨询开发",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "2,15",
        "flagBit": 0,
        "goodsChName": "服装设计",
        "goodsEnName": "Dress designing",
        "goodsJpName": "服飾デザインの考案",
        "goodsNo": "420142",
        "id": 75800,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Dress designing and leasing service ",
        "similarGroup": "4218",
        "similarItem": "4218",
        "similarName": "服装设计服务",
        "type": 1,
        "updateTime": "2021-05-25 10:53:00"
      },
      {
        "category": "42",
        "categoryEnName": "Design, consulting, development",
        "categoryName": "设计咨询开发",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "5",
        "flagBit": 0,
        "goodsChName": "人工智能领域的研究",
        "goodsEnName": "Research in the field of artificial intelligence",
        "goodsJpName": "人工知能分野の研究",
        "goodsNo": "",
        "id": 55962,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Computer programming and related service ",
        "similarGroup": "4220",
        "similarItem": "",
        "similarName": "计算机编程及相关服务",
        "type": 0,
        "updateTime": "2021-05-25 11:07:51"
      },
      {
        "category": "42",
        "categoryEnName": "Design, consulting, development",
        "categoryName": "设计咨询开发",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "14",
        "flagBit": 0,
        "goodsChName": "钱币鉴定",
        "goodsEnName": "Numismatic authenticating",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 58713,
        "niceCodeLists": "",
        "remark": "可接受、非标准 2017-4",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Works of art appraisal service ",
        "similarGroup": "4224",
        "similarItem": "4224",
        "similarName": "提供艺术品鉴定服务",
        "type": 0,
        "updateTime": "2021-05-25 10:27:15"
      },
      {
        "category": "42",
        "categoryEnName": "Design, consulting, development",
        "categoryName": "设计咨询开发",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "替他人称量货物",
        "goodsEnName": "Weighting goods for others",
        "goodsJpName": "受託による商品の計り",
        "goodsNo": "C420012",
        "id": 60918,
        "niceCodeLists": "",
        "remark": "2018版【修改】“代替他人称量货物”改为“替他人称量货物”",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Single service ",
        "similarGroup": "4227",
        "similarItem": "4227Z6",
        "similarName": "单一服务",
        "type": 1,
        "updateTime": "2021-05-25 10:29:42"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "生物化学研究",
    "goodsEnName": "Biochemical research",
    "goodsJpName": "生物化学の研究",
    "goodsNo": "",
    "id": 55234,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": " Chemical research service ",
    "similarGroup": "4211",
    "similarItem": "",
    "similarName": "提供化学研究服务",
    "type": 0,
    "updateTime": "2021-05-25 11:24:55"
  },
  {
    "category": "43",
    "categoryEnName": "Food, accommodation, nursing",
    "categoryName": "餐饮住宿看管",
    "children": [
      {
        "category": "43",
        "categoryEnName": "Food, accommodation, nursing",
        "categoryName": "餐饮住宿看管",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "寄宿处预订",
        "goodsEnName": "Boarding house bookings",
        "goodsJpName": "宿泊施設の予約の取次ぎ",
        "goodsNo": "430104",
        "id": 56879,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Accommodation bureaux [hotels, boarding houses]",
        "similarGroup": "4301",
        "similarItem": "4301",
        "similarName": "提供餐饮,住宿服务",
        "type": 0,
        "updateTime": "2021-05-25 11:03:10"
      },
      {
        "category": "43",
        "categoryEnName": "Food, accommodation, nursing",
        "categoryName": "餐饮住宿看管",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "旅游房屋出租",
        "goodsEnName": "Tourist home services",
        "goodsJpName": "民宿における宿泊施設の提供",
        "goodsNo": "430071",
        "id": 61555,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " House facilities supply service ",
        "similarGroup": "4302",
        "similarItem": "4302",
        "similarName": "提供房屋设施的服务",
        "type": 0,
        "updateTime": "2021-05-25 10:58:21"
      },
      {
        "category": "43",
        "categoryEnName": "Food, accommodation, nursing",
        "categoryName": "餐饮住宿看管",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "12,16",
        "flagBit": 0,
        "goodsChName": "养老院",
        "goodsEnName": "Retirement homes services",
        "goodsJpName": "高齢者用入所施設の提供（介護を伴うものを除く。）",
        "goodsNo": "430013",
        "id": 109309,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Retirement homes ",
        "similarGroup": "4303",
        "similarItem": "4303",
        "similarName": "养老院",
        "type": 1,
        "updateTime": "2021-05-25 10:58:22"
      },
      {
        "category": "43",
        "categoryEnName": "Food, accommodation, nursing",
        "categoryName": "餐饮住宿看管",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "托儿所服务",
        "goodsEnName": "Nursery services",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 105760,
        "niceCodeLists": "",
        "remark": "可接受，非标准",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Child-care center ",
        "similarGroup": "4304",
        "similarItem": "4304",
        "similarName": "托儿服务",
        "type": 0,
        "updateTime": "2021-05-25 11:36:22"
      },
      {
        "category": "43",
        "categoryEnName": "Food, accommodation, nursing",
        "categoryName": "餐饮住宿看管",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "12",
        "flagBit": 0,
        "goodsChName": "宠物寄养",
        "goodsEnName": "Pet foster care",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 140508,
        "niceCodeLists": "",
        "remark": "2018-6, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Animal entrustment ",
        "similarGroup": "4305",
        "similarItem": "4305",
        "similarName": "为动物提供食宿",
        "type": 0,
        "updateTime": "2021-05-25 11:36:22"
      },
      {
        "category": "43",
        "categoryEnName": "Food, accommodation, nursing",
        "categoryName": "餐饮住宿看管",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "1,12",
        "flagBit": 0,
        "goodsChName": "烹饪设备出租",
        "goodsEnName": "Rental of cooking apparatus",
        "goodsJpName": "調理用機械器具の貸与",
        "goodsNo": "430190",
        "id": 64740,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Single service ",
        "similarGroup": "4306",
        "similarItem": "4306Z2",
        "similarName": "单一服务",
        "type": 1,
        "updateTime": "2021-05-25 10:34:21"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "寄宿处预订",
    "goodsEnName": "Boarding house bookings",
    "goodsJpName": "宿泊施設の予約の取次ぎ",
    "goodsNo": "430104",
    "id": 56879,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": " Accommodation bureaux [hotels, boarding houses]",
    "similarGroup": "4301",
    "similarItem": "4301",
    "similarName": "提供餐饮,住宿服务",
    "type": 0,
    "updateTime": "2021-05-25 11:03:10"
  },
  {
    "category": "44",
    "categoryEnName": "Medical, beauty, horticulture services",
    "categoryName": "医疗美容园林",
    "children": [
      {
        "category": "44",
        "categoryEnName": "Medical, beauty, horticulture services",
        "categoryName": "医疗美容园林",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "帮助个人戒烟的健康护理服务",
        "goodsEnName": "Health care services to help individuals quit smoking",
        "goodsJpName": "個人の禁煙を助ける健康ケアサービス",
        "goodsNo": "",
        "id": 55535,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Medical service ",
        "similarGroup": "4401",
        "similarItem": "",
        "similarName": "医疗服务",
        "type": 0,
        "updateTime": "2021-05-25 11:05:36"
      },
      {
        "category": "44",
        "categoryEnName": "Medical, beauty, horticulture services",
        "categoryName": "医疗美容园林",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "美发服务",
        "goodsEnName": "Hairdressing service",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 60344,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Hygienism and beauty service ",
        "similarGroup": "4402",
        "similarItem": "4402",
        "similarName": "卫生、美容服务",
        "type": 0,
        "updateTime": "2021-05-25 10:27:52"
      },
      {
        "category": "44",
        "categoryEnName": "Medical, beauty, horticulture services",
        "categoryName": "医疗美容园林",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "兽医服务",
        "goodsEnName": "Veterinary service ",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 59854,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Providing service for animal ",
        "similarGroup": "4403",
        "similarItem": "4403",
        "similarName": "为动物提供服务",
        "type": 0,
        "updateTime": "2021-05-25 10:30:16"
      },
      {
        "category": "44",
        "categoryEnName": "Medical, beauty, horticulture services",
        "categoryName": "医疗美容园林",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "剪草机出租",
        "goodsEnName": "Lawn mower rental",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 57810,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Agriculture and horticulture ",
        "similarGroup": "4404",
        "similarItem": "4404",
        "similarName": "农业、园艺服务",
        "type": 0,
        "updateTime": "2021-05-25 11:36:20"
      },
      {
        "category": "44",
        "categoryEnName": "Medical, beauty, horticulture services",
        "categoryName": "医疗美容园林",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "7",
        "flagBit": 0,
        "goodsChName": "配镜服务",
        "goodsEnName": "Opticians’ services",
        "goodsJpName": "",
        "goodsNo": "440092",
        "id": 56795,
        "niceCodeLists": "",
        "remark": "修改：“眼镜行”改为“配镜服务”；修改注2",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Single service ",
        "similarGroup": "4405",
        "similarItem": "4405Z1",
        "similarName": "单一服务",
        "type": 1,
        "updateTime": "2021-05-25 10:27:50"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "帮助个人戒烟的健康护理服务",
    "goodsEnName": "Health care services to help individuals quit smoking",
    "goodsJpName": "個人の禁煙を助ける健康ケアサービス",
    "goodsNo": "",
    "id": 55535,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": " Medical service ",
    "similarGroup": "4401",
    "similarItem": "",
    "similarName": "医疗服务",
    "type": 0,
    "updateTime": "2021-05-25 11:05:36"
  },
  {
    "category": "45",
    "categoryEnName": "Legal, security services",
    "categoryName": "法律社会服务",
    "children": [
      {
        "category": "45",
        "categoryEnName": "Legal, security services",
        "categoryName": "法律社会服务",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "保镖服务",
        "goodsEnName": "Bodyguard service",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 58559,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Security service ",
        "similarGroup": "4501",
        "similarItem": "4501",
        "similarName": "安全服务",
        "type": 0,
        "updateTime": "2021-05-25 10:26:29"
      },
      {
        "category": "45",
        "categoryEnName": "Legal, security services",
        "categoryName": "法律社会服务",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "6",
        "flagBit": 0,
        "goodsChName": "社交护送（陪伴）",
        "goodsEnName": "Chaperoning",
        "goodsJpName": "社交界における付き添い",
        "goodsNo": "450002",
        "id": 57684,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Personnel service ",
        "similarGroup": "4502",
        "similarItem": "4502",
        "similarName": "提供人员服务",
        "type": 0,
        "updateTime": "2021-05-25 11:32:00"
      },
      {
        "category": "45",
        "categoryEnName": "Legal, security services",
        "categoryName": "法律社会服务",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "2",
        "flagBit": 0,
        "goodsChName": "晚礼服出租",
        "goodsEnName": "Evening dress rental",
        "goodsJpName": "イブニングドレスの貸与",
        "goodsNo": "450046",
        "id": 81407,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Provision of clothes ",
        "similarGroup": "4503",
        "similarItem": "4503",
        "similarName": "提供服饰服务",
        "type": 0,
        "updateTime": "2021-05-25 10:51:22"
      },
      {
        "category": "45",
        "categoryEnName": "Legal, security services",
        "categoryName": "法律社会服务",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "殡仪",
        "goodsEnName": "Funerary undertaking",
        "goodsJpName": "葬儀の執行",
        "goodsNo": "450057",
        "id": 62878,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Funeral service ",
        "similarGroup": "4504",
        "similarItem": "4504",
        "similarName": "殡仪服务",
        "type": 1,
        "updateTime": "2021-05-25 11:17:20"
      },
      {
        "category": "45",
        "categoryEnName": "Legal, security services",
        "categoryName": "法律社会服务",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "4",
        "flagBit": 0,
        "goodsChName": "开保险锁",
        "goodsEnName": "Opening of security locks",
        "goodsJpName": "安全錠前開け",
        "goodsNo": "450033",
        "id": 57054,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Single service ",
        "similarGroup": "4505",
        "similarItem": "4505Z1",
        "similarName": "单一服务",
        "type": 1,
        "updateTime": "2021-05-25 11:32:02"
      },
      {
        "category": "45",
        "categoryEnName": "Legal, security services",
        "categoryName": "法律社会服务",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "13",
        "flagBit": 0,
        "goodsChName": "版权管理",
        "goodsEnName": "Copyright management",
        "goodsJpName": "著作権の管理",
        "goodsNo": "450207",
        "id": 56571,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Legal services ",
        "similarGroup": "4506",
        "similarItem": "4506",
        "similarName": "法律服务",
        "type": 0,
        "updateTime": "2021-05-25 11:17:22"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "13",
    "flagBit": 0,
    "goodsChName": "版权管理",
    "goodsEnName": "Copyright management",
    "goodsJpName": "著作権の管理",
    "goodsNo": "450207",
    "id": 56571,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": " Legal services ",
    "similarGroup": "4506",
    "similarItem": "4506",
    "similarName": "法律服务",
    "type": 0,
    "updateTime": "2021-05-25 11:17:22"
  },
  {
    "category": "5",
    "categoryEnName": "Pharamaceuticals",
    "categoryName": "医药婴儿食品",
    "children": [
      {
        "category": "5",
        "categoryEnName": "Pharamaceuticals",
        "categoryName": "医药婴儿食品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "药用玉竹（药用植物根）",
        "goodsEnName": "Polygonatum odoratum for pharmaceutical purposes (medicinal plant root)",
        "goodsJpName": "薬用玉竹（薬用植物根）",
        "goodsNo": "",
        "id": 55381,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Medicines, disinfectors, herbal medicines, medicinal liquors",
        "similarGroup": "0501",
        "similarItem": "",
        "similarName": "药品,消毒剂,中药药材,药酒",
        "type": 0,
        "updateTime": "2021-05-25 11:01:43"
      },
      {
        "category": "5",
        "categoryEnName": "Pharamaceuticals",
        "categoryName": "医药婴儿食品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "活性炭膳食补充剂",
        "goodsEnName": "Active carbon dietary supplement",
        "goodsJpName": "活性炭食サプリメント",
        "goodsNo": "",
        "id": 55892,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Medical nutriments, dietary supplements for human being, baby food",
        "similarGroup": "0502",
        "similarItem": "",
        "similarName": "医用营养品,人用膳食补充剂,婴儿食品",
        "type": 0,
        "updateTime": "2021-05-25 11:07:48"
      },
      {
        "category": "5",
        "categoryEnName": "Pharamaceuticals",
        "categoryName": "医药婴儿食品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "活性炭空气除臭剂",
        "goodsEnName": "Active carbon air deodorant",
        "goodsJpName": "活性炭空気消臭剤",
        "goodsNo": "",
        "id": 55899,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Scavengers",
        "similarGroup": "0503",
        "similarItem": "",
        "similarName": "净化制剂",
        "type": 0,
        "updateTime": "2021-05-25 11:07:49"
      },
      {
        "category": "5",
        "categoryEnName": "Pharamaceuticals",
        "categoryName": "医药婴儿食品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "动物用维生素",
        "goodsEnName": "Animal vitamin",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 59126,
        "niceCodeLists": "",
        "remark": "可接受、非标准 2017-4",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Medicines for animals, dietary supplements for animals ",
        "similarGroup": "0504",
        "similarItem": "0504",
        "similarName": "兽药,动物用膳食补充剂",
        "type": 0,
        "updateTime": "2021-05-25 10:22:46"
      },
      {
        "category": "5",
        "categoryEnName": "Pharamaceuticals",
        "categoryName": "医药婴儿食品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "用于驱虫的樟木条",
        "goodsEnName": "Camphor stick for insect repellent",
        "goodsJpName": "虫よけに使うクスノキの枝",
        "goodsNo": "",
        "id": 55836,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Insecticide, mycocide, herbicide, pesticide",
        "similarGroup": "0505",
        "similarItem": "",
        "similarName": "杀虫剂,除莠剂,农药",
        "type": 0,
        "updateTime": "2021-05-25 10:55:35"
      },
      {
        "category": "5",
        "categoryEnName": "Pharamaceuticals",
        "categoryName": "医药婴儿食品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "浸抗菌制剂的薄纸",
        "goodsEnName": "Tissue of paper impregnated with antimicrobial agents",
        "goodsJpName": "抗菌剤のシート",
        "goodsNo": "",
        "id": 56116,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Hygienism articles, materials for bandaging and applying, health protection bags for medical purposes",
        "similarGroup": "0506",
        "similarItem": "",
        "similarName": "卫生用品,绷敷材料,医用保健袋",
        "type": 0,
        "updateTime": "2021-05-25 10:02:18"
      },
      {
        "category": "5",
        "categoryEnName": "Pharamaceuticals",
        "categoryName": "医药婴儿食品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "假牙用材料",
        "goodsEnName": "Denture material",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 60820,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Materials for choking chips, wax for dentists ",
        "similarGroup": "0507",
        "similarItem": "0507",
        "similarName": "填塞牙孔用料,牙科用蜡",
        "type": 0,
        "updateTime": "2021-05-25 10:23:21"
      },
      {
        "category": "5",
        "categoryEnName": "Pharamaceuticals",
        "categoryName": "医药婴儿食品",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "宠物用生理裤",
        "goodsEnName": "Pet pants",
        "goodsJpName": "ペット用生理ズボン",
        "goodsNo": "",
        "id": 189823,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Single goods",
        "similarGroup": "0508",
        "similarItem": "0508",
        "similarName": "单一商品",
        "type": 0,
        "updateTime": "2021-05-25 10:51:57"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "药用玉竹（药用植物根）",
    "goodsEnName": "Polygonatum odoratum for pharmaceutical purposes (medicinal plant root)",
    "goodsJpName": "薬用玉竹（薬用植物根）",
    "goodsNo": "",
    "id": 55381,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": "Medicines, disinfectors, herbal medicines, medicinal liquors",
    "similarGroup": "0501",
    "similarItem": "",
    "similarName": "药品,消毒剂,中药药材,药酒",
    "type": 0,
    "updateTime": "2021-05-25 11:01:43"
  },
  {
    "category": "6",
    "categoryEnName": "Metals",
    "categoryName": "金属五金器具",
    "children": [
      {
        "category": "6",
        "categoryEnName": "Metals",
        "categoryName": "金属五金器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "黄铜合金",
        "goodsEnName": "Brass alloy",
        "goodsJpName": "真鍮合金",
        "goodsNo": "",
        "id": 55269,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Common metals and their alloys, plates, all kinds of section bars (not including metal materials for jointing and railway ",
        "similarGroup": "0601",
        "similarItem": "",
        "similarName": "普通金属及其合金、板、各种型材（不包括焊接及铁路用金属材料）",
        "type": 0,
        "updateTime": "2021-05-25 10:23:53"
      },
      {
        "category": "6",
        "categoryEnName": "Metals",
        "categoryName": "金属五金器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "铜管",
        "goodsEnName": " copper pipe",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 57509,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Common metal tubes and their fittings",
        "similarGroup": "0602",
        "similarItem": "0602",
        "similarName": "普通金属管及其配件",
        "type": 0,
        "updateTime": "2021-05-25 10:24:27"
      },
      {
        "category": "6",
        "categoryEnName": "Metals",
        "categoryName": "金属五金器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "游泳池（金属结构）",
        "goodsEnName": "Swimming pools [structures] of metal",
        "goodsJpName": "金属製水泳用プール組立セット",
        "goodsNo": "060290",
        "id": 57341,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Metal construction materials, mobile metal buildings (not including construction trims)",
        "similarGroup": "0603",
        "similarItem": "0603",
        "similarName": "金属建筑材料,可移动金属建筑物（不包括建筑小五金）",
        "type": 0,
        "updateTime": "2021-05-25 11:06:52"
      },
      {
        "category": "6",
        "categoryEnName": "Metals",
        "categoryName": "金属五金器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "金属轨道",
        "goodsEnName": "Rails of metal",
        "goodsJpName": "金属製レール",
        "goodsNo": "060129",
        "id": 65139,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Metal materials for railway",
        "similarGroup": "0604",
        "similarItem": "0604",
        "similarName": "铁路用金属材料",
        "type": 0,
        "updateTime": "2021-05-25 11:08:59"
      },
      {
        "category": "6",
        "categoryEnName": "Metals",
        "categoryName": "金属五金器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "9",
        "flagBit": 0,
        "goodsChName": "普通金属线",
        "goodsEnName": "Wire of common metal",
        "goodsJpName": "金属製のワイヤ（貴金属製のものを除く。）",
        "goodsNo": "060108",
        "id": 56480,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Hawsers and metal wires、nets、straps not for electrical purposes",
        "similarGroup": "0605",
        "similarItem": "0605",
        "similarName": "非电气用缆索和金属线、网、带",
        "type": 1,
        "updateTime": "2021-05-25 11:18:49"
      },
      {
        "category": "6",
        "categoryEnName": "Metals",
        "categoryName": "金属五金器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "紧线夹头",
        "goodsEnName": "Combalong head",
        "goodsJpName": "ベッドロック",
        "goodsNo": "C060023",
        "id": 85901,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Metal fittings of metal, nonelectric",
        "similarGroup": "0606",
        "similarItem": "0606",
        "similarName": "缆绳用非电气金属附件",
        "type": 0,
        "updateTime": "2021-05-25 11:19:19"
      },
      {
        "category": "6",
        "categoryEnName": "Metals",
        "categoryName": "金属五金器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "金属环",
        "goodsEnName": "Rings of metal",
        "goodsJpName": "金属製リング",
        "goodsNo": "060038",
        "id": 57313,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Nails and standard fasteners (not including special standard fasteners for communication and vehicles)",
        "similarGroup": "0607",
        "similarItem": "0607",
        "similarName": "钉及标准紧固件",
        "type": 0,
        "updateTime": "2021-05-25 11:23:21"
      },
      {
        "category": "6",
        "categoryEnName": "Metals",
        "categoryName": "金属五金器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "金属闩",
        "goodsEnName": "Latches of metal",
        "goodsJpName": "金属製掛金",
        "goodsNo": "060167",
        "id": 56746,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Furniture and metal accessories for doors and windows",
        "similarGroup": "0608",
        "similarItem": "0608",
        "similarName": "家具及门窗的金属附件",
        "type": 0,
        "updateTime": "2021-05-25 11:49:20"
      },
      {
        "category": "6",
        "categoryEnName": "Metals",
        "categoryName": "金属五金器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "金属包头",
        "goodsEnName": "Ferrules of metal",
        "goodsJpName": "金属製石突き",
        "goodsNo": "060262",
        "id": 73049,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Miscellaneous hardware appliances for daily use",
        "similarGroup": "0609",
        "similarItem": "0609",
        "similarName": "日用五金器具",
        "type": 0,
        "updateTime": "2021-05-25 11:22:11"
      },
      {
        "category": "6",
        "categoryEnName": "Metals",
        "categoryName": "金属五金器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "弹簧锁",
        "goodsEnName": "Spring locks",
        "goodsJpName": "ばね式の締まり金具",
        "goodsNo": "060153",
        "id": 77697,
        "niceCodeLists": "",
        "remark": "修改备注",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Non-electronic locks",
        "similarGroup": "0610",
        "similarItem": "0610",
        "similarName": "非电子锁",
        "type": 0,
        "updateTime": "2021-05-25 11:22:12"
      },
      {
        "category": "6",
        "categoryEnName": "Metals",
        "categoryName": "金属五金器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "枪械保险柜",
        "goodsEnName": "Firearms safe",
        "goodsJpName": "銃器金庫",
        "goodsNo": "",
        "id": 69374,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Safe boxes, metal cupboards",
        "similarGroup": "0611",
        "similarItem": "0611",
        "similarName": "保险箱柜",
        "type": 0,
        "updateTime": "2021-05-25 10:24:03"
      },
      {
        "category": "6",
        "categoryEnName": "Metals",
        "categoryName": "金属五金器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "钩子（金属器具）",
        "goodsEnName": "Hooks [metal hardware]",
        "goodsJpName": "金属製フック",
        "goodsNo": "060105",
        "id": 60050,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Metal utensils, metal components (not mechanical parts)",
        "similarGroup": "0612",
        "similarItem": "0612",
        "similarName": "金属器具,金属硬件（非机器零件）",
        "type": 0,
        "updateTime": "2021-05-25 10:15:08"
      },
      {
        "category": "6",
        "categoryEnName": "Metals",
        "categoryName": "金属五金器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "金属大桶",
        "goodsEnName": "Vats of metal",
        "goodsJpName": "金属製大おけ",
        "goodsNo": "060354",
        "id": 60652,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Metal containers",
        "similarGroup": "0613",
        "similarItem": "0613",
        "similarName": "金属容器",
        "type": 0,
        "updateTime": "2021-05-25 10:50:59"
      },
      {
        "category": "6",
        "categoryEnName": "Metals",
        "categoryName": "金属五金器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "不发光金属门牌",
        "goodsEnName": "House numbers of metal, nonluminous",
        "goodsJpName": "金属製家屋番号札（発光式のものを除く。）",
        "goodsNo": "060196",
        "id": 58993,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Metal labels",
        "similarGroup": "0614",
        "similarItem": "0614",
        "similarName": "金属标牌",
        "type": 0,
        "updateTime": "2021-05-25 10:51:01"
      },
      {
        "category": "6",
        "categoryEnName": "Metals",
        "categoryName": "金属五金器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "马掌钉",
        "goodsEnName": "Horseshoe nails",
        "goodsJpName": "蹄鉄用くぎ",
        "goodsNo": "060077",
        "id": 66539,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Metal work for animals",
        "similarGroup": "0615",
        "similarItem": "0615",
        "similarName": "动物用金属制品",
        "type": 0,
        "updateTime": "2021-05-25 10:51:02"
      },
      {
        "category": "6",
        "categoryEnName": "Metals",
        "categoryName": "金属五金器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "焊条",
        "goodsEnName": "Welding rod",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 64957,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Metal materials for jointing (not including plastic solder wires)",
        "similarGroup": "0616",
        "similarItem": "0616",
        "similarName": "焊接用金属材料（不包括塑料焊丝）",
        "type": 0,
        "updateTime": "2021-05-25 10:23:22"
      },
      {
        "category": "6",
        "categoryEnName": "Metals",
        "categoryName": "金属五金器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "金属下锚柱",
        "goodsEnName": "Mooring bollards of metal",
        "goodsJpName": "ボートけい留用金属製ボラード",
        "goodsNo": "060271",
        "id": 62668,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Anchors, metal floating dock for berthing the ships, mooring bollards of metal",
        "similarGroup": "0617",
        "similarItem": "0617",
        "similarName": "锚,停船用金属浮动船坞,金属下锚桩",
        "type": 1,
        "updateTime": "2021-05-25 11:25:02"
      },
      {
        "category": "6",
        "categoryEnName": "Metals",
        "categoryName": "金属五金器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "8,9",
        "flagBit": 0,
        "goodsChName": "金属制身份鉴别手环",
        "goodsEnName": "Identification bracelets of metal",
        "goodsJpName": "病院用金属製識別腕環",
        "goodsNo": "060051",
        "id": 92355,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Handcuffs, metal identification bracelets for hospital use",
        "similarGroup": "0618",
        "similarItem": "0618",
        "similarName": "手铐,金属制身份鉴别手环",
        "type": 1,
        "updateTime": "2021-05-25 10:09:40"
      },
      {
        "category": "6",
        "categoryEnName": "Metals",
        "categoryName": "金属五金器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "8,9",
        "flagBit": 0,
        "goodsChName": "金属风向标",
        "goodsEnName": "Weather- or wind vanes of metal / Weather vanes of metal / Wind vanes of metal",
        "goodsJpName": "金属製風向計",
        "goodsNo": "060148",
        "id": 71173,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Metal paddles for measuring meteorological phenomena and wind-force, metal weather cocks",
        "similarGroup": "0619",
        "similarItem": "0619",
        "similarName": "（测气象或风力的）金属风标",
        "type": 1,
        "updateTime": "2021-05-25 11:25:02"
      },
      {
        "category": "6",
        "categoryEnName": "Metals",
        "categoryName": "金属五金器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "9",
        "flagBit": 0,
        "goodsChName": "树木金属保护器",
        "goodsEnName": "Tree protectors of metal",
        "goodsJpName": "樹木用の金属製プロテクター",
        "goodsNo": "060028",
        "id": 74862,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Metal plant protectors",
        "similarGroup": "0620",
        "similarItem": "0620",
        "similarName": "金属植物保护器",
        "type": 1,
        "updateTime": "2021-05-25 11:25:03"
      },
      {
        "category": "6",
        "categoryEnName": "Metals",
        "categoryName": "金属五金器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "8,9",
        "flagBit": 0,
        "goodsChName": "捕野兽陷阱",
        "goodsEnName": "Traps for wild animals",
        "goodsJpName": "野生動物用金属製わな",
        "goodsNo": "060025",
        "id": 126676,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Traps",
        "similarGroup": "0621",
        "similarItem": "0621",
        "similarName": " 捕野兽陷阱",
        "type": 1,
        "updateTime": "2021-05-25 11:08:52"
      },
      {
        "category": "6",
        "categoryEnName": "Metals",
        "categoryName": "金属五金器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "普通金属小塑像",
        "goodsEnName": "Figurines [statuettes] of common metal",
        "goodsJpName": "金属製の小立像（貴金属製のものを除く。）",
        "goodsNo": "060382",
        "id": 62871,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Common metal works of art, bronze (works of art)",
        "similarGroup": "0622",
        "similarItem": "0622",
        "similarName": "普通金属艺术品,青铜（艺术品）",
        "type": 0,
        "updateTime": "2021-05-25 11:08:52"
      },
      {
        "category": "6",
        "categoryEnName": "Metals",
        "categoryName": "金属五金器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "锰矿石",
        "goodsEnName": "Manganese ore ",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 61611,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Ores, ore sands",
        "similarGroup": "0623",
        "similarItem": "0623",
        "similarName": "矿石,矿砂",
        "type": 0,
        "updateTime": "2021-05-25 10:24:04"
      },
      {
        "category": "6",
        "categoryEnName": "Metals",
        "categoryName": "金属五金器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "金属墓",
        "goodsEnName": "Tombs of metal",
        "goodsJpName": "金属製墓標",
        "goodsNo": "060254",
        "id": 56634,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Metal coffins (for burying), metal coffin fastenings, metal equipments for coffins",
        "similarGroup": "0624",
        "similarItem": "0624",
        "similarName": "金属棺（埋葬用）,金属棺材扣件,棺材用金属器材",
        "type": 0,
        "updateTime": "2021-05-25 11:03:04"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "黄铜合金",
    "goodsEnName": "Brass alloy",
    "goodsJpName": "真鍮合金",
    "goodsNo": "",
    "id": 55269,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": " Common metals and their alloys, plates, all kinds of section bars (not including metal materials for jointing and railway ",
    "similarGroup": "0601",
    "similarItem": "",
    "similarName": "普通金属及其合金、板、各种型材（不包括焊接及铁路用金属材料）",
    "type": 0,
    "updateTime": "2021-05-25 10:23:53"
  },
  {
    "category": "7",
    "categoryEnName": "Machines and machine tools",
    "categoryName": "机器马达部件",
    "children": [
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "水稻插秧机",
        "goodsEnName": "Rice transplanter",
        "goodsJpName": "田植え機",
        "goodsNo": "",
        "id": 56249,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Agricultural machines and parts (not including small farm implement)",
        "similarGroup": "0701",
        "similarItem": "",
        "similarName": "农业用机械及部件（不包括小农具）",
        "type": 0,
        "updateTime": "2021-05-25 10:21:54"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "青饲料切割机",
        "goodsEnName": "Succulence cu-off machine",
        "goodsJpName": "靑飼料切断機",
        "goodsNo": "C070007",
        "id": 62619,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Machines and equipments for fishing and livestock breeding",
        "similarGroup": "0702",
        "similarItem": "0702（二）",
        "similarName": "渔牧业用机械及器具",
        "type": 0,
        "updateTime": "2021-05-25 10:39:04"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "原木传送机",
        "goodsEnName": "Log transporter",
        "goodsJpName": "原木用コンベヤー ",
        "goodsNo": "C070015",
        "id": 59196,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Wood cutting, wood sawing, wood working and matches producing machines and equipment",
        "similarGroup": "0703",
        "similarItem": "0703",
        "similarName": "伐木、锯木、木材加工及火柴生产用机械及器具",
        "type": 0,
        "updateTime": "2021-05-25 11:17:45"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "造纸用打浆机",
        "goodsEnName": "Beating machine for making paper",
        "goodsJpName": "パルプ化用装置（製紙工業用）",
        "goodsNo": "C070021",
        "id": 57677,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Machines and equipments for paper making and paper products processing industry",
        "similarGroup": "0704",
        "similarItem": "0704（一）",
        "similarName": "造纸及加工纸制品工业用机械及器具",
        "type": 0,
        "updateTime": "2021-05-25 11:16:41"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "9,16,18",
        "flagBit": 0,
        "goodsChName": "印刷机器",
        "goodsEnName": "Printing machines",
        "goodsJpName": "印刷機",
        "goodsNo": "070218",
        "id": 59987,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Machines and equipments for printing industry",
        "similarGroup": "0705",
        "similarItem": "0705",
        "similarName": "印刷工业用机械及器具",
        "type": 0,
        "updateTime": "2021-05-25 10:53:19"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "维尼龙抽丝设备",
        "goodsEnName": "Vinylo silking device",
        "goodsJpName": "ビニロン紡糸装置",
        "goodsNo": "C070075",
        "id": 58678,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Machines and parts for fibre processing and spinning, knitting industry",
        "similarGroup": "0706",
        "similarItem": "0706",
        "similarName": "纤维加工及纺织、针织工业用机械及部件",
        "type": 0,
        "updateTime": "2021-05-25 11:35:00"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "上浆机",
        "goodsEnName": "Sizing machines",
        "goodsJpName": "サイジング機械",
        "goodsNo": "070013",
        "id": 65937,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Printing and dyeing machines",
        "similarGroup": "0707",
        "similarItem": "0707",
        "similarName": "印染工业用机械",
        "type": 1,
        "updateTime": "2021-05-25 11:08:49"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "揉捻机（制茶工业用）",
        "goodsEnName": "Rolling machine (for tea making industry)",
        "goodsJpName": "圧延機（製茶工業用）",
        "goodsNo": "C070090",
        "id": 71418,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Machines for tea manufacturing industry",
        "similarGroup": "0708",
        "similarItem": "0708",
        "similarName": "制茶工业用机械",
        "type": 0,
        "updateTime": "2021-05-25 10:11:19"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "石磨",
        "goodsEnName": "Millstones",
        "goodsJpName": "食料加工用・陶土加工用臼石",
        "goodsNo": "070262",
        "id": 57229,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Machines and parts for food industry",
        "similarGroup": "0709",
        "similarItem": "0709",
        "similarName": "食品业用机械及部件",
        "type": 0,
        "updateTime": "2021-05-25 11:35:04"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "9",
        "flagBit": 0,
        "goodsChName": "酿造机器",
        "goodsEnName": "Brewing machines",
        "goodsJpName": "醸造用機械",
        "goodsNo": "070066",
        "id": 63123,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Machines for brewing and beverage industry",
        "similarGroup": "0710",
        "similarItem": "0710",
        "similarName": "酿造、饮料工业用机械",
        "type": 1,
        "updateTime": "2021-05-25 10:33:13"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "8",
        "flagBit": 0,
        "goodsChName": "工业用卷烟机",
        "goodsEnName": "Cigarette machines for industrial purposes",
        "goodsJpName": "工業用紙巻たばこ製造機械",
        "goodsNo": "070103",
        "id": 61408,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Machines for tobacco industry",
        "similarGroup": "0711",
        "similarItem": "0711",
        "similarName": "烟草工业用机械",
        "type": 0,
        "updateTime": "2021-05-25 10:33:14"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "8",
        "flagBit": 0,
        "goodsChName": "皮革修整机",
        "goodsEnName": "Leather neatening machine",
        "goodsJpName": "革用トリマ",
        "goodsNo": "C070125",
        "id": 57082,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Machines for leather industry",
        "similarGroup": "0712",
        "similarItem": "0712",
        "similarName": "皮革工业用机械",
        "type": 0,
        "updateTime": "2021-05-25 10:33:16"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "锁扣机",
        "goodsEnName": "Button locking machine",
        "goodsJpName": "ボタンロッキング装置",
        "goodsNo": "C070127",
        "id": 63340,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Machines for sewing and shoemaking industry",
        "similarGroup": "0713",
        "similarItem": "0713",
        "similarName": "缝纫、制鞋工业用机械",
        "type": 0,
        "updateTime": "2021-05-25 11:32:04"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "滚挡泥板机",
        "goodsEnName": "Rolling fenderboard machine",
        "goodsJpName": "スプラッシャー製造機械",
        "goodsNo": "C070137",
        "id": 59182,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Device for bicycle industry",
        "similarGroup": "0714",
        "similarItem": "0714",
        "similarName": "自行车工业用设备",
        "type": 0,
        "updateTime": "2021-05-25 11:20:27"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "陶匠用旋轮",
        "goodsEnName": "Potters’ wheels",
        "goodsJpName": "陶工用ろくろ",
        "goodsNo": "070420",
        "id": 69486,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Ceramic, brick and tile making machine",
        "similarGroup": "0715",
        "similarItem": "0715",
        "similarName": "陶瓷、砖、瓦制造机械",
        "type": 1,
        "updateTime": "2021-05-25 11:32:06"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "9",
        "flagBit": 0,
        "goodsChName": "雕刻机",
        "goodsEnName": "Engraving machines",
        "goodsJpName": "金属加工用・木工用・石材加工\n用彫刻盤",
        "goodsNo": "070207",
        "id": 59756,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Engraving machines for industry use, trueing machines",
        "similarGroup": "0716",
        "similarItem": "0716",
        "similarName": "工业用雕刻、打标机械",
        "type": 1,
        "updateTime": "2021-05-25 11:32:30"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "上电池底机",
        "goodsEnName": "Bottom lid installer for battery",
        "goodsJpName": "バッテリー底蓋組立機械",
        "goodsNo": "C070142",
        "id": 87980,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Battery making machine",
        "similarGroup": "0717",
        "similarItem": "0717",
        "similarName": "制电池机械",
        "type": 0,
        "updateTime": "2021-05-25 10:15:03"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "制笔机械",
        "goodsEnName": "Pen production machinery",
        "goodsJpName": "ペンの製造機械",
        "goodsNo": "C070152",
        "id": 69381,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Daily sundry goods processing machine",
        "similarGroup": "0718",
        "similarItem": "0718",
        "similarName": "日用杂品加工机械",
        "type": 0,
        "updateTime": "2021-05-25 11:20:28"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "8,9,16,18",
        "flagBit": 0,
        "goodsChName": "制搪瓷机械",
        "goodsEnName": "Enamel production machinery",
        "goodsJpName": "ほうろ製造機械",
        "goodsNo": "C070154",
        "id": 111171,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Enamel production machinery",
        "similarGroup": "0719",
        "similarItem": "0719",
        "similarName": "制搪瓷机械",
        "type": 1,
        "updateTime": "2021-05-25 11:20:29"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "8,9,16,18",
        "flagBit": 0,
        "goodsChName": "制灯泡机械",
        "goodsEnName": "Bulb production machinery",
        "goodsJpName": "電球生産機械",
        "goodsNo": "C070155",
        "id": 143035,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Bulb production machinery",
        "similarGroup": "0720",
        "similarItem": "0720",
        "similarName": "制灯泡机械",
        "type": 1,
        "updateTime": "2021-05-25 11:27:08"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "捆扎机",
        "goodsEnName": "Sheaf-binding machines",
        "goodsJpName": "結束機械",
        "goodsNo": "070052",
        "id": 62318,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Wrapping machines (not including wrapping machines special for whole set apparatus)",
        "similarGroup": "0721",
        "similarItem": "0721",
        "similarName": "包装机械（不包括成套设备专用包装机械）",
        "type": 0,
        "updateTime": "2021-05-25 11:27:08"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "蜂窝煤机",
        "goodsEnName": "Honeycomb-shaped briquette production machine",
        "goodsJpName": "ハニカム状練炭製造機",
        "goodsNo": "C070158",
        "id": 91144,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Civil briquette processing machine",
        "similarGroup": "0722",
        "similarItem": "0722",
        "similarName": "民用煤加工机械",
        "type": 0,
        "updateTime": "2021-05-25 11:20:31"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "混合机（机器）",
        "goodsEnName": "Mixers [machines]",
        "goodsJpName": "混合機",
        "goodsNo": "070267",
        "id": 59238,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Equipments for kitchen and household purpose (not including cooking, electrical heating equipments and small tools for kitchen use)",
        "similarGroup": "0723",
        "similarItem": "0709",
        "similarName": "厨房家用器具（不包括烹调、电气加热设备及厨房手工具）",
        "type": 0,
        "updateTime": "2021-05-25 11:35:19"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "5",
        "flagBit": 0,
        "goodsChName": "家用洗衣机",
        "goodsEnName": "Washing machine",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 77522,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Washing machines",
        "similarGroup": "0724",
        "similarItem": "0724",
        "similarName": "洗衣机",
        "type": 0,
        "updateTime": "2021-05-25 10:21:45"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "8",
        "flagBit": 0,
        "goodsChName": "离心碾磨机",
        "goodsEnName": "Centrifugal mills",
        "goodsJpName": "遠心製粉機",
        "goodsNo": "070087",
        "id": 59112,
        "niceCodeLists": "",
        "remark": "2018版【修改注3】",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Machines and parts of pharmaceutical industry",
        "similarGroup": "0725",
        "similarItem": "0709",
        "similarName": "制药工业用机械及部件",
        "type": 0,
        "updateTime": "2021-05-25 11:35:20"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "干塑模压瓦机",
        "goodsEnName": "Tile pressing machine for dry mould",
        "goodsJpName": "プラスチック・スクィーズキャスティング",
        "goodsNo": "C070178",
        "id": 59371,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Machines for rubber and plastic industry",
        "similarGroup": "0726",
        "similarItem": "0726",
        "similarName": "橡胶、塑料工业机械",
        "type": 0,
        "updateTime": "2021-05-25 11:27:37"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "玻璃加工机",
        "goodsEnName": "Glass working machines",
        "goodsJpName": "ガラス加工機械",
        "goodsNo": "070382",
        "id": 63746,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Machines for glass industry",
        "similarGroup": "0727",
        "similarItem": "0727",
        "similarName": "玻璃工业用机械",
        "type": 1,
        "updateTime": "2021-05-25 11:23:22"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "9,16,18",
        "flagBit": 0,
        "goodsChName": "化肥制造设备",
        "goodsEnName": "Fertilizer plant",
        "goodsJpName": "肥料製造設備",
        "goodsNo": "C070191",
        "id": 94406,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Fertilizer plant",
        "similarGroup": "0728",
        "similarItem": "0728",
        "similarName": "化肥设备",
        "type": 1,
        "updateTime": "2021-05-25 10:14:34"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "纯碱制造设备",
        "goodsEnName": "Soda plant",
        "goodsJpName": "ソーダ製造設備",
        "goodsNo": "C070201",
        "id": 56949,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Other machines for chemical industry",
        "similarGroup": "0729",
        "similarItem": "0729",
        "similarName": "其他化学工业用机械",
        "type": 0,
        "updateTime": "2021-05-25 10:14:35"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "采掘机",
        "goodsEnName": "Extractors for mines",
        "goodsJpName": "鉱山用抽出器",
        "goodsNo": "070185",
        "id": 58006,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Machines for geological exploration, mining, mineral separation ",
        "similarGroup": "0730",
        "similarItem": "0730",
        "similarName": "地质勘探、采矿、选矿用机械",
        "type": 1,
        "updateTime": "2021-05-25 11:23:57"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "补炉机",
        "goodsEnName": "Patching machine",
        "goodsJpName": "パッチングマシン",
        "goodsNo": "C070231",
        "id": 60183,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Device for smelt industry",
        "similarGroup": "0731",
        "similarItem": "0731",
        "similarName": "冶炼工业用设备",
        "type": 0,
        "updateTime": "2021-05-25 11:23:55"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "16,18",
        "flagBit": 0,
        "goodsChName": "石油化工设备",
        "goodsEnName": "Petrochemical plant",
        "goodsJpName": "石油化学工業設備",
        "goodsNo": "C070237",
        "id": 65671,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Machines for oil development and refinement",
        "similarGroup": "0732",
        "similarItem": "0732",
        "similarName": "石油开采、精炼工业用设备",
        "type": 0,
        "updateTime": "2021-05-25 11:23:55"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "土方机械",
        "goodsEnName": "Earth moving machines",
        "goodsJpName": "土工機械",
        "goodsNo": "070417",
        "id": 59462,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Construction, railway and civil engineering machines",
        "similarGroup": "0733",
        "similarItem": "0733",
        "similarName": "建筑、铁道、土木工程用机械",
        "type": 0,
        "updateTime": "2021-05-25 10:16:29"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "带式输送机",
        "goodsEnName": "Belt conveyors",
        "goodsJpName": "ベルトコンベヤー",
        "goodsNo": "070037",
        "id": 56592,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Lifting and transporting machines",
        "similarGroup": "0734",
        "similarItem": "0734",
        "similarName": "起重运输机械",
        "type": 0,
        "updateTime": "2021-05-25 11:23:30"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "自动冲压机",
        "goodsEnName": "Automatic punching machine",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 60673,
        "niceCodeLists": "",
        "remark": "可接受，非标准",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Forge and press devices",
        "similarGroup": "0735",
        "similarItem": "0735",
        "similarName": "锻压设备",
        "type": 0,
        "updateTime": "2021-05-25 10:17:59"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "8",
        "flagBit": 0,
        "goodsChName": "铸模机",
        "goodsEnName": "Moulding machines / Molding machines",
        "goodsJpName": "金属加工用・食料加工用・陶磁\n器加工用・プラスチック加工用\nの造形機械",
        "goodsNo": "070278",
        "id": 56466,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Foundry machines",
        "similarGroup": "0736",
        "similarItem": "0736",
        "similarName": "铸造机械",
        "type": 0,
        "updateTime": "2021-05-25 11:25:47"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "蒸汽机锅炉",
        "goodsEnName": "Steam engine boilers",
        "goodsJpName": "蒸気機関ボイラー",
        "goodsNo": "070429",
        "id": 58328,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Steam powered equipment",
        "similarGroup": "0737",
        "similarItem": "0737",
        "similarName": "蒸汽动力设备",
        "type": 0,
        "updateTime": "2021-05-25 11:26:13"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "3",
        "flagBit": 0,
        "goodsChName": "化油器",
        "goodsEnName": "Carburettor",
        "goodsJpName": "キャブレター",
        "goodsNo": "C070280",
        "id": 62654,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Internal combustion powered equipment",
        "similarGroup": "0738",
        "similarItem": "0738",
        "similarName": "内燃动力设备",
        "type": 0,
        "updateTime": "2021-05-25 11:35:47"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "9",
        "flagBit": 0,
        "goodsChName": "风力动力设备",
        "goodsEnName": "Wind powered equipment",
        "goodsJpName": "風力動力機器",
        "goodsNo": "C070281",
        "id": 59693,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Wind and water powered equipment",
        "similarGroup": "0739",
        "similarItem": "0739",
        "similarName": "风力、水力动力设备",
        "type": 0,
        "updateTime": "2021-05-25 11:35:48"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "8",
        "flagBit": 0,
        "goodsChName": "图钉机",
        "goodsEnName": "Thumbtack machine",
        "goodsJpName": "画鋲リベット製造機",
        "goodsNo": "C070287",
        "id": 78852,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Needle and nail machine for offices",
        "similarGroup": "0740",
        "similarItem": "0740",
        "similarName": "制办公用针钉机械",
        "type": 0,
        "updateTime": "2021-05-25 11:26:16"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "9",
        "flagBit": 0,
        "goodsChName": "制纽扣机",
        "goodsEnName": "Fastener machine",
        "goodsJpName": "ボタン製造機",
        "goodsNo": "C070289",
        "id": 145821,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Fastener and slide fastener machines",
        "similarGroup": "0741",
        "similarItem": "0741",
        "similarName": "制纽扣拉链机械",
        "type": 1,
        "updateTime": "2021-05-25 11:26:16"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "刨床",
        "goodsEnName": "Planing machines",
        "goodsJpName": "平削り盤",
        "goodsNo": "070321",
        "id": 59042,
        "niceCodeLists": "",
        "remark": "修改，编号由C070306改为070321",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Metal cutting machine tools, cutting equipment and other metal processing machines",
        "similarGroup": "0742",
        "similarItem": "0742（一）",
        "similarName": "金属切削机床,切削工具和其他金属加工机械",
        "type": 0,
        "updateTime": "2021-05-25 11:22:24"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "风动手工具",
        "goodsEnName": "Pneumatic hand tool",
        "goodsJpName": "空気手工具",
        "goodsNo": "C070326",
        "id": 58020,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Hand-held tools, other than hand-operated",
        "similarGroup": "0743",
        "similarItem": "0743",
        "similarName": "非手动的手持工具",
        "type": 0,
        "updateTime": "2021-05-25 11:22:54"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "9",
        "flagBit": 0,
        "goodsChName": "静电工业设备",
        "goodsEnName": "Electrostatic industry device",
        "goodsJpName": "静電工業装置",
        "goodsNo": "C070329",
        "id": 62262,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Electrostatic and electronic industry device",
        "similarGroup": "0744",
        "similarItem": "0744",
        "similarName": "静电、电子工业用设备",
        "type": 0,
        "updateTime": "2021-05-25 11:22:55"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "9",
        "flagBit": 0,
        "goodsChName": "光学冷加工设备",
        "goodsEnName": "Optical cold working equipment",
        "goodsJpName": "光学冷間加工装置",
        "goodsNo": "C070332",
        "id": 91557,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Equipments for optical industry",
        "similarGroup": "0745",
        "similarItem": "0745",
        "similarName": "光学工业用设备",
        "type": 0,
        "updateTime": "2021-05-25 11:22:55"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "稀有气体提取设备",
        "goodsEnName": "Rare gas extracting equipment",
        "goodsJpName": "希有気体抽出装置",
        "goodsNo": "C070336",
        "id": 70221,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Gas separation plant",
        "similarGroup": "0746",
        "similarItem": "0746",
        "similarName": "气体分离设备",
        "type": 0,
        "updateTime": "2021-05-25 11:22:55"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "9",
        "flagBit": 0,
        "goodsChName": "喷漆机",
        "goodsEnName": "Paint spraying machine",
        "goodsJpName": "ペツント吹付機",
        "goodsNo": "C070339",
        "id": 103352,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Paint spraying machine",
        "similarGroup": "0747",
        "similarItem": "0747",
        "similarName": "喷漆机具",
        "type": 0,
        "updateTime": "2021-05-25 11:19:41"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "点火式磁发电机",
        "goodsEnName": "Igniting magnetos",
        "goodsJpName": "点火用マグネト発電機",
        "goodsNo": "070244",
        "id": 56487,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Dynamos, motors and engines for other than for land vehicles use and other kinds of parts of motors",
        "similarGroup": "0748",
        "similarItem": "0748（一）",
        "similarName": "发电机、非陆地车辆用马达和引擎及其零部件",
        "type": 0,
        "updateTime": "2021-05-25 11:22:57"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "污泥泵",
        "goodsEnName": "Sludge pump",
        "goodsJpName": "汚泥ポンプ",
        "goodsNo": "",
        "id": 56263,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Pumps, compressed air machines, blowing machines, valves, hydraulic components, pneumatic components",
        "similarGroup": "0749",
        "similarItem": "",
        "similarName": "泵,阀,气体压缩机,风机,液压元件,气动元件",
        "type": 0,
        "updateTime": "2021-05-25 10:23:28"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "机器用凿子",
        "goodsEnName": "Chisels for machines",
        "goodsJpName": "金属加工用・木工用・石材加工\n用たがね",
        "goodsNo": "070107",
        "id": 57215,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Shaft couplings, belts and other parts of machines",
        "similarGroup": "0750",
        "similarItem": "0750（一）",
        "similarName": "机器传动用联轴节,传动带及其他机器零部件",
        "type": 0,
        "updateTime": "2021-05-25 10:40:43"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "电弧焊接设备",
        "goodsEnName": "Electric arc welding apparatus",
        "goodsJpName": "アーク溶接装置",
        "goodsNo": "070530",
        "id": 57103,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Welding machines",
        "similarGroup": "0751",
        "similarItem": "0751",
        "similarName": "焊接机械",
        "type": 0,
        "updateTime": "2021-05-25 11:06:36"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "蒸汽清洗机",
        "goodsEnName": "Steam cleaning machine",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 57264,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Washing and waste disposals machine",
        "similarGroup": "0752",
        "similarItem": "0752",
        "similarName": "清洁、废物处理机械",
        "type": 0,
        "updateTime": "2021-05-25 10:23:00"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "制动液更换加注机",
        "goodsEnName": "Filling machine for replacing brake fluid  ",
        "goodsJpName": "ブレーキ液交換注機",
        "goodsNo": "",
        "id": 55710,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Single goods",
        "similarGroup": "0753",
        "similarItem": "",
        "similarName": "单一商品",
        "type": 0,
        "updateTime": "2021-05-25 11:05:40"
      },
      {
        "category": "7",
        "categoryEnName": "Machines and machine tools",
        "categoryName": "机器马达部件",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "真空喷镀机械",
        "goodsEnName": "Vacuum spray metallizing machine",
        "goodsJpName": "真空溶射機械",
        "goodsNo": "C070443",
        "id": 69745,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Electroplating devices",
        "similarGroup": "0754",
        "similarItem": "0754",
        "similarName": "电镀设备",
        "type": 0,
        "updateTime": "2021-05-25 11:24:44"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "制动液更换加注机",
    "goodsEnName": "Filling machine for replacing brake fluid  ",
    "goodsJpName": "ブレーキ液交換注機",
    "goodsNo": "",
    "id": 55710,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": "Single goods",
    "similarGroup": "0753",
    "similarItem": "",
    "similarName": "单一商品",
    "type": 0,
    "updateTime": "2021-05-25 11:05:40"
  },
  {
    "category": "8",
    "categoryEnName": "Cutlery, hand tools",
    "categoryName": "刀叉手工器具",
    "children": [
      {
        "category": "8",
        "categoryEnName": "Cutlery, hand tools",
        "categoryName": "刀叉手工器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "磨剃刀的皮带",
        "goodsEnName": "Leather strops",
        "goodsJpName": "革砥",
        "goodsNo": "080006",
        "id": 59098,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Hand Lapping Equipments",
        "similarGroup": "0801",
        "similarItem": "0801",
        "similarName": "手动研磨器具",
        "type": 0,
        "updateTime": "2021-05-25 11:24:45"
      },
      {
        "category": "8",
        "categoryEnName": "Cutlery, hand tools",
        "categoryName": "刀叉手工器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "农用宽叉",
        "goodsEnName": "Agricultural wide fork",
        "goodsJpName": "農用フォーク",
        "goodsNo": "",
        "id": 55717,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Small farm implements (not including knives and scissors for agriculture and horticulture)",
        "similarGroup": "0802",
        "similarItem": "",
        "similarName": "小农具（不包括农业、园艺用刀剪）",
        "type": 0,
        "updateTime": "2021-05-25 11:05:40"
      },
      {
        "category": "8",
        "categoryEnName": "Cutlery, hand tools",
        "categoryName": "刀叉手工器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "手动起草皮铲",
        "goodsEnName": "Drafting shovel, hand-operated",
        "goodsJpName": "手で皮を作るスコップ",
        "goodsNo": "",
        "id": 55724,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Small tools for forestry and horticulture",
        "similarGroup": "0803",
        "similarItem": "",
        "similarName": "林业、园艺用手工具",
        "type": 0,
        "updateTime": "2021-05-25 11:05:40"
      },
      {
        "category": "8",
        "categoryEnName": "Cutlery, hand tools",
        "categoryName": "刀叉手工器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "牲畜打记号用工具",
        "goodsEnName": "Cattle marking tools",
        "goodsJpName": "家畜用烙印用具",
        "goodsNo": "080031",
        "id": 121328,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Small tools for animal husbandry",
        "similarGroup": "0804",
        "similarItem": "0804",
        "similarName": "畜牧业用手工具",
        "type": 0,
        "updateTime": "2021-05-25 10:35:30"
      },
      {
        "category": "8",
        "categoryEnName": "Cutlery, hand tools",
        "categoryName": "刀叉手工器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "鱼叉",
        "goodsEnName": "Harpoons",
        "goodsJpName": "もり",
        "goodsNo": "080140",
        "id": 78222,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Small tools for fishery industry",
        "similarGroup": "0805",
        "similarItem": "0805",
        "similarName": "渔业用手工具",
        "type": 1,
        "updateTime": "2021-05-25 11:25:15"
      },
      {
        "category": "8",
        "categoryEnName": "Cutlery, hand tools",
        "categoryName": "刀叉手工器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "指甲刀",
        "goodsEnName": "Nail clippers",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 57607,
        "niceCodeLists": "",
        "remark": "可接受，非标准",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Hair dressing tools, nail clippers, apparatus for tattooing",
        "similarGroup": "0806",
        "similarItem": "0806",
        "similarName": "理发工具,修指甲刀,文身器具",
        "type": 0,
        "updateTime": "2021-05-25 10:44:24"
      },
      {
        "category": "8",
        "categoryEnName": "Cutlery, hand tools",
        "categoryName": "刀叉手工器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "胸压式手摇钻",
        "goodsEnName": "Breast drills",
        "goodsJpName": "胸当てぎり",
        "goodsNo": "080199",
        "id": 56711,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Adynamic small tools (not including knives and scissors)",
        "similarGroup": "0807",
        "similarItem": "0807",
        "similarName": "非动力手工具（不包括刀、剪）",
        "type": 0,
        "updateTime": "2021-05-25 10:40:31"
      },
      {
        "category": "8",
        "categoryEnName": "Cutlery, hand tools",
        "categoryName": "刀叉手工器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "手动截管器",
        "goodsEnName": "Pipe cutter, hand-operated",
        "goodsJpName": "手動カッター",
        "goodsNo": "",
        "id": 55339,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Adynamic manual utensils",
        "similarGroup": "0808",
        "similarItem": "",
        "similarName": "非动力手工器具",
        "type": 0,
        "updateTime": "2021-05-25 11:16:19"
      },
      {
        "category": "8",
        "categoryEnName": "Cutlery, hand tools",
        "categoryName": "刀叉手工器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "调色刀",
        "goodsEnName": "Palette knives\n",
        "goodsJpName": "パレットナイフ",
        "goodsNo": "080013",
        "id": 58538,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Small tools for special line",
        "similarGroup": "0809",
        "similarItem": "0809",
        "similarName": "专业用手工具",
        "type": 0,
        "updateTime": "2021-05-25 11:37:15"
      },
      {
        "category": "8",
        "categoryEnName": "Cutlery, hand tools",
        "categoryName": "刀叉手工器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "手动香蕉切片器",
        "goodsEnName": "Banana slicer, hand-operated",
        "goodsJpName": "手動バナナスライス器",
        "goodsNo": "",
        "id": 55969,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Knives and scissors (not including mechanical cutter blade and stationery knives)",
        "similarGroup": "0810",
        "similarItem": "",
        "similarName": "刀剪（不包括机械刀片,文具刀）",
        "type": 0,
        "updateTime": "2021-05-25 11:07:52"
      },
      {
        "category": "8",
        "categoryEnName": "Cutlery, hand tools",
        "categoryName": "刀叉手工器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "警棍",
        "goodsEnName": "Truncheons",
        "goodsJpName": "警棒",
        "goodsNo": "080130",
        "id": 67918,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Side arms except firearm",
        "similarGroup": "0811",
        "similarItem": "0811",
        "similarName": "除火器外的随身武器",
        "type": 0,
        "updateTime": "2021-05-25 11:37:23"
      },
      {
        "category": "8",
        "categoryEnName": "Cutlery, hand tools",
        "categoryName": "刀叉手工器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "长柄勺（手工具）",
        "goodsEnName": "Ladles [hand tools]",
        "goodsJpName": "ひしゃく及び取瓶（手持工具に当たるものに限る。）",
        "goodsNo": "080081",
        "id": 86384,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Table knives, forks and spoons",
        "similarGroup": "0812",
        "similarItem": "0812",
        "similarName": "餐具刀、叉、匙",
        "type": 0,
        "updateTime": "2021-05-25 11:37:45"
      },
      {
        "category": "8",
        "categoryEnName": "Cutlery, hand tools",
        "categoryName": "刀叉手工器具",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "手工具用加长杆",
        "goodsEnName": "Extended rod for hand tools",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 163559,
        "niceCodeLists": "",
        "remark": "2018-6, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Handles for hand-operated",
        "similarGroup": "0813",
        "similarItem": "0813",
        "similarName": "手工具柄",
        "type": 0,
        "updateTime": "2021-05-25 10:05:56"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "手动截管器",
    "goodsEnName": "Pipe cutter, hand-operated",
    "goodsJpName": "手動カッター",
    "goodsNo": "",
    "id": 55339,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": "Adynamic manual utensils",
    "similarGroup": "0808",
    "similarItem": "",
    "similarName": "非动力手工器具",
    "type": 0,
    "updateTime": "2021-05-25 11:16:19"
  },
  {
    "category": "9",
    "categoryEnName": "Scientific, electronic devices",
    "categoryName": "电子声影防护",
    "children": [
      {
        "category": "9",
        "categoryEnName": "Scientific, electronic devices",
        "categoryName": "电子声影防护",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "鼠标扫描仪",
        "goodsEnName": "Mouse scanner",
        "goodsJpName": "マウススキャナー",
        "goodsNo": "",
        "id": 55472,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Electronic computers and their external equipments",
        "similarGroup": "0901",
        "similarItem": "",
        "similarName": "电子计算机及其外部设备",
        "type": 0,
        "updateTime": "2021-05-25 10:55:51"
      },
      {
        "category": "9",
        "categoryEnName": "Scientific, electronic devices",
        "categoryName": "电子声影防护",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "14",
        "flagBit": 0,
        "goodsChName": "硬币检测机",
        "goodsEnName": "Coin detector",
        "goodsJpName": "硬貨検査機",
        "goodsNo": "",
        "id": 55822,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Count detecting instruments",
        "similarGroup": "0902",
        "similarItem": "",
        "similarName": "记录、记数检测器",
        "type": 1,
        "updateTime": "2021-05-25 10:55:35"
      },
      {
        "category": "9",
        "categoryEnName": "Scientific, electronic devices",
        "categoryName": "电子声影防护",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "传真机用未填充墨盒",
        "goodsEnName": "Unfilled ink cartridge for fax machine",
        "goodsJpName": "ファックス用のインクカートリッジを充填していません。",
        "goodsNo": "",
        "id": 55521,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Other office mechanics (not including typewriters, transcribers, mimeographs)",
        "similarGroup": "0903",
        "similarItem": "",
        "similarName": "其他办公用机械（不包括打字机、誊写机、油印机）",
        "type": 0,
        "updateTime": "2021-05-25 11:05:35"
      },
      {
        "category": "9",
        "categoryEnName": "Scientific, electronic devices",
        "categoryName": "电子声影防护",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "数字称量勺",
        "goodsEnName": "Digital weighing spoon",
        "goodsJpName": "デジタル計量スプーン",
        "goodsNo": "",
        "id": 55983,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Weighing apparatuses",
        "similarGroup": "0904",
        "similarItem": "",
        "similarName": "衡器",
        "type": 0,
        "updateTime": "2021-05-25 11:07:53"
      },
      {
        "category": "9",
        "categoryEnName": "Scientific, electronic devices",
        "categoryName": "电子声影防护",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "刻度尺",
        "goodsEnName": "Rules [measuring instruments]",
        "goodsJpName": "定規（測定器具）",
        "goodsNo": "090349",
        "id": 62353,
        "niceCodeLists": "",
        "remark": "修改注",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Measuring implements",
        "similarGroup": "0905",
        "similarItem": "0905",
        "similarName": "量具",
        "type": 0,
        "updateTime": "2021-05-25 10:40:26"
      },
      {
        "category": "9",
        "categoryEnName": "Scientific, electronic devices",
        "categoryName": "电子声影防护",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "夜明或机械信号标志",
        "goodsEnName": "Signals, luminous or mechanical",
        "goodsJpName": "発光式又は機械式の信号機",
        "goodsNo": "090434",
        "id": 56683,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Signalizers",
        "similarGroup": "0906",
        "similarItem": "0906",
        "similarName": "信号器具",
        "type": 0,
        "updateTime": "2021-05-25 10:40:54"
      },
      {
        "category": "9",
        "categoryEnName": "Scientific, electronic devices",
        "categoryName": "电子声影防护",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "智能手机屏幕放大器",
        "goodsEnName": "Smartphone screen amplifier",
        "goodsJpName": "スマートフォンの画面増幅器",
        "goodsNo": "",
        "id": 55479,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Communication and navigational aids",
        "similarGroup": "0907",
        "similarItem": "",
        "similarName": "通讯导航设备",
        "type": 0,
        "updateTime": "2021-05-25 10:55:51"
      },
      {
        "category": "9",
        "categoryEnName": "Scientific, electronic devices",
        "categoryName": "电子声影防护",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "芯片式录音机",
        "goodsEnName": "Chip recorder",
        "goodsJpName": "チップ？レコーダー",
        "goodsNo": "",
        "id": 55682,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Audio and video equipments",
        "similarGroup": "0908",
        "similarItem": "",
        "similarName": "音像设备",
        "type": 0,
        "updateTime": "2021-05-25 11:04:22"
      },
      {
        "category": "9",
        "categoryEnName": "Scientific, electronic devices",
        "categoryName": "电子声影防护",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "智能手机照相镜头",
        "goodsEnName": "Smartphone camera lens",
        "goodsJpName": "スマートフォンカメラ",
        "goodsNo": "",
        "id": 55402,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Appliances and apparatuses for photography and film",
        "similarGroup": "0909",
        "similarItem": "",
        "similarName": "摄影、电影用具及仪器",
        "type": 0,
        "updateTime": "2021-05-25 10:55:49"
      },
      {
        "category": "9",
        "categoryEnName": "Scientific, electronic devices",
        "categoryName": "电子声影防护",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "实验室用移液器吸头",
        "goodsEnName": "Pipette suction tips for laboratory use",
        "goodsJpName": "実験室でピペットを吸います。",
        "goodsNo": "",
        "id": 55227,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Measuring apparatuses and instruments, laboratory appliances, electrical measuring apparatuses, scientific instruments",
        "similarGroup": "0910",
        "similarItem": "",
        "similarName": "测量仪器仪表,实验室用器具,电测量仪器,科学仪器",
        "type": 0,
        "updateTime": "2021-05-25 10:08:52"
      },
      {
        "category": "9",
        "categoryEnName": "Scientific, electronic devices",
        "categoryName": "电子声影防护",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "望远镜镜筒（透镜镜筒）",
        "goodsEnName": "Telescope tubes [lenses tube] ",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 58433,
        "niceCodeLists": "",
        "remark": "可接受，非标准",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Optical instruments",
        "similarGroup": "0911",
        "similarItem": "0911",
        "similarName": "光学仪器",
        "type": 0,
        "updateTime": "2021-05-25 10:16:03"
      },
      {
        "category": "9",
        "categoryEnName": "Scientific, electronic devices",
        "categoryName": "电子声影防护",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "纤维光缆",
        "goodsEnName": "Fibre [fiber (Am.)] optic cables",
        "goodsJpName": "光ファイバーケーブル",
        "goodsNo": "090666",
        "id": 66056,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": " Photoelectric transferring materials",
        "similarGroup": "0912",
        "similarItem": "0912",
        "similarName": "光电传输材料",
        "type": 0,
        "updateTime": "2021-05-25 10:58:05"
      },
      {
        "category": "9",
        "categoryEnName": "Scientific, electronic devices",
        "categoryName": "电子声影防护",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "退磁器",
        "goodsEnName": "Demagnetization device",
        "goodsJpName": "かいこう器",
        "goodsNo": "",
        "id": 55913,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Crystal and carbon materials for electric appliances, electronic and electric universal components",
        "similarGroup": "0913",
        "similarItem": "",
        "similarName": "电器用晶体及碳素材料,电子、电气通用元件",
        "type": 0,
        "updateTime": "2021-05-25 11:07:49"
      },
      {
        "category": "9",
        "categoryEnName": "Scientific, electronic devices",
        "categoryName": "电子声影防护",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "电站自动化装置",
        "goodsEnName": "Automatic device for power station",
        "goodsJpName": "発電所の自動装置",
        "goodsNo": "C090109",
        "id": 56774,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Electrical appliance complete equipments and control devices",
        "similarGroup": "0914",
        "similarItem": "0914（一）",
        "similarName": "电器成套设备及控制装置",
        "type": 0,
        "updateTime": "2021-05-25 11:25:20"
      },
      {
        "category": "9",
        "categoryEnName": "Scientific, electronic devices",
        "categoryName": "电子声影防护",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "非空气、非水处理用电离设备",
        "goodsEnName": "Ionization apparatus not for the treatment of air or water",
        "goodsJpName": "イオン発生装置（空気清浄用又\nは浄水用を除く。）",
        "goodsNo": "090311",
        "id": 61842,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Electrolyzing equipment",
        "similarGroup": "0915",
        "similarItem": "0915",
        "similarName": "电解装置",
        "type": 0,
        "updateTime": "2021-05-25 11:25:48"
      },
      {
        "category": "9",
        "categoryEnName": "Scientific, electronic devices",
        "categoryName": "电子声影防护",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "8,15,16,17,18",
        "flagBit": 0,
        "goodsChName": "灭火设备",
        "goodsEnName": "Fire extinguishers",
        "goodsJpName": "消火器",
        "goodsNo": "090750",
        "id": 64642,
        "niceCodeLists": "",
        "remark": " ",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Fire extinguishers",
        "similarGroup": "0916",
        "similarItem": "0916",
        "similarName": "灭火器具",
        "type": 1,
        "updateTime": "2021-05-25 11:25:48"
      },
      {
        "category": "9",
        "categoryEnName": "Scientific, electronic devices",
        "categoryName": "电子声影防护",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "工业用放射屏幕",
        "goodsEnName": "Radiology screens for industrial purposes",
        "goodsJpName": "工業用の放射線透写スクリーン",
        "goodsNo": "090526",
        "id": 56893,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "X-ray mechanic equipments for industrial purposes",
        "similarGroup": "0918",
        "similarItem": "0918",
        "similarName": "工业用X光机械设备",
        "type": 0,
        "updateTime": "2021-05-25 10:41:59"
      },
      {
        "category": "9",
        "categoryEnName": "Scientific, electronic devices",
        "categoryName": "电子声影防护",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "火灾逃生梯",
        "goodsEnName": "Fire escape ladder",
        "goodsJpName": "非常階段",
        "goodsNo": "",
        "id": 56319,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Safety and medical aid equipments",
        "similarGroup": "0919",
        "similarItem": "",
        "similarName": "安全救护器具",
        "type": 0,
        "updateTime": "2021-05-25 11:48:43"
      },
      {
        "category": "9",
        "categoryEnName": "Scientific, electronic devices",
        "categoryName": "电子声影防护",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "5,16,17",
        "flagBit": 0,
        "goodsChName": "电子报警铃",
        "goodsEnName": "Eletronic alarm",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 57621,
        "niceCodeLists": "",
        "remark": "可接受，非标准",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Warning devices, electric bells",
        "similarGroup": "0920",
        "similarItem": "0920",
        "similarName": "警报装置,电铃",
        "type": 0,
        "updateTime": "2021-05-25 11:28:05"
      },
      {
        "category": "9",
        "categoryEnName": "Scientific, electronic devices",
        "categoryName": "电子声影防护",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "2",
        "flagBit": 0,
        "goodsChName": "太阳镜镜片",
        "goodsEnName": "Sunglasses lenses",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 57943,
        "niceCodeLists": "",
        "remark": "2018, 五方可接受",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Eyeglasses and accessories",
        "similarGroup": "0921",
        "similarItem": "0921",
        "similarName": "眼镜及附件",
        "type": 0,
        "updateTime": "2021-05-25 10:19:25"
      },
      {
        "category": "9",
        "categoryEnName": "Scientific, electronic devices",
        "categoryName": "电子声影防护",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "",
        "flagBit": 0,
        "goodsChName": "电池引线",
        "goodsEnName": "Battery lead",
        "goodsJpName": "",
        "goodsNo": "",
        "id": 60862,
        "niceCodeLists": "",
        "remark": "可接受、非标准 2017-4",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Batteries, chargers",
        "similarGroup": "0922",
        "similarItem": "0922",
        "similarName": "电池,充电器",
        "type": 0,
        "updateTime": "2021-05-25 10:48:46"
      },
      {
        "category": "9",
        "categoryEnName": "Scientific, electronic devices",
        "categoryName": "电子声影防护",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "16,17,18",
        "flagBit": 0,
        "goodsChName": "动画片",
        "goodsEnName": "Animated cartoons",
        "goodsJpName": "0",
        "goodsNo": "090176",
        "id": 56781,
        "niceCodeLists": "",
        "remark": "",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Movie films, exposed materials",
        "similarGroup": "0923",
        "similarItem": "0923",
        "similarName": "电影片,已曝光材料",
        "type": 0,
        "updateTime": "2021-05-25 10:38:50"
      },
      {
        "category": "9",
        "categoryEnName": "Scientific, electronic devices",
        "categoryName": "电子声影防护",
        "children": "",
        "createTime": "",
        "crossRetrieval": "",
        "deleteTime": "",
        "delflag": 0,
        "domainSecondId": "8,15",
        "flagBit": 0,
        "goodsChName": "叫狗哨子",
        "goodsEnName": "Dog whistles",
        "goodsJpName": "犬笛",
        "goodsNo": "090508",
        "id": 105298,
        "niceCodeLists": "",
        "remark": "修改注",
        "score": "",
        "scorePhonetic": "",
        "similarEnName": "Others",
        "similarGroup": "0924",
        "similarItem": "0924（一）",
        "similarName": "其他",
        "type": 0,
        "updateTime": "2021-05-25 11:29:52"
      }
    ],
    "createTime": "",
    "crossRetrieval": "",
    "deleteTime": "",
    "delflag": 0,
    "domainSecondId": "",
    "flagBit": 0,
    "goodsChName": "实验室用移液器吸头",
    "goodsEnName": "Pipette suction tips for laboratory use",
    "goodsJpName": "実験室でピペットを吸います。",
    "goodsNo": "",
    "id": 55227,
    "niceCodeLists": "",
    "remark": "",
    "score": "",
    "scorePhonetic": "",
    "similarEnName": "Measuring apparatuses and instruments, laboratory appliances, electrical measuring apparatuses, scientific instruments",
    "similarGroup": "0910",
    "similarItem": "",
    "similarName": "测量仪器仪表,实验室用器具,电测量仪器,科学仪器",
    "type": 0,
    "updateTime": "2021-05-25 10:08:52"
  }
]
}
