<template>
  <section class="progress-bar-l" v-show="queryItemsShow.isShow.progressBar">
    <div class="container">
      <div class="bar vertical-horizon-center">
        <p><span :style="{width:queryItemsShow.progressNum+'%'}"></span></p>
        <i>{{queryItemsShow.progressNum+'%'}}</i>
      </div>
    </div>
  </section>
</template>

<script>
  import {mapState, mapMutations, mapGetters} from 'vuex';
  export default {
    name: "progress-bar",
    components: {},
    data() {
      return {}
    },
    created() {

    },
    mounted() {

    },
    methods: {
      ...mapMutations('query', ['queryItemsShowFn']),
    },
    computed: {
      ...mapState('query', ['queryItemsShow']),
    },
  }
</script>

<style lang="less">
  .progress-bar-l {
    position: absolute;
    width: 100%;
    z-index: 5;
    .container {
      width: 570px;
      height: 260px;
      margin: 0 auto;
      background-color: #ffffff;
      box-shadow: 0px 2px 18px 0px rgba(47, 47, 47, 0.18);
      border-radius: 5px;
      position: relative;
      .bar {
        width: 360px;
        height: 48px;
        p {
          height: 10px;
          background-color: #dcdbdb;
          border-radius: 5px;
          span {
            display: inline-block;
            vertical-align: top;
            /*width: 100%;*/
            height: 100%;
            border-radius: 5px;
            background-image: linear-gradient(182deg, #de4c5c 1%, #ed8d67 100%), linear-gradient(#dcdbdb, #dcdbdb);
          }
        }
        i {
          display: block;
          font-size: 16px;
          line-height: 48px;
          letter-spacing: 0px;
          color: #333333;
          text-align: center;
          font-style: normal;
        }
      }
    }
  }
</style>
